/**
 * Redux Action Types for Gigya Session Management
 *
 * @author Gazi Rahman
 */
export enum GigyaSessionActionType {
  GIGYA_SESSION_CHECKING = 'GIGYA_SESSION_CHECKING',
  GIGYA_SESSION_CHECKED = 'GIGYA_SESSION_CHECKED',
}

export interface IGigyaSessionChecking {
  type: GigyaSessionActionType.GIGYA_SESSION_CHECKING;
}

export interface IGigyaSessionChecked {
  type: GigyaSessionActionType.GIGYA_SESSION_CHECKED;
  gigyaSessionExixts: boolean;
}

export type IAllGiygaSessionActions = IGigyaSessionChecking | IGigyaSessionChecked;
