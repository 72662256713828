import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Properties for SecurityQuestionOption
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  securityQuestion: string;
  notAllowedQuestions: Array<string>;
}

/**
 * Option Component to select a Security Question from among all the Security
 * Questions.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const securityQuestionOption: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <option value={props.securityQuestion} disabled={props.notAllowedQuestions.indexOf(props.securityQuestion) !== -1}>
      {t(props.securityQuestion)}
    </option>
  );
};

export default withTranslation('securityQuestions')(securityQuestionOption);
