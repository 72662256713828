import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * Default Locale to use if can't be figured out
 *
 * @author Gazi Rahman
 */
const DEFAULT_LOCALE = 'en';

/**
 * Namespaces to download from server
 *
 * @author Gazi Rahman
 */
const AUTO_NAMESPACES = ['common', 'login', 'register'];

/**
 * Default Namespace to use for internationalization; if not mentioned
 *
 * @author Gazi Rahman
 */
const DEFAULT_NAMESPACE = 'common';

/**
 * Path to load the locale file for a namespace
 *
 * @author Gazi Rahman
 */
const LOCALE_LOAD_PATH = '/locales/{{ns}}/{{lng}}.json';

/**
 * Server Path to add a missing locale for a namespace
 *
 * @author Gazi Rahman
 */
const MISSING_LOCALE_ADD_PATH = '/missinglocales/{{ns}}/{{lng}}';

/**
 * Query Parameter to use for Locale Settings
 *
 * @author Gazi Rahman
 */
const LOCALE_QUERY_PARAM = 'culture';

/**
 * Creates i18n-next instance for all the internationalization.
 *
 * @author Gazi Rahman
 */
i18n
  /**
   * Load translation using http -> see /public/locales (i.e.
   * https://github.com/i18next/react-i18next/tree/master/example/react/public/locales).
   * learn more: https://github.com/i18next/i18next-http-backend
   */
  .use(Backend)
  /**
   * detect user language
   * learn more: https://github.com/i18next/i18next-browser-languageDetector
   */
  .use(LanguageDetector)
  /**
   * pass the i18n instance to react-i18next.
   */
  .use(initReactI18next)
  /**
   * init i18next
   * for all options read: https://www.i18next.com/overview/configuration-options
   */
  .init({
    ns: AUTO_NAMESPACES,

    defaultNS: DEFAULT_NAMESPACE,

    fallbackLng: DEFAULT_LOCALE,

    saveMissing: true,

    debug: true,

    interpolation: {
      /**
       * not needed for react as it escapes by default
       */
      escapeValue: false,
    },

    backend: {
      loadPath: LOCALE_LOAD_PATH,

      addPath: MISSING_LOCALE_ADD_PATH,
    },

    detection: {
      lookupQuerystring: LOCALE_QUERY_PARAM,
    },
  });

export default i18n;
