/**
 * @author Gazi Rahman
 */
import IAccountInfoRetrieveState from './IAccountInfoRetrieveState';
import { Reducer } from 'redux';
import { AccountInfoRetrieveActionType, IAllAccountInfoRetrieveActions } from './AccountInfoRetrieveActionType';

const initialState: IAccountInfoRetrieveState = {
  accountInfoRetrieveStarted: false,
  accountInfoRetrieveFailed: false,
  accountInfoRetrieved: false,
};

/**
 * Redux Reducer for AccountInfoRetrieve from Gigya
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const accountInfoRetrieveReducer: Reducer<IAccountInfoRetrieveState, IAllAccountInfoRetrieveActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_STARTED:
      return {
        ...state,
        accountInfoRetrieveStarted: true,
      };
    case AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FAILED:
      return {
        ...state,
        accountInfoRetrieveFailed: true,
        accountInfoRetrieveFailedErrorCode: action.errorCode,
        accountInfoRetrieveFailedErrorMessage: action.errorMessage,
      };
    case AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FINISHED:
      return {
        ...state,
        accountInfoRetrieved: true,
        accountInfoResponse: action.response,
      };
    default:
      return { ...state };
  }
};

export default accountInfoRetrieveReducer;
