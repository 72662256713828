import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { withTranslation, WithTranslation } from 'react-i18next';

import { isValidEmail, isValidOrthoEmployeeEmail } from '../../../util/EmailUtil';

import LoginLink from '../../../components/loginLink/LoginLink';

import { changeProfileForm, submitProfileForm } from '../../../store/register/RegistrationNavigationActions';
import { checkEmailAvailbleForRegister } from '../../../store/register/CheckEmailAvailableForRegisterAction';

import personalInfoImg from '../../../assets/images/CDC/personal-info.svg';
import contactInfoImg from '../../../assets/images/CDC/contact-info.svg';
import PasswordVisibilityImg from '../../../assets/images/CDC/password-visibility.svg';
import PasswordVisibleImg from '../../../assets/images/CDC/password-visible.svg';

import TelephoneNumber from '../../../components/telephonenumber/TelephoneNumber';
import FaxNumber from '../../../components/faxnumber/FaxNumber';
import MobileNumber from '../../../components/mobilenumber/MobileNumber';
import { IApplicationState } from '../../../store/Store';
import { IProfileForm } from '../../../store/register/IProfileForm';
import IRegistrationState from '../../../store/register/IRegistrationState';
import CountrySelector from '../../../components/countryselector/CountrySelector';
import { PASSWORD_STRENGTH_DESC, PasswordStrengthDescClass } from '../../../util/PasswordStrengthUtil';
import { calculatePasswordStrength } from '../../../util/PasswordStrengthUtil';
import { CssClasses } from '../../../constants/CssClasses';
import IProgressBarStyle from '../../../styles/IProgressBarStyle';
import IAutoLinkedCustomerUser from '../../../api/response/IAutoLinkedCustomerUser';

/**
 * Properties for ProfileInfoForm
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps, IRegistrationState, WithTranslation {
  notifyProfileChange: typeof changeProfileForm;
  submitProfileForm: typeof submitProfileForm;
  checkEmailAvailableForRegister: typeof checkEmailAvailbleForRegister;
  autoLinkedCustomerUser?: IAutoLinkedCustomerUser;
}

/**
 * State for ProfileInfoForm
 *
 * @author Gazi Rahman
 */
interface IState {
  profileForm: IProfileForm;
  isEmailValid: boolean;
  isOrthoEmployeeEmail: boolean;
  showPasswordText: boolean;
  showPasswordMessages: boolean;
  alphabetRestrictionValidated: boolean;
  numberRestrictionValidated: boolean;
  lengthRestrictionValidated: boolean;
  passwordScore: number;
  progressBarClass: string;
  progressBarStyle?: IProgressBarStyle;
  emailsDoNotMatch: boolean;
  passwordsDoNotMatch: boolean;
}

/**
 * Registration Form Fragment for User Profile info
 *
 * @author Gazi Rahman
 */
class ProfileForm extends React.Component<IProps, IState> {
  state = {
    profileForm: { ...this.props.registrationForm.profileForm },
    isEmailValid: false,
    isOrthoEmployeeEmail: false,
    showPasswordText: false,
    showPasswordMessages: false,
    alphabetRestrictionValidated: false,
    numberRestrictionValidated: false,
    lengthRestrictionValidated: false,
    passwordScore: 0,
    progressBarClass: '',
    progressBarStyle: undefined,
    emailsDoNotMatch: false,
    passwordsDoNotMatch: false,
  };

  componentDidMount(): void {
    if (this.props.autoLinkedCustomerUser) {
      this.setState({
        ...this.state,
        profileForm: {
          ...this.state.profileForm,
          email: this.props.autoLinkedCustomerUser.userEmail,
          confirmEmail: this.props.autoLinkedCustomerUser.userEmail,
          firstName: this.state.profileForm.firstName || this.props.autoLinkedCustomerUser.firstName || '',
          lastName: this.state.profileForm.lastName || this.props.autoLinkedCustomerUser.lastName || '',
        },
      });
    }
  }

  componentDidUpdate(): void {
    if (
      this.props.autoLinkedCustomerUser &&
      this.state.profileForm.email !== this.props.autoLinkedCustomerUser.userEmail
    ) {
      this.setState({
        ...this.state,
        profileForm: {
          ...this.state.profileForm,
          email: this.props.autoLinkedCustomerUser.userEmail,
          confirmEmail: this.props.autoLinkedCustomerUser.userEmail,
          firstName: this.state.profileForm.firstName || this.props.autoLinkedCustomerUser.firstName || '',
          lastName: this.state.profileForm.lastName || this.props.autoLinkedCustomerUser.lastName || '',
        },
      });
    }
  }

  firstNameChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentFirstName = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        firstName: currentFirstName,
      },
    });
  };

  lastNameChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentLastName = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        lastName: currentLastName,
      },
    });
  };

  roleChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentRole = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        role: currentRole,
      },
    });
  };

  emailChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentEmail = ev.currentTarget.value?.trim();

    const currentConfirmEmail = this.state.profileForm.confirmEmail;
    const emailsDoNotMatch: boolean = currentEmail !== currentConfirmEmail;

    this.setState({
      profileForm: {
        ...this.state.profileForm,
        email: currentEmail,
      },
      emailsDoNotMatch: emailsDoNotMatch,
    });
  };

  emailBlurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    const emailInput = ev.currentTarget;
    const currentEmail = emailInput.value;
    const isCurrentEmailValid = isValidEmail(currentEmail);
    const isOrthoEmployeeEmail = isValidOrthoEmployeeEmail(currentEmail);
    const { t } = this.props;
    if (isCurrentEmailValid && !isOrthoEmployeeEmail) {
      emailInput.setCustomValidity('');
    } else if (isOrthoEmployeeEmail) {
      emailInput.setCustomValidity(t('profileInfoForm.errorMessage.email.orthoEmployee'));
    } else {
      emailInput.setCustomValidity(t('profileInfoForm.errorMessage.email.invalid'));
    }
    this.setState(
      {
        isEmailValid: isCurrentEmailValid,
        isOrthoEmployeeEmail: isOrthoEmployeeEmail,
      },
      () =>
        currentEmail &&
        isCurrentEmailValid &&
        !isOrthoEmployeeEmail &&
        this.props.checkEmailAvailableForRegister(currentEmail),
    );
  };

  confirmEmailChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const confirmEmailField = ev.currentTarget;
    const currentConfirmEmail = confirmEmailField.value?.trim();

    const currentEmail = this.state.profileForm.email;
    const emailsDoNotMatch: boolean = currentEmail !== currentConfirmEmail;
    const { t } = this.props;
    if (emailsDoNotMatch) {
      confirmEmailField.setCustomValidity(t('profileInfoForm.errorMessage.confirmEmail.misMatch'));
    } else {
      confirmEmailField.setCustomValidity('');
    }
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        confirmEmail: currentConfirmEmail,
      },
      emailsDoNotMatch: emailsDoNotMatch,
    });
  };

  passwordChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentPassword = ev.currentTarget.value;

    const currentVerifyPassword = this.state.profileForm.confirmPassword;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== currentPassword;

    this.setState({
      profileForm: {
        ...this.state.profileForm,
        password: currentPassword,
      },
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  passwordFocusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      showPasswordMessages: true,
    });
  };

  passwordBlurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      showPasswordMessages: false,
    });
  };

  calculatePasswordStrength = (password: string): void => {
    const score = calculatePasswordStrength(password);

    this.setState({
      passwordScore: score,
      progressBarClass: PasswordStrengthDescClass[score],
      progressBarStyle: PASSWORD_STRENGTH_DESC[score],
    });

    // element.removeClass(descClass[score - 1]).addClass(descClass[score]).css(desc[score]);
  };

  passwordKeyupHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentPassVal = event.currentTarget.value;
    const alphabetRestrictionValidated = currentPassVal.match(/[a-zA-Z]/g) !== null;
    const numberRestrictionValidated = currentPassVal.match(/[0-9]/g) !== null;
    const lengthRestrictionValidated = currentPassVal.length >= 8;
    this.setState(
      {
        alphabetRestrictionValidated: alphabetRestrictionValidated,
        numberRestrictionValidated: numberRestrictionValidated,
        lengthRestrictionValidated: lengthRestrictionValidated,
      },
      () => {
        this.calculatePasswordStrength(currentPassVal);
      },
    );
  };

  passwordVisibilityClickedHandler = (event: React.MouseEvent<HTMLImageElement>) => {
    const newVisibility = !this.state.showPasswordText;
    this.setState({
      showPasswordText: newVisibility,
    });
  };

  confirmPasswordChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPasswordInput = ev.currentTarget;
    const currentVerifyPassword = confirmPasswordInput.value;

    const currentPassword = this.state.profileForm.password;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== currentPassword;

    const { t } = this.props;
    if (passwordsDoNotMatch) {
      confirmPasswordInput.setCustomValidity(t('profileInfoForm.errorMessage.confirmPassword.misMatch'));
    } else {
      confirmPasswordInput.setCustomValidity('');
    }

    this.setState({
      profileForm: {
        ...this.state.profileForm,
        confirmPassword: currentVerifyPassword,
      },
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  telephoneCountryChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const option: HTMLOptionElement | null = ev.currentTarget.selectedOptions.item(0);
    const countryCode = option != null ? option.dataset.countrycode || '' : '';
    const countryCallingCode = option != null ? option.dataset.callingcode || '' : '';
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        telephone: {
          ...this.state.profileForm.telephone,
          countryCode: countryCode,
          countryValue: parseInt(countryCallingCode),
        },
      },
    });
  };

  telephoneNumberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentTelephoneNumber = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        telephone: {
          ...this.state.profileForm.telephone,
          number: currentTelephoneNumber,
        },
      },
    });
  };

  telephoneExtensionChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentTelephoneExtension = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        telephone: {
          ...this.state.profileForm.telephone,
          extension: currentTelephoneExtension,
        },
      },
    });
  };

  faxCountryChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const option: HTMLOptionElement | null = ev.currentTarget.selectedOptions.item(0);
    const countryCode = option != null ? option.dataset.countrycode || '' : '';
    const countryCallingCode = option != null ? option.dataset.callingcode || '' : '';
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        fax: {
          ...this.state.profileForm.fax,
          countryCode: countryCode,
          countryValue: parseInt(countryCallingCode),
        },
      },
    });
  };

  faxNumberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentFaxNumber = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        fax: {
          ...this.state.profileForm.fax,
          number: currentFaxNumber,
        },
      },
    });
  };

  faxExtensionChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentFaxExtension = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        fax: {
          ...this.state.profileForm.fax,
          extension: currentFaxExtension,
        },
      },
    });
  };

  mobileCountryChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const option: HTMLOptionElement | null = ev.currentTarget.selectedOptions.item(0);
    const countryCode = option != null ? option.dataset.countrycode || '' : '';
    const countryCallingCode = option != null ? option.dataset.callingcode || '' : '';
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        mobile: {
          ...this.state.profileForm.mobile,
          countryCode: countryCode,
          countryValue: parseInt(countryCallingCode),
        },
      },
    });
  };

  mobileNumberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentMobileNumber = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        mobile: {
          ...this.state.profileForm.mobile,
          number: currentMobileNumber,
        },
      },
    });
  };

  countryChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const currentVal = ev.currentTarget.value;
    this.setState({
      profileForm: {
        ...this.state.profileForm,
        country: currentVal,
      },
    });
  };

  formSubmitHaandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const form = ev.currentTarget;
    if (
      form.checkValidity() === false ||
      this.state.passwordsDoNotMatch ||
      this.state.emailsDoNotMatch ||
      this.isEmailUnavailable()
    ) {
      form.classList.add(CssClasses.WAS_VALIDATED);
    } else {
      this.props.submitProfileForm(this.state.profileForm);
    }
  };

  isEmailUnavailable = () => this.props.isEmailAvailable !== undefined && !this.props.isEmailAvailable;

  render() {
    const passVisbilityImg = this.state.showPasswordText ? PasswordVisibleImg : PasswordVisibilityImg;
    const passwordInputType = this.state.showPasswordText ? 'text' : 'password';
    const { t } = this.props;
    return (
      <section className="register-form registration-form">
        <form className="needs-validation" noValidate={true} onSubmit={this.formSubmitHaandler}>
          <img src={personalInfoImg} alt="" />
          <label>{t('profileInfoForm.label.profileInfo')}</label>

          <div>
            <input
              type="text"
              className="form-control"
              placeholder={t('profileInfoForm.placeHolder.firstName')}
              required={true}
              value={this.state.profileForm.firstName}
              onChange={this.firstNameChangeHandler}
              autoFocus={true}
            />
            <div className="invalid-feedback">{t('profileInfoForm.errorMessage.firstName.required')}</div>
          </div>

          <div>
            <input
              type="text"
              className="form-control"
              placeholder={t('profileInfoForm.placeHolder.lastName')}
              required={true}
              value={this.state.profileForm.lastName}
              onChange={this.lastNameChangeHandler}
            />
            <div className="invalid-feedback">{t('profileInfoForm.errorMessage.lastName.required')}</div>
          </div>

          <input
            type="text"
            className="form-control"
            placeholder={t('profileInfoForm.placeHolder.role')}
            value={this.state.profileForm.role}
            onChange={this.roleChangeHandler}
          />

          <img src={contactInfoImg} alt="" />
          <label htmlFor="validationCustom04">{t('profileInfoForm.label.contactInfo')}</label>

          <div>
            <input
              type="email"
              id="validationCustom04"
              className="form-control validate"
              placeholder={t('profileInfoForm.placeHolder.email')}
              required={true}
              value={this.state.profileForm.email}
              onChange={!this.props.autoLinkedCustomerUser ? this.emailChangeHandler : undefined}
              onBlur={this.emailBlurHandler}
              readOnly={!!this.props.autoLinkedCustomerUser}
              tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
            />
            <div className="invalid-feedback">
              {!this.state.isEmailValid ? t('profileInfoForm.errorMessage.email.invalid') : null}
              {this.state.isOrthoEmployeeEmail ? t('profileInfoForm.errorMessage.email.orthoEmployee') : null}
            </div>
            <div
              className="invalid-feedback"
              style={this.isEmailUnavailable() ? { display: 'block' } : { display: 'none' }}
            >
              {t('profileInfoForm.errorMessage.email.notAvailable')}
            </div>
          </div>

          <div>
            <input
              type="email"
              className="form-control validate"
              placeholder={t('profileInfoForm.placeHolder.confirmEmail')}
              required={true}
              value={this.state.profileForm.confirmEmail}
              onChange={!this.props.autoLinkedCustomerUser ? this.confirmEmailChangeHandler : undefined}
              readOnly={!!this.props.autoLinkedCustomerUser}
              tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
            />
            <div className="invalid-feedback">{t('profileInfoForm.errorMessage.confirmEmail.misMatch')}</div>
          </div>

          <div>
            <input
              type={passwordInputType}
              className="form-control"
              placeholder={t('profileInfoForm.placeHolder.password')}
              pattern="^(?=.*[a-zA-Z])(?=.*[0-9])\S{8,}$"
              required={true}
              value={this.state.profileForm.password}
              onChange={this.passwordChangeHandler}
              onFocus={this.passwordFocusHandler}
              onBlur={this.passwordBlurHandler}
              onKeyUp={this.passwordKeyupHandler}
            />
            <div className="password-container">
              <img
                className="password-visibility"
                src={passVisbilityImg}
                alt=""
                onClick={this.passwordVisibilityClickedHandler}
              />
            </div>
            <div id="progress">
              <div id="progress-bar" className={this.state.progressBarClass} style={this.state.progressBarStyle}></div>
            </div>
            <p className="strength-text"></p>
            {this.state.showPasswordMessages ? (
              <div id="message" style={{ display: 'block' }}>
                <h3>{t('profileInfoForm.errorMessage.password.rule.messagePrefix')}</h3>
                <div className="row">
                  <div className="col-md-6">
                    <p id="letter" className={this.state.alphabetRestrictionValidated ? 'valid' : 'invalid'}>
                      {t('profileInfoForm.errorMessage.password.rule.alphabet')}
                    </p>
                    {/* <p id="letter" className="invalid">{t('profileInfoForm.errorMessage.password.rule.lowerCaseCharacter')}</p> */}
                    {/* <p id="capital" className="invalid">{t('profileInfoForm.errorMessage.password.rule.upperCaseCharacter')}</p> */}
                    <p id="length" className={this.state.lengthRestrictionValidated ? 'valid' : 'invalid'}>
                      {t('profileInfoForm.errorMessage.password.rule.characterNumber')}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p id="number" className={this.state.numberRestrictionValidated ? 'valid' : 'invalid'}>
                      {t('profileInfoForm.errorMessage.password.rule.number')}
                    </p>
                    {/* <p id="special" className="invalid">{t('profileInfoForm.errorMessage.password.rule.specialCharacter')}</p> */}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="invalid-feedback password-invalid">
              {t('profileInfoForm.errorMessage.password.invalid')}
            </div>
          </div>

          <div>
            <input
              type={passwordInputType}
              className="form-control"
              placeholder={t('profileInfoForm.placeHolder.confirmPassword')}
              required={true}
              value={this.state.profileForm.confirmPassword}
              onChange={this.confirmPasswordChangeHandler}
            />
            <div className="password-container">
              <img
                className="password-visibility"
                src={passVisbilityImg}
                alt=""
                onClick={this.passwordVisibilityClickedHandler}
              />
            </div>
            <div className="invalid-feedback">{t('profileInfoForm.errorMessage.confirmPassword.invalid')}</div>
            <div
              className="no-match"
              style={this.state.passwordsDoNotMatch ? { display: 'block' } : { display: 'none' }}
            >
              {t('profileInfoForm.errorMessage.confirmPassword.misMatch')}
            </div>
          </div>

          <TelephoneNumber
            countryValue={
              this.state.profileForm.telephone.countryCode + '-' + this.state.profileForm.telephone.countryValue
            }
            onCountryValueChange={this.telephoneCountryChangeHandler}
            number={this.state.profileForm.telephone.number}
            onNumberChange={this.telephoneNumberChangeHandler}
            extension={this.state.profileForm.telephone.extension}
            onExtensionChange={this.telephoneExtensionChangeHandler}
          />

          <FaxNumber
            countryValue={this.state.profileForm.fax.countryCode + '-' + this.state.profileForm.fax.countryValue}
            onCountryValueChange={this.faxCountryChangeHandler}
            number={this.state.profileForm.fax.number}
            onNumberChange={this.faxNumberChangeHandler}
            extension={this.state.profileForm.fax.extension}
            onExtensionChange={this.faxExtensionChangeHandler}
          />

          <MobileNumber
            countryValue={this.state.profileForm.mobile.countryCode + '-' + this.state.profileForm.mobile.countryValue}
            onCountryValueChange={this.mobileCountryChangeHandler}
            number={this.state.profileForm.mobile.number}
            onNumberChange={this.mobileNumberChangeHandler}
          />

          <CountrySelector
            countryCode={this.state.profileForm.country}
            onCountryCodeChange={this.countryChangeHandler}
          />

          <div className="form-btns justify-content-end">
            <button className="btn btn-primary register-form-btn next" type="submit">
              {t('buttonLabel.next')}
            </button>
          </div>
        </form>

        <LoginLink />
      </section>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.registrationState,
});

const mapDispatchToProps = (dispatch: any) => ({
  notifyProfileChange: (profileForm: IProfileForm) => dispatch(changeProfileForm(profileForm)),
  submitProfileForm: (profileForm: IProfileForm) => dispatch(submitProfileForm(profileForm)),
  checkEmailAvailableForRegister: (email: string) => dispatch(checkEmailAvailbleForRegister(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation('register')(ProfileForm)));
