/**
 * Constants for different CSS Classes used in the application for Components.
 *
 * @author Gazi Rahman
 */
export enum CssClasses {
  WAS_VALIDATED = 'was-validated',
  PASSWORD_STRENGTH_WEAK = 'progress-bar-danger',
  PASSWORD_STRENGTH_OK = 'progress-bar-warning',
  PASSWORD_STRENGTH_GOOD = 'progress-bar-success',
  DEFAULT_MAIN_CLASSES = 'no-hero orthoplus',
  ERROR_PAGE_CLASS = 'error-page',
  TITLE_CLASS = 'login',
  TITLE_ERROR_PAGE = 'error',
}
