import { Reducer } from 'redux';
import IAuthState from './IAuthState';
import { AuthActionType, IAllAuthActions } from './AuthActionType';

/**
 * Initial State for AuthReducer
 *
 * @author Gazi Rahman
 */
const initialAuthState: IAuthState = {
  authenticationLoadingStarted: false,
  authenticationLoaded: false,
  isAuthenticationCleared: false,
  authenticated: false,
  orthoUser: false,
  accessTokenInfo: undefined,
  authRequestStarted: false,
  authFinishedSuccessfully: false,
  authFailed: false,
  authPendingPasswordChange: false,
  passwordNotSet: false,
  pendingAccountVerification: false,
  resentAccountVerificationEmail: false,
  uid: undefined,
  regToken: undefined,
};

/**
 * Redux Reducer used for Authentication
 *
 * @param state
 * @param authAction
 * @returns
 *
 * @author Gazi Rahman
 */
const authReducer: Reducer<IAuthState, IAllAuthActions> = (state = initialAuthState, authAction) => {
  switch (authAction.type) {
    case AuthActionType.AUTHENTICATION_LOADING:
      return {
        ...state,
        authenticationLoadingStarted: true,
        authenticationLoaded: false,
        isAuthenticationCleared: false,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.LOAD_AUTHENTICATION:
      return {
        ...state,
        authenticationLoaded: true,
        isAuthenticationCleared: false,
        authenticated: false,
        accessTokenInfo: authAction.accessTokenInfo,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.CLEAR_AUTHENTICATION:
      return {
        ...state,
        authenticationLoaded: false,
        isAuthenticationCleared: true,
        authenticated: false,
        accessTokenInfo: undefined,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        orthoUser: false,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.START_AUTH_REQUEST:
      return {
        ...state,
        authenticated: false,
        orthoUser: authAction.orthoUser,
        authRequestStarted: true,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.AUTHENTICATION_COMPLETED:
      return {
        ...state,
        authenticated: false,
        orthoUser: authAction.orthoUser,
        authRequestStarted: false,
        authFinishedSuccessfully: true,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: authAction.accessTokenInfo,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.AUTHENTICATION_FAILED:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: true,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: authAction.errorCodes,
        authErrorMessage: authAction.errorMessage,
      };
    case AuthActionType.SEND_PENDING_PASSWORD_CHANGE:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: true,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: authAction.uid,
        regToken: authAction.regToken,
        accessTokenInfo: undefined,
        authErrorCodes: authAction.errorCodes,
        authErrorMessage: authAction.errorMessage,
      };
    case AuthActionType.SEND_PASSWORD_NOT_SET:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: true,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: authAction.errorCodes,
        authErrorMessage: authAction.errorMessage,
      };
    case AuthActionType.SEND_PENDING_ACCOUNT_VERIFICATION:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: true,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: authAction.errorCodes,
        authErrorMessage: authAction.errorMessage,
      };
    case AuthActionType.VERIRIFICATION_EMAIL_SENT:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: true,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.RESET_VERIFICATION_EMAIL_SENT:
      return {
        ...state,
        authenticated: false,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        accessTokenInfo: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.RESET_AUTHENTICATION_PROCESS:
      return {
        ...state,
        authRequestStarted: false,
        authFinishedSuccessfully: false,
        authFailed: false,
        authPendingPasswordChange: false,
        passwordNotSet: false,
        pendingAccountVerification: false,
        resentAccountVerificationEmail: false,
        uid: undefined,
        regToken: undefined,
        authErrorCodes: undefined,
        authErrorMessage: undefined,
      };
    case AuthActionType.MARK_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    default:
      return state;
  }
};

export default authReducer;
