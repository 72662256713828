import * as React from 'react';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation } from 'react-i18next';

import LoginLink from '../../../components/loginLink/LoginLink';

import {
  submitSequrityQuestionsForm,
  goPreviousFromSecurityQuestionsForm,
} from '../../../store/register/RegistrationNavigationActions';
import IRegistrationState from '../../../store/register/IRegistrationState';
import { ISecurityQuestionsForm, ISecurityQuestionAnswer } from '../../../store/register/ISecurityQuestionsForm';
import { IApplicationState } from '../../../store/Store';
import { loadSecurityQuestions } from '../../../store/securityquestion/SecurityQuestionsActions';
import ISecurityQuestionsState from '../../../store/securityquestion/ISecurityQuestionsState';

import SecurityQuestionAnswer from '../../../components/securityquestionanswer/SecurityQuestionAnswer';

import securityQuestionsImg from '../../../assets/images/CDC/security-questions.svg';
import { CssClasses } from '../../../constants/CssClasses';

/**
 * Properties for SecurityQuestionsForm
 *
 * @author Gazi Rahman
 */
interface IProps extends IRegistrationState, ISecurityQuestionsState, WithTranslation {
  submitSequrityQuestionsForm: typeof submitSequrityQuestionsForm;
  goPreviousFromSecurityQuestionsForm: typeof goPreviousFromSecurityQuestionsForm;
  loadSecurityQuestions: typeof loadSecurityQuestions;
}

/**
 * State for SecurityQuestionsForm
 *
 * @author Gazi Rahman
 */
type IState = ISecurityQuestionsForm;

/**
 * Registration Form Segment for Security Questions and Answers
 *
 * @author Gazi Rahman
 */
class SecurityQuestionsForm extends React.Component<IProps, IState> {
  state = {
    ...this.props.registrationForm.securityQuestionsForm,
  };

  componentDidMount() {
    if (!this.props.securityQuestionsLoaded && !this.props.securityQuestionsLoading) {
      this.props.loadSecurityQuestions();
    }
  }

  securityQuestionChangeHandler = (questionNumber: number, question: string) => {
    const newSecurityQuestionAnswers: Array<ISecurityQuestionAnswer> = this.state.securityQuestionAnswers.map(
      (securityQuestionAnswer, i) =>
        i + 1 === questionNumber
          ? { question: question, answer: securityQuestionAnswer.answer }
          : { ...securityQuestionAnswer },
    );
    this.setState({
      securityQuestionAnswers: newSecurityQuestionAnswers,
    });
  };

  securityAnswerChangeHandler = (questionNumber: number, answer: string) => {
    const newSecurityQuestionAnswers: Array<ISecurityQuestionAnswer> = this.state.securityQuestionAnswers.map(
      (securityQuestionAnswer, i) =>
        i + 1 === questionNumber
          ? { question: securityQuestionAnswer.question, answer: answer }
          : { ...securityQuestionAnswer },
    );
    this.setState({
      securityQuestionAnswers: newSecurityQuestionAnswers,
    });
  };

  formSubmitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      form.classList.add(CssClasses.WAS_VALIDATED);
    } else {
      this.props.submitSequrityQuestionsForm(this.state);
    }
  };

  prevButtonClickHandler = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.props.goPreviousFromSecurityQuestionsForm(this.state);
  };

  render() {
    const securityQuestionAnswers = this.state.securityQuestionAnswers.map((securityQuestionAnswer, i) => {
      const siblingQuestions = this.state.securityQuestionAnswers
        .filter((secQA, j) => j !== i)
        .map((secQA) => secQA.question);
      return (
        <SecurityQuestionAnswer
          key={i}
          questionNumber={i + 1}
          question={securityQuestionAnswer.question}
          onQuestionChange={this.securityQuestionChangeHandler}
          answer={securityQuestionAnswer.answer}
          onAnswerChange={this.securityAnswerChangeHandler}
          notAllowedQuestions={siblingQuestions}
          securityQuestions={this.props.securityQuestions}
        />
      );
    });

    const { t } = this.props;

    return (
      <section className="register-form security-questions">
        <form className=" needs-validation" noValidate={true} onSubmit={this.formSubmitHandler}>
          <img src={securityQuestionsImg} alt="" />
          <label>{t('securityQuestionForm.label.securityQuestions')}</label>

          {securityQuestionAnswers}

          <div className="form-btns">
            <button className="btn btn-primary previous-btn" type="button" onClick={this.prevButtonClickHandler}>
              {t('buttonLabel.previous')}
            </button>
            <button className="btn btn-primary register-form-btn next" type="submit">
              {t('buttonLabel.next')}
            </button>
          </div>
        </form>
        <LoginLink />
      </section>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.registrationState,
  ...state.securityQuestionsState,
});

const mapDispatchToProps = (dispatch: any) => ({
  submitSequrityQuestionsForm: (securityQuestionsForms: ISecurityQuestionsForm) =>
    dispatch(submitSequrityQuestionsForm(securityQuestionsForms)),
  goPreviousFromSecurityQuestionsForm: (securityQuestionsForm: ISecurityQuestionsForm) =>
    dispatch(goPreviousFromSecurityQuestionsForm(securityQuestionsForm)),
  loadSecurityQuestions: () => dispatch(loadSecurityQuestions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('register')(SecurityQuestionsForm));
