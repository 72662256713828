import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import phoneCountriesData from '../../data/PhoneCountries.json';

/**
 * Properties for 'CountrySelector'
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  countryCode: string;
  onCountryCodeChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * Componenet that displayes all the countries and allows user to select one.
 *
 * @param props Properties for the JSX Component
 * @returns the JSX Element
 *
 * @author Gazi Rahman
 */
const countrySelector: React.SFC<IProps> = (props) => {
  const { t } = props;

  const optionObjects = phoneCountriesData.map((it) => (
    <option key={it.country_code + '-' + it.calling_code} value={it.country_code}>
      {it.country_description}
    </option>
  ));

  return (
    <div>
      <select
        name=""
        required={true}
        className="form-control"
        value={props.countryCode}
        onChange={props.onCountryCodeChange}
      >
        <option value="" disabled>
          {t('countrySelector.label')}
        </option>
        {optionObjects}
      </select>
      <div className="invalid-feedback">{t('countrySelector.error.invalid')}</div>
    </div>
  );
};

export default withTranslation('common')(countrySelector);
