import * as React from 'react';
import plusLogo from '../../assets/images/CDC/plus-logo-reg.svg';

/**
 * Component to display Ortho Logo.
 *
 * @returns
 *
 * @author Gazi Rahman
 */
const logo: React.SFC = () => (
  <div className="logo">
    <img src={plusLogo} alt="" />
  </div>
);

export default logo;
