import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import log from '../../util/Log';

import { isValidEmail, isValidOrthoEmployeeEmail } from '../../util/EmailUtil';

import personalInfoLogo from '../../assets/images/CDC/personal-info.svg';
import passwordVisibilityLogo from '../../assets/images/CDC/password-visibility.svg';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { Link } from 'react-router-dom';
import { CssClasses } from '../../constants/CssClasses';
import { Links } from '../../constants/Links';
import { QueryParams } from '../../constants/QueryParams';
import AccountPendingVerificationError from '../../components/resendVerificationLink/AccountPendingVerificationError';
import RegisterLink from '../../components/registerLink/RegisterLink';

import { CDCLoading } from '../../components/cdcloading/CDCLoading';

import '../../assets/css/cdc.form.css';

const ALLOWED_EMAIL_PATTERN =
  process.env.LOGIN_ALLOWED_EMAIL_PATTERN || "[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$";

const ORTHO_EMAIL_DOMAIN = process.env.LOGIN_ORTHO_EMAIL_DOMAIN || '@quidelortho.com';

const ORTHO_EMAIL_PATTERN = /@quid/i;

/**
 * Properties for LoginForm Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  email?: string;
  onEmailChange: (email: string) => void;
  password?: string;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  isAuthenticationLoading: () => boolean;
  isPageLoading: () => boolean;
  onCustomerFormSubmit: () => void;
  onEmployeeFormSubmit: () => void;
  onRegisterLinkClick: (event: React.MouseEvent) => void;
  emailSuggestions?: Array<string>;
  showVerificationError: boolean;
  onResendVerificationLinkClick: () => void;
  verificationEmailSent: boolean;
}

/**
 * State for LoginForm Component
 *
 * @author Gazi Rahman
 */
interface IState {
  showPassword: boolean;
  displayPasswordText: boolean;
  suggestions: Array<string>;
}

/**
 * Login Form Component
 *
 * @author Gazi Rahman
 */
class LoginForm extends React.Component<IProps, IState> {
  state = {
    showPassword: false,
    displayPasswordText: false,
    suggestions: this.props.emailSuggestions ? [...this.props.emailSuggestions] : [],
  };

  checkEmail = () => {
    if (isValidEmail(this.props.email || '') && !isValidOrthoEmployeeEmail(this.props.email || '')) {
      log('Found Valid Email');
      this.setState({
        showPassword: true,
      });
    } else {
      log('Invalid Email!');
      this.setState({
        showPassword: false,
      });
    }
  };

  handleEmailChanged = (email: string) => {
    this.props.onEmailChange(email);

    const suggestions = this.props.emailSuggestions ? [...this.props.emailSuggestions] : [];
    if (
      !!email &&
      email.toLowerCase().substr(-ORTHO_EMAIL_DOMAIN.length) !== ORTHO_EMAIL_DOMAIN &&
      ORTHO_EMAIL_PATTERN.test(email)
    ) {
      suggestions.push(email.substr(0, email.indexOf('@')) + ORTHO_EMAIL_DOMAIN);
    }
    this.setState({
      suggestions: suggestions,
    });
  };

  handleEmailBlurred = () => {
    log('Found Valid Form');
    this.checkEmail();
  };

  handlePasswordVisibilityChanged = () => {
    this.setState({
      displayPasswordText: !this.state.displayPasswordText,
    });
  };

  formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      const isOrthoEmail = isValidOrthoEmployeeEmail(this.props.email || '');
      log('Is Valid Ortho User? ', isOrthoEmail);
      if (!isOrthoEmail) {
        /*
         * If the password input was shown then authenticate the user; otherwise
         * show the password field to the user.
         */
        if (!this.state.showPassword) {
          this.setState({
            showPassword: true,
          });
        } else {
          this.props.onCustomerFormSubmit();
        }
      } else {
        this.props.onEmployeeFormSubmit();
      }
    } else {
      log('Invalid Form!');
      if (!this.state.showPassword) {
        this.checkEmail();
      }
    }

    form.classList.add(CssClasses.WAS_VALIDATED);
  };

  isLoading = () => this.props.isPageLoading() || this.props.isAuthenticationLoading();

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div id="login-form" className="login-form">
          <form className="needs-validation" noValidate={true} onSubmit={this.formSubmitHandler}>
            <div className="col">
              <img src={personalInfoLogo} alt="" />
              <label htmlFor="validationCustom01">{t('form.label.loginInfo')}</label>
              <Autocomplete
                id="validationCustom01"
                placeholder={t('form.placeholder.loginEmail')}
                allowedPattern={ALLOWED_EMAIL_PATTERN}
                required={true}
                value={this.props.email}
                onInputChange={this.handleEmailChanged}
                onBlur={this.handleEmailBlurred}
                style={this.state.showPassword ? { borderColor: '#28a745' } : {}}
                autoFocus={true}
                suggestions={this.state.suggestions}
                isLoading={this.isLoading}
              />
              <label className="email-id">{t('form.label.loginEmail')}</label>

              {this.state.showPassword ? (
                <React.Fragment>
                  <div className="password-container">
                    <input
                      type={!this.state.displayPasswordText ? 'password' : 'text'}
                      className="form-control1"
                      placeholder={t('form.placeholder.password')}
                      required={true}
                      value={this.props.password}
                      onChange={this.props.onPasswordChange}
                      style={{ display: 'block' }}
                    />
                    <img
                      className="password-visibility login-password-visibility"
                      src={passwordVisibilityLogo}
                      alt=""
                      style={{ display: 'block' }}
                      onClick={this.handlePasswordVisibilityChanged}
                    />
                  </div>
                  <Link
                    to={{
                      pathname: Links.RESET_PASSWORD,
                      search: QueryParams.PASSWORD_RESET_EMAIL + encodeURIComponent(this.props.email || ''),
                    }}
                    style={{ display: 'block' }}
                  >
                    {t('form.link.forgotPassword')}
                  </Link>
                </React.Fragment>
              ) : null}
              <div className="invalid-feedback">{t('form.error.loginEmail.invalid')}</div>

              {this.props.errorMessage ? (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {this.props.errorMessage}
                </div>
              ) : null}

              {this.props.showVerificationError ? (
                <AccountPendingVerificationError
                  onResendVerificationLinkClick={this.props.onResendVerificationLinkClick}
                />
              ) : null}

              {this.props.isPageLoading() && (
                <div style={{ marginTop: 10 }}>
                  <p className="alert alert-info">{t('form.message.pageLoading')}</p>
                </div>
              )}

              {this.props.isAuthenticationLoading() && (
                <div style={{ marginTop: 10 }}>
                  <p className="alert alert-info">{t('form.message.authenticationInProgress')}</p>
                </div>
              )}

              <div className="form-btns">
                <button
                  className="btn btn-primary register-form-btn"
                  id="next"
                  type="submit"
                  disabled={this.isLoading()}
                >
                  {this.state.showPassword ? t('form.button.login') : t('form.button.next')}
                </button>
              </div>

              {this.isLoading() ? <CDCLoading styles={{ position: 'absolute', top: 60 }} /> : null}
            </div>
          </form>
        </div>
        <RegisterLink onRegisterLinkClick={this.props.onRegisterLinkClick} />
      </React.Fragment>
    );
  }
}

export default withTranslation('login')(LoginForm);
