import { IProfileForm } from './IProfileForm';
import { ISecurityQuestionsForm } from './ISecurityQuestionsForm';
import { ICustomerInfoForm } from './ICustomerInfoForm';
import IConsentForm from './IConsentForm';
import { RegistrationType } from './RegistrationType';

/**
 * Redux Action Types for Registration.
 *
 * @author Gazi Rahman
 */
export enum RegistrationActionType {
  RESET_REGISTRATION = 'RESET_REGISTRATION',
  SUBMIT_PROFILE_FORM = 'SUBMIT_PROFILE_FORM',
  CHANGE_PROFILE_FORM = 'CHANGE_PROFILE_FORM',
  SUBMIT_SECURITY_QUESTIONS_FORM = 'SUBMIT_SECURITY_QUESTIONS_FORM',
  GO_PREV_FROM_SECURITY_QUESTIONS_FORM = 'GO_PREV_FROM_SECURITY_QUESTIONS_FORM',
  SUBMIT_CUSTOMER_INFO_FORM = 'SUBMIT_CUSTOMER_INFO_FORM',
  GO_PREV_FROM_CUSTOMER_INFO_FORM = 'GO_PREV_FROM_CUSTOMER_INFO_FORM',
  SUBMIT_CONSENT_FORM = 'SUBMIT_CONSENT_FORM',
  GO_PREV_FROM_CONSENT_FORM = 'GO_PREV_FROM_CONSENT_FORM',
  START_REGISTRATION_SUBMIT = 'START_REGISTRATION_SUBMIT',
  FINISH_REGISTRATION_SUBMIT = 'FINISH_REGISTRATION_SUBMIT',
  FAIL_REGISTRATION_SUBMIT = 'FAIL_REGISTRATION_SUBMIT',
  CHECK_EMAIL_FOR_REGISTER = 'CHECK_EMAIL_FOR_REGISTER',
  SET_EMAIL_AVAILABLE_FOR_REGISTER = 'SET_EMAIL_AVAILABLE_FOR_REGISTER',
  SET_EMAIL_NOT_AVAILBLE_FOR_REGISTER = 'SET_EMAIL_NOT_AVAILBLE_FOR_REGISTER',
  SET_REGISTRATION_TYPE = 'SET_REGISTRATION_TYPE',
  SET_REGISTRATION_KEY = 'SET_REGISTRATION_KEY',
  SET_REQUESTED_FEATURES = 'SET_REQUESTED_FEATURES',
}

export interface IRegistrationReset {
  type: RegistrationActionType.RESET_REGISTRATION;
}

export interface ISubmitProfileForm {
  type: RegistrationActionType.SUBMIT_PROFILE_FORM;
  profileForm: IProfileForm;
}

export interface IChangeProfileForm {
  type: RegistrationActionType.CHANGE_PROFILE_FORM;
}

export interface ISubmitSecurityQuestionsForm {
  type: RegistrationActionType.SUBMIT_SECURITY_QUESTIONS_FORM;
  securityQuestionsForm: ISecurityQuestionsForm;
}

export interface IGoPreviousFromSecurityQuestionsForm {
  type: RegistrationActionType.GO_PREV_FROM_SECURITY_QUESTIONS_FORM;
  securityQuestionsForm: ISecurityQuestionsForm;
}

export interface ISubmitCustomerInfoForm {
  type: RegistrationActionType.SUBMIT_CUSTOMER_INFO_FORM;
  customerInfoForm: ICustomerInfoForm;
}

export interface ISubmitConsentForm {
  type: RegistrationActionType.SUBMIT_CONSENT_FORM;
  consentForm: IConsentForm;
}

export interface IGoPrevFromCustomerInfoForm {
  type: RegistrationActionType.GO_PREV_FROM_CUSTOMER_INFO_FORM;
  customerInfoForm: ICustomerInfoForm;
}

export interface IStartRegistrationSubmit {
  type: RegistrationActionType.START_REGISTRATION_SUBMIT;
}

export interface IFinishRegistrationSubmit {
  type: RegistrationActionType.FINISH_REGISTRATION_SUBMIT;
}

export interface IFaileRegistrationSubmit {
  type: RegistrationActionType.FAIL_REGISTRATION_SUBMIT;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IGoPrevFromConsentForm {
  type: RegistrationActionType.GO_PREV_FROM_CONSENT_FORM;
}

export interface ICheckEmailForRegister {
  type: RegistrationActionType.CHECK_EMAIL_FOR_REGISTER;
}

export interface ISetEmailAvailableForRegister {
  type: RegistrationActionType.SET_EMAIL_AVAILABLE_FOR_REGISTER;
}

export interface ISetEmailNotAvailableForRegister {
  type: RegistrationActionType.SET_EMAIL_NOT_AVAILBLE_FOR_REGISTER;
}

export interface ISetRegistrationType {
  type: RegistrationActionType.SET_REGISTRATION_TYPE;
  registrationType: RegistrationType;
}

export interface ISetRegistrationKey {
  type: RegistrationActionType.SET_REGISTRATION_KEY;
  registrationKey: string;
}

export interface ISetRequestedFeatures {
  type: RegistrationActionType.SET_REQUESTED_FEATURES;
  requestedFeatures: string;
}

export type IAllRegistrationActions =
  | IRegistrationReset
  | ISubmitProfileForm
  | IChangeProfileForm
  | ISubmitSecurityQuestionsForm
  | IGoPreviousFromSecurityQuestionsForm
  | ISubmitCustomerInfoForm
  | IGoPrevFromCustomerInfoForm
  | ISubmitConsentForm
  | IGoPrevFromConsentForm
  | IStartRegistrationSubmit
  | IFinishRegistrationSubmit
  | IFaileRegistrationSubmit
  | ICheckEmailForRegister
  | ISetEmailAvailableForRegister
  | ISetEmailNotAvailableForRegister
  | ISetRegistrationType
  | ISetRegistrationKey
  | ISetRequestedFeatures;
