import * as React from 'react';
import { Link } from 'react-router-dom';

import { Translation, Trans } from 'react-i18next';

import axios from '../../axiosFactory';

import Layout from '../layout/Layout';

/**
 * Prefix for the Display Name for WithConnectionErrorHandler Component
 *
 * @author Gazi Rahman
 */
const DISPLAY_NAME_PREFIX = 'WithConnectionErrorHandler_';

/**
 * High Order Component for Handling Http Connection Errors for all the REST
 * Communication between this app and the Server.
 *
 * @param WrappedComponent
 * @returns
 *
 * @author Gazi Rahman
 */
function withConnectionErrorHandler<T>(WrappedComponent: React.ComponentType<T>) {
  return class extends React.Component<T> {
    state = {
      error: null,
    };

    displayName = () => DISPLAY_NAME_PREFIX + WrappedComponent;

    reqInterceptor = -1;
    resInterceptor = -1;

    UNSAFE_componentWillMount() {
      this.reqInterceptor = axios.interceptors.request.use((req) => {
        this.setState({ error: null });
        return req;
      });
      this.resInterceptor = axios.interceptors.response.use(
        (res) => res,
        (error) => {
          this.setState({ error: error });
        },
      );
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
    };

    render() {
      const customerSupportEmail = process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL;
      return (
        <React.Fragment>
          {this.state.error ? (
            <Translation ns="common">
              {(t, { i18n }) => (
                <Layout title={t('errorPages.connectionErrorPage.pageTitle')} isErrorPage={true}>
                  <div className="error-section">
                    <div className="error-title">
                      <Trans ns="common" i18nKey="errorPages.connectionErrorPage.message">
                        <h2>There seems to be a problem with the connection.</h2>
                        <h2>Please make sure you have a reliable network connection and try again.</h2>
                      </Trans>
                    </div>
                    <Link className="login-btn" to="" onClick={this.errorConfirmedHandler}>
                      {t('errorPages.connectionErrorPage.loginLink')}
                    </Link>
                    {/* <button className="btn btn-primary login-btn" onClick={this.errorConfirmedHandler}>
                      {t('errorPages.connectionErrorPage.retryButton')}
                    </button> */}
                    <p>
                      <Trans ns="common" i18nKey="errorPages.connectionErrorPage.helpText">
                        If you need help please <a href={`mailto: ${customerSupportEmail}`}>email</a> the Ortho Plus℠
                        Technical Support Team
                      </Trans>
                    </p>
                  </div>
                </Layout>
              )}
            </Translation>
          ) : (
            <WrappedComponent {...this.props} />
          )}
        </React.Fragment>
      );
    }
  };
}

export default withConnectionErrorHandler;
