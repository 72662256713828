import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import log from '../../util/Log';

import IPasswordChangeState from './PasswordChangeState';

import axios from '../../axiosFactory';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import PasswordChangeTypes, {
  IStartPasswordChange,
  IFinishPasswordChange,
  IFailPasswordChange,
  IAllPaswordChangeActions,
  IResetPasswordChange,
} from './PasswordChangeActionTypes';
import { EndPoint } from '../../constants/EndPoints';

/**
 * Redux Action to Reset Password Change State
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetPasswordChange: ActionCreator<IResetPasswordChange> = () => ({
  type: PasswordChangeTypes.RESET_PASSWORD_CHANGE,
});

const passwordChangeUrl = process.env.REACT_APP_PASSWORD_CHANGE_URL || EndPoint.PASSWORD_CHANGE;

/**
 * Redux Thunk Action to Change Password
 *
 * @param passwordResetToken
 * @param password
 * @param verifyPassword
 * @param uid
 * @returns
 *
 * @author Gazi Rahman
 */
export const changePassword: ActionCreator<ThunkAction<
  Promise<any>,
  IPasswordChangeState,
  null,
  IAllPaswordChangeActions
>> = (passwordResetToken: string, password: string, verifyPassword: string, uid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startPasswordChange());
    const data = {
      passwordResetToken: passwordResetToken,
      password: password,
      verifyPassword: verifyPassword,
      uid: uid,
    };
    try {
      const response = await axios.post(passwordChangeUrl, data);
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response Password Change Request!', resData);
        return dispatch(failPasswordChange(resData.errorCodes, resData.message));
      } else {
        log('Password Change Request processed Successfully: ', resData);
        return dispatch(finishPasswordChange());
      }
    } catch (error) {
      log('Failed to send request to Change Password: ', error);
      return dispatch(
        failPasswordChange(['error.serverError'], 'Failed to send request to Change Password. Please try again later.'),
      );
    }
  };
};

/**
 * Redux Thunk Action to Change Password for Customer whose password is already
 * expired.
 *
 * @param oldPassword
 * @param password
 * @param verifyPassword
 * @param uid
 * @param regToken
 * @returns
 *
 * @author Gazi Rahman
 */
export const changeExpiredPassword: ActionCreator<ThunkAction<
  Promise<any>,
  IPasswordChangeState,
  null,
  IAllPaswordChangeActions
>> = (oldPassword: string, password: string, verifyPassword: string, uid?: string, regToken?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startPasswordChange());
    const data = {
      oldPassword: oldPassword,
      password: password,
      verifyPassword: verifyPassword,
      uid: uid,
      regToken: regToken,
    };
    try {
      const response = await axios.post(EndPoint.CHANGE_PASSWORD_WITH_OLDPASSWORD, data);
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response Password Change Request!', resData);
        return dispatch(failPasswordChange(resData.errorCodes, resData.message));
      } else {
        log('Password Change Request processed Successfully: ', resData);
        return dispatch(finishPasswordChange());
      }
    } catch (error) {
      log('Failed to send request to Change Password: ', error);
      return dispatch(
        failPasswordChange(['error.serverError'], 'Failed to send request to Change Password. Please try again later.'),
      );
    }
  };
};

const startPasswordChange: ActionCreator<IStartPasswordChange> = () => ({
  type: PasswordChangeTypes.START_PASSWORD_CHANGE,
});

const finishPasswordChange: ActionCreator<IFinishPasswordChange> = () => ({
  type: PasswordChangeTypes.FINISH_PASSWORD_CHANGE,
});

const failPasswordChange: ActionCreator<IFailPasswordChange> = (errorCodes: Array<string>, errorMessage: string) => ({
  type: PasswordChangeTypes.FAIL_PASSWORD_CHANGE,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
