import * as React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout from '../../hoc/layout/Layout';

/**
 * PasswordChange Error Page
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordChangeError: React.SFC<WithTranslation> = (props) => {
  const customerSupportEmail = process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL;

  const { t } = props;
  return (
    <Layout title={t('pageTitle.passwordChangeError')} isErrorPage={true}>
      <div className="error-section">
        <div className="error-title">
          <Trans ns={'passwordChange'} i18nKey="passwordChangeError.errorMessage">
            <h2>The system failed to reset your password.</h2>
            <h2>Please try again using the link provided in our email.</h2>
          </Trans>
        </div>
        <p>
          <Trans ns={'passwordChange'} i18nKey="passwordChangeError.helpMessage">
            If you need help please&nbsp;
            <a href={`mailto: ${customerSupportEmail}`}>email</a>&nbsp; the Ortho Plus℠ Technical Support Team
          </Trans>
        </p>
      </div>
    </Layout>
  );
};

export default withTranslation('passwordChange')(passwordChangeError);
