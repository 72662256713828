import * as React from 'react';
import { withRouter } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';

import { withTranslation, WithTranslation } from 'react-i18next';

import RegistrationStep from '../../store/register/RegistrationStep';
import { Links } from '../../constants/Links';

/**
 * Properties for the RegisterStepBar
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps, WithTranslation {
  activeStep: RegistrationStep;
}

/**
 * The bar component that displays the current step in the Registration Process.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const registerStepBar: React.SFC<IProps> = (props) => {
  const handleStepLinkClick = (ev: React.MouseEvent<HTMLAnchorElement>, registrationStep: RegistrationStep) => {
    ev.preventDefault();
    ev.stopPropagation();
    // this.setState({
    //   activeStep: registrationStep
    // });
  };

  // const getActiveStep = (props: IProps) => {
  //   const currentPath = props.match.path;
  //   if (currentPath.endsWith('/profileinfo')) {
  //     return RegistrationStep.PROFILE_INFO;
  //   } else if (currentPath.endsWith('/securityquestions')) {
  //     return RegistrationStep.SECURITY_QUESTIONS;
  //   } else if (currentPath.endsWith('/customerinfo')) {
  //     return RegistrationStep.CUSTOMER_FEATURES;
  //   } else if (currentPath.endsWith('/persmissions')) {
  //     return RegistrationStep.CONSENT_AGREEMENT;
  //   } else {
  //     return RegistrationStep.PROFILE_INFO;
  //   }
  // };

  let profileStepClasses = '';
  let securityQAStepClasses = '';
  let customerInfoStepClasses = '';
  let consentStepClasses = '';
  switch (props.activeStep) {
    case RegistrationStep.PROFILE_INFO:
      profileStepClasses = 'active';
      break;
    case RegistrationStep.SECURITY_QUESTIONS:
      profileStepClasses = 'completed';
      securityQAStepClasses = 'active';
      break;
    case RegistrationStep.CUSTOMER_FEATURES:
      profileStepClasses = 'completed';
      securityQAStepClasses = 'completed';
      customerInfoStepClasses = 'active';
      break;
    case RegistrationStep.CONSENT_AGREEMENT:
      profileStepClasses = 'completed';
      securityQAStepClasses = 'completed';
      customerInfoStepClasses = 'completed';
      consentStepClasses = 'active';
      break;
    case RegistrationStep.SUBMIT_REGISTRATION:
      profileStepClasses = 'completed';
      securityQAStepClasses = 'completed';
      customerInfoStepClasses = 'completed';
      consentStepClasses = 'completed';
      break;
    default:
  }

  const { t } = props;
  return (
    <div className="background">
      <div className="step-bar-wrapper">
        <div className="container">
          <ul className="step-wrapper">
            <li className={profileStepClasses}>
              <Link
                to={Links.REGISTER_PROFILE_INFO}
                style={{ cursor: 'default' }}
                onClick={(ev) => {
                  handleStepLinkClick(ev, RegistrationStep.PROFILE_INFO);
                }}
              />
              <span>
                <Link
                  to={Links.REGISTER_PROFILE_INFO}
                  style={{ cursor: 'default' }}
                  onClick={(ev) => {
                    handleStepLinkClick(ev, RegistrationStep.PROFILE_INFO);
                  }}
                >
                  {t('registrationStepBar.profileStep')}
                </Link>
              </span>
            </li>
            <span className="divider"></span>
            <li className={securityQAStepClasses}>
              <Link
                to={Links.REGISTER_SECURITY_QUESTIONS}
                style={{ cursor: 'default' }}
                onClick={(ev) => {
                  handleStepLinkClick(ev, RegistrationStep.SECURITY_QUESTIONS);
                }}
              />
              <span>
                <Link
                  to={Links.REGISTER_SECURITY_QUESTIONS}
                  style={{ cursor: 'default' }}
                  onClick={(ev) => {
                    handleStepLinkClick(ev, RegistrationStep.SECURITY_QUESTIONS);
                  }}
                >
                  {t('registrationStepBar.securityQuestions')}
                </Link>
              </span>
            </li>
            <span className="divider"></span>
            <li className={customerInfoStepClasses}>
              <Link
                to={Links.REGISTER_CUSTOMER_INFO}
                style={{ cursor: 'default' }}
                onClick={(ev) => {
                  handleStepLinkClick(ev, RegistrationStep.CUSTOMER_FEATURES);
                }}
              />
              <span>
                <Link
                  to={Links.REGISTER_CUSTOMER_INFO}
                  style={{ cursor: 'default' }}
                  onClick={(ev) => {
                    handleStepLinkClick(ev, RegistrationStep.CUSTOMER_FEATURES);
                  }}
                >
                  {t('registrationStepBar.customerInfo')}
                </Link>
              </span>
            </li>
            <span className="divider"></span>
            <li className={consentStepClasses}>
              <Link
                to={Links.REGISTER_PERMISSIONS}
                style={{ cursor: 'default' }}
                onClick={(ev) => {
                  handleStepLinkClick(ev, RegistrationStep.CONSENT_AGREEMENT);
                }}
              />
              <span>
                <Link
                  to={Links.REGISTER_PERMISSIONS}
                  style={{ cursor: 'default' }}
                  onClick={(ev) => {
                    handleStepLinkClick(ev, RegistrationStep.CONSENT_AGREEMENT);
                  }}
                >
                  {t('registrationStepBar.permissions')}
                </Link>
              </span>
            </li>
          </ul>
          {/*<a href="javascript:void(0)" className="button previous"><span>Previous</span></a>
      <a href="javascript:void(0)" className="button next"><span>Next</span></a> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation('register')(registerStepBar));
