/**
 * Constant for relative links to different part of the application.
 *
 * @author Gazi Rahman
 */
export enum Links {
  LOGIN = '/login',
  REGISTER_PROFILE_INFO = '/register/profileinfo',
  REGISTER_SECURITY_QUESTIONS = '/register/securityquestions',
  REGISTER_CUSTOMER_INFO = '/register/customerinfo',
  REGISTER_PERMISSIONS = '/register/permissions',
  REGISTER_FAILED = '/registerfailed',
  RESET_PASSWORD = '/passwordreset',
  EXPIRED_PASSWORD_CHANGE = '/expiredpasswordchange',
  PASSWORD_CHANGE_FAILED = '/passwordchangefailed',
}
