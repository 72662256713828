import { Reducer } from 'redux';
import IGigyaLogoutState from './IGigyaLogoutState';
import { IAllGigyaLogoutActions, GigyaLogoutActionTypes } from './GigyaLogoutActionTypes';

/**
 * Initial State for GigyaLogoutReducer
 *
 * @author Gazi Rahman
 */
const initialState: IGigyaLogoutState = {
  gigyalogoutProcessing: false,
  gigyaLogoutFinished: false,
  gigyaLogoutErrorCode: undefined,
  gigyaLogoutErrorMessage: undefined,
};

/**
 * Redux Reducer for Logout from Gigya
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const gigyaLogoutReducer: Reducer<IGigyaLogoutState, IAllGigyaLogoutActions> = (state = initialState, action) => {
  switch (action.type) {
    case GigyaLogoutActionTypes.GIGYA_LOGOUT_START:
      return {
        gigyalogoutProcessing: true,
        gigyaLogoutFinished: false,
        gigyaLogoutErrorCode: undefined,
        gigyaLogoutErrorMessage: undefined,
      };
    case GigyaLogoutActionTypes.GIGYA_LOGOUT_FINISH:
      return {
        gigyalogoutProcessing: false,
        gigyaLogoutFinished: true,
        gigyaLogoutErrorCode: undefined,
        gigyaLogoutErrorMessage: undefined,
      };
    case GigyaLogoutActionTypes.GIGYA_LOGOUT_ERROR:
      return {
        gigyalogoutProcessing: false,
        gigyaLogoutFinished: false,
        gigyaLogoutErrorCode: action.errorCode,
        gigyaLogoutErrorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default gigyaLogoutReducer;
