import { ActionCreator } from 'redux';

import {
  RegistrationActionType,
  ISubmitProfileForm,
  IChangeProfileForm,
  ISubmitSecurityQuestionsForm,
  IGoPreviousFromSecurityQuestionsForm,
  ISubmitCustomerInfoForm,
  IGoPrevFromCustomerInfoForm,
  IGoPrevFromConsentForm,
  ISubmitConsentForm,
} from './RegistrationActionTypes';
import { IProfileForm } from './IProfileForm';
import { ISecurityQuestionsForm } from './ISecurityQuestionsForm';
import { ICustomerInfoForm } from './ICustomerInfoForm';
import IConsentForm from './IConsentForm';

/**
 * Redux Action to submit Profile Form in Registration
 *
 * @param profileForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const submitProfileForm: ActionCreator<ISubmitProfileForm> = (profileForm: IProfileForm) => ({
  type: RegistrationActionType.SUBMIT_PROFILE_FORM,
  profileForm: profileForm,
});

/**
 * Redux Action to Change Registration Form during Registration
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const changeProfileForm: ActionCreator<IChangeProfileForm> = () => ({
  type: RegistrationActionType.CHANGE_PROFILE_FORM,
});

/**
 * Redux Action to Submit Security Questions form during Registration
 *
 * @param securityQuestionsForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const submitSequrityQuestionsForm: ActionCreator<ISubmitSecurityQuestionsForm> = (
  securityQuestionsForm: ISecurityQuestionsForm,
) => ({
  type: RegistrationActionType.SUBMIT_SECURITY_QUESTIONS_FORM,
  securityQuestionsForm: securityQuestionsForm,
});

/**
 * Redux Action to go to the previous State from the Security Questions Form
 * during Registration
 *
 * @param securityQuestionsForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const goPreviousFromSecurityQuestionsForm: ActionCreator<IGoPreviousFromSecurityQuestionsForm> = (
  securityQuestionsForm: ISecurityQuestionsForm,
) => ({
  type: RegistrationActionType.GO_PREV_FROM_SECURITY_QUESTIONS_FORM,
  securityQuestionsForm: securityQuestionsForm,
});

/**
 * Redux Action to Submit Customer Info Form during Registration
 *
 * @param customerInfoForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const submitCustomerInfoForm: ActionCreator<ISubmitCustomerInfoForm> = (
  customerInfoForm: ICustomerInfoForm,
) => ({
  type: RegistrationActionType.SUBMIT_CUSTOMER_INFO_FORM,
  customerInfoForm: customerInfoForm,
});

/**
 * Redux Action to go to the Previous Form from Customer Info Form during
 * Registration.
 *
 * @param customerInfoForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const goPrevFromCustomerInfoForm: ActionCreator<IGoPrevFromCustomerInfoForm> = (
  customerInfoForm: ICustomerInfoForm,
) => ({
  type: RegistrationActionType.GO_PREV_FROM_CUSTOMER_INFO_FORM,
  customerInfoForm: customerInfoForm,
});

/**
 * Redux Action to Submit the Consent Form during Registration
 *
 * @param consentForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const submitConsentForm: ActionCreator<ISubmitConsentForm> = (consentForm: IConsentForm) => ({
  type: RegistrationActionType.SUBMIT_CONSENT_FORM,
  consentForm: consentForm,
});

/**
 * Redux Action to Go to the Previous Form during Registration
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const goPrevFromConsentForm: ActionCreator<IGoPrevFromConsentForm> = () => ({
  type: RegistrationActionType.GO_PREV_FROM_CONSENT_FORM,
});
