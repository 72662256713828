import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import ISecurityQuestion from '../../store/securityquestion/ISecurityQuestion';

import SecurityQuestionSelector from '../securityquestionselector/SecurityQuestionSelector';

/**
 * Properties for SecurityQuestionAnswer Component
 */
interface IProps extends WithTranslation {
  notAllowedQuestions: Array<string>;
  questionNumber: number;
  question: string;
  onQuestionChange: (questionNumber: number, question: string) => void;
  answer: string;
  onAnswerChange: (questionNumber: number, answer: string) => void;
  securityQuestions?: Array<ISecurityQuestion>;
}

/**
 * Component that displayes a security question and takes it's answer from the
 * user.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const securityQuestionAnswer: React.SFC<IProps> = (props) => {
  const answerInputChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    props.onAnswerChange(props.questionNumber, currentVal);
  };

  const { t } = props;

  return (
    <div>
      <SecurityQuestionSelector
        questionNumber={props.questionNumber}
        question={props.question}
        onQuestionChange={props.onQuestionChange}
        notAllowedQuestions={props.notAllowedQuestions}
        securityQuestions={props.securityQuestions}
      />
      <input
        type="text"
        className="form-control"
        placeholder={`${t('securityQuestionForm.label.answer')} ${props.questionNumber}`}
        required={true}
        value={props.answer}
        onChange={answerInputChangeHandler}
      />
      <div className="invalid-feedback">{t('securityQuestionForm.error.answer.invalid')}</div>
    </div>
  );
};

export default withTranslation('register')(securityQuestionAnswer);
