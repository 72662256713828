import * as React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegisterLink from '../../../components/registerLink/RegisterLink';
import { Links } from '../../../constants/Links';

/**
 * Properties for PasswordResetSubmitted Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  onRegisterLinkClick: (event: React.MouseEvent) => void;
}

/**
 * Fragment page for PasswordResetSubmitted
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordResetSubmitted: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      <div className="error-section">
        <div className="error-title">
          <Trans ns="resetPassword" i18nKey="passwordResetSubmitted.message">
            <p className="mt-5">We sent an email to the address you registered with. </p>
            <p className="mt-1">Click on the button in the email to reset your password.</p>
            <p className="mt-5">
              <b>Didn’t get the email?</b>
            </p>
            <p className="mt-2">Check your spam/junk folder.</p>
          </Trans>
        </div>
        <Link className="login-btn" to={Links.LOGIN}>
          {t('passwordResetSubmitted.loginLink')}
        </Link>
        <RegisterLink onRegisterLinkClick={props.onRegisterLinkClick} />
      </div>
    </React.Fragment>
  );
};

export default withTranslation('resetPassword')(passwordResetSubmitted);
