/**
 * Endpoints to diffent Backend APIs
 *
 * @author Gazi Rahman
 */
export enum EndPoint {
  CUSTOMER_AUTH = '/api/authenticateCustomer',

  AFTER_AUTH_USER_INFO_UPDATER = '/api/updateUserInfoAfterAuth',

  AUTO_LOGIN_API = '/api/autologin',

  PASSWORD_CHANGE = '/api/passwordchange',

  CHANGE_PASSWORD_WITH_OLDPASSWORD = '/api/changePasswordWithOldPassword',

  RESET_PASSWORD = '/api/passwordreset',

  REGISTRATION = '/api/register',

  CHECK_EMAIL_AVAILABLE_FOR_REGISTER = '/api/register/email/available',

  SECURITY_QUESTIONS = '/api/securityquestions',

  VERIFICATION_EMAIL_SERVICE_URL = '/api/accountVerificationEmail',

  AUTO_LINKED_CUSTOMER_USER_URL = '/api/autolinkedcustomeruser',
}
