import * as React from 'react';
import { Trans } from 'react-i18next';

/**
 * React Properties for {@link #resendVerificationLink}
 *
 * @author Gazi Rahman
 */
interface IProps {
  onResendVerificationLinkClick: () => void;
}

/**
 * React Component for showing Account Pending Verification Error Message
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const AccountPendingVerificationError: React.SFC<IProps> = (props: IProps) => {
  const handleResendVerificationLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    props.onResendVerificationLinkClick();
  };

  return (
    <div className="invalid-feedback" style={{ display: 'block' }}>
      <Trans ns="login" i18nKey="form.link.resendVerification">
        Click
        <a style={{ display: 'inline' }} onClick={handleResendVerificationLinkClick} href="#">
          here
        </a>
        to resend account verification link.
      </Trans>
    </div>
  );
};

export default AccountPendingVerificationError;
