/**
 * @author Gazi Rahman
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import { IApplicationState } from '../../store/Store';
import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';

import log from '../../util/Log';

import Layout from '../../hoc/layout/Layout';
import { getGigyaSite } from '../../util/GigyaUtil';
import { loadGigya, checkGigyaLoaded } from '../../store/auth/gigya/GigyaActions';
import { RouteComponentProps } from 'react-router-dom';
import { GigyaSite } from '../../store/auth/gigya/GigyaSite';
import IGigyaState from '../../store/auth/gigya/IGigyaState';
import { retrieveAccountInfo } from '../../store/auth/gigya/accountInfoRetrieve/AccountInfoRetrieveActions';
import IAccountInfoRetrieveState from '../../store/auth/gigya/accountInfoRetrieve/IAccountInfoRetrieveState';
import IAutoLoginState from '../../store/auth/autologin/IAutoLoginState';
import { autoLogin } from '../../store/auth/autologin/AutoLoginActions';
import { Links } from '../../constants/Links';
import { CDCLoading } from '../../components/cdcloading/CDCLoading';

/**
 * Global Window Object
 *
 * @author Gazi Rahman
 */
declare const window: any;

/**
 * State for the AutoLogin Container
 *
 * @author Gazi Rahman
 */
interface IState {
  gigyaSite: GigyaSite;
}

/**
 * Route Parameters for the AutoLogin Page
 *
 * @author Gazi Rahman
 */
interface IRouteParams {
  gigyaSiteId: string;
}

/**
 * Properties for AutoLogin Container
 */
interface IProps
  extends RouteComponentProps<IRouteParams>,
    WithTranslation,
    IGigyaState,
    IAutoLoginState,
    IAccountInfoRetrieveState {
  loadGigya: typeof loadGigya;
  checkGigyaLoaded: typeof checkGigyaLoaded;
  autoLogin: typeof autoLogin;
  retrieveAccountInfo: typeof retrieveAccountInfo;
}

/**
 * Container for the AutoLogin Page.
 *
 * @author Gazi Rahman
 */
class AutoLogin extends React.Component<IProps, IState> {
  componentDidMount() {
    const gigyaSiteParam = this.props.match.params.gigyaSiteId;
    const gigyaSite: GigyaSite = getGigyaSite(gigyaSiteParam);
    this.setState({
      gigyaSite: gigyaSite,
    });
  }

  componentDidUpdate() {
    const { t } = this.props;

    if (this.props.autoLoginFinished) {
      this.props.history.push(Links.LOGIN);
    } else if (this.props.autoLoginFailed) {
      log(
        'Auto Login Failed! Error Code: ',
        this.props.autoLoginErrorCode,
        ' Error Message: ',
        this.props.autoLoginErrorMessage,
      );
    } else if (this.props.autoLoginLoading) {
      log('Auto Login Going on...');
    } else if (this.props.accountInfoRetrieved) {
      const res = this.props.accountInfoResponse;
      log('Logged in User: {}', res.profile);
      if (!res.isRegistered) {
        alert(t('error.notRegistered'));
      } else if (!res.isActive) {
        alert(t('error.notActive'));
        this.props.history.push(Links.LOGIN);
      } else if (!res.isVerified) {
        alert(t('error.notVerified'));
        this.props.history.push(Links.LOGIN);
      } else {
        log('User is logged in Gigya. Going to send auto login request');
        this.props.autoLogin(res.UID, res.UIDSignature, res.signatureTimestamp);
      }
    } else if (this.props.accountInfoRetrieveFailed) {
      alert(t('error.other'));
      this.props.history.push(Links.LOGIN);
    } else if (this.props.accountInfoRetrieveStarted) {
      log('Account Info Retrieval is going on...');
    } else if (this.props.gigyaLoaded) {
      log('Gigya is loaded. Going to retrieve account information.');
      this.props.retrieveAccountInfo();
    } else if (this.props.gigyaLoadStarted) {
      log('Gigya loading is still going on...');
      this.props.checkGigyaLoaded();
    } else {
      log('Gigya loading has not been started. Going to Initialize Gigya.');
      this.props.loadGigya(this.state.gigyaSite);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Layout title={t('pageTitle')}>
        <CDCLoading />

        <div style={{ marginTop: '70px' }} className="error-section">
          <p style={{ marginTop: '40px' }}>
            <Trans ns="autologin" i18nKey="message">
              Logging in to Ortho Plus℠
              <br />
              Please wait...
            </Trans>
          </p>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.gigyaState,
  ...state.autoLoginState,
  ...state.accountInfoRetrieveState,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadGigya: () => dispatch(loadGigya()),
  checkGigyaLoaded: () => dispatch(checkGigyaLoaded()),
  autoLogin: (uid: string, uidSignature: string, timestampSignature: string) =>
    dispatch(autoLogin(uid, uidSignature, timestampSignature)),
  retrieveAccountInfo: () => dispatch(retrieveAccountInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('autologin')(AutoLogin)));
