import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation } from 'react-i18next';

import log from '../../util/Log';

import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';

import { IApplicationState } from '../../store/Store';
import IPasswordChangeState from '../../store/passwordchange/PasswordChangeState';
import { resetPasswordChange, changePassword } from '../../store/passwordchange/PasswordChangeActions';

import Layout from '../../hoc/layout/Layout';

import PasswordChangeRequest from '../../forms/passwordchangerequest/PasswordChangeRequest';
import PasswordChangeSubmitted from './PasswordChangeSubmitted/PasswordChangeSubmitted';
import { CssClasses } from '../../constants/CssClasses';
import { Links } from '../../constants/Links';

/**
 * State for PasswordChange Component
 *
 * @author Gazi Rahman
 */
interface IState {
  password: string;
  verifyPassword: string;
  passwordsDoNotMatch: boolean;
}

/**
 * Route/Path Parameters for PasswordChange Component
 *
 * @author Gazi Rahman
 */
interface IRouteMaparms {
  apiKey: string;
  passwordResetToken: string;
}

/**
 * Properties for PasswordChange Component
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps<IRouteMaparms>, WithTranslation, IPasswordChangeState {
  resetPasswordChange: typeof resetPasswordChange;
  changePassword: typeof changePassword;
}

/**
 * Container Page for PasswordChange
 *
 * @author Gazi Rahman
 */
class PasswordChange extends React.Component<IProps, IState> {
  state = {
    password: '',
    verifyPassword: '',
    passwordsDoNotMatch: false,
  };

  componentDidMount() {
    this.props.resetPasswordChange();
    this.checkErrorPage();
  }

  componentDidUpdate() {
    this.checkErrorPage();
  }

  checkErrorPage = () => {
    if (this.props.passwordChangeFailed && !this.props.errorMessage) {
      this.props.history.push(Links.PASSWORD_CHANGE_FAILED);
    }
  };

  passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPassword = event.target.value;
    const currentVerifyPassword = this.state.verifyPassword;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== currentPassword;
    this.setState({
      password: currentPassword,
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  verifyPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const verifyPasswordInput = event.currentTarget;
    const currentVerifyPassword = verifyPasswordInput.value;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== this.state.password;
    const { t } = this.props;
    if (passwordsDoNotMatch) {
      verifyPasswordInput.setCustomValidity(t('passwordChangeRequest.error.confirmPassword.noMatch'));
    } else {
      verifyPasswordInput.setCustomValidity('');
    }
    this.setState({
      verifyPassword: currentVerifyPassword,
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  registerLinkClickHandler = (event: React.MouseEvent) => {
    log('Register Link is clicked from the Password Change page');
  };

  isLoading = () => {
    return this.props.passwordChangeStarted && !(this.props.passwordChangeFinished || this.props.passwordChangeFailed);
  };

  formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!this.state.passwordsDoNotMatch) {
      if (form.checkValidity() !== false) {
        log('Form is validated');
        const hash = this.props.history.location.hash;
        const uid = hash !== '' ? hash.substring(1) : null;
        this.props.changePassword(
          this.props.match.params.passwordResetToken,
          this.state.password,
          this.state.verifyPassword,
          uid,
        );
      } else {
        log('Form is not valid');
      }
    }
    form.classList.add(CssClasses.WAS_VALIDATED);
  };

  render() {
    log('Properties: ', this.props);
    const { t } = this.props;
    const title = this.props.passwordChangeFinished ? t('pageTitle.passwordChanged') : t('pageTitle.resetPassword');
    return (
      <Layout title={title} isErrorPage={this.props.passwordChangeFinished}>
        {!this.props.passwordChangeFinished ? (
          <PasswordChangeRequest
            requireOldPassword={false}
            password={this.state.password}
            verifyPassword={this.state.verifyPassword}
            passwordChangeHandler={this.passwordChangeHandler}
            verifyPasswordChangeHandler={this.verifyPasswordChangeHandler}
            onRegisterLinkClick={this.registerLinkClickHandler}
            isLoading={this.isLoading}
            formSubmitHandler={this.formSubmitHandler}
            serverError={this.props.passwordChangeFailed}
            serverErrorCodes={this.props.errorCodes}
            serverErrorMessage={this.props.errorMessage}
            passwordsDoNotMatch={this.state.passwordsDoNotMatch}
          />
        ) : (
          <PasswordChangeSubmitted />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.passwordChangeState,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPasswordChange: () => dispatch(resetPasswordChange()),
  changePassword: (passwordResetToken: string, password: string, verifyPassword: string, hash: string) =>
    dispatch(changePassword(passwordResetToken, password, verifyPassword, hash)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('passwordChange')(PasswordChange)));
