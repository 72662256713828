/**
 * Registration Steps
 *
 * @author Gazi Rahman
 */
enum RegistrationStep {
  PROFILE_INFO,
  SECURITY_QUESTIONS,
  CUSTOMER_FEATURES,
  CONSENT_AGREEMENT,
  SUBMIT_REGISTRATION,
}

export default RegistrationStep;
