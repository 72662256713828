import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryDet } from '../../util/utils';

import '../../assets/css/header.footer.css';
import SSOBanner from '../banners/SSOBanner';



const HeaderCmp = () => {
    const [bannerState, setBannerState] = useState(true);

    const { t, i18n } = useTranslation();


    const HEADER_FOOTER_DOMAIN_CONSTANT = "https://dev.quidelortho.com/";


    let languageCountry;
    let i18Language = i18n?.language?.toString().toLowerCase() || "en-global";


    // Checking if only language is selected?
    const isCountryAbsent = i18Language.split("-")?.length < 2;

    if (isCountryAbsent) {
        const country = getCountryDet(i18Language);

        languageCountry =
            country ? (i18Language + "-" + country) : (i18Language + "-" + i18Language);
    }
    else {
        languageCountry = i18Language;
    }




    const baseURL = process?.env?.REACT_APP_API_BASE_URL + "/";

    const headerFooterDomain =
        process?.env?.REACT_APP_PUBLIC_SITE_BASE_URL + "/"
        ||
        HEADER_FOOTER_DOMAIN_CONSTANT;



    const headerApi =
        process?.env?.REACT_APP_HEADER_FOOTER_WEB_SERVICE_URL
        +
        "?type=header&culture="
        +
        languageCountry;


    const cssScriptKey = "etc.clientlibs/quidelortho/clientlibs/clientlib-";
    const jsHeaderFooterScriptKey = "etc.clientlibs/quidelortho/clientlibs/clientlib-header-footer"
    const jsLibraryScriptKey = "etc.clientlibs/quidelortho/clientlibs/clientlib-libraries";



    const [headerSt, setHeaderSt] = useState("");



    const getHeader = () => {
        fetch(headerApi)
            .then(response => {
                return response.text();
            })
            .then((data) => {
                const enc1 = data;
                const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');


                const cssLinks = [];

                // Getting the CSS Links
                const links = newDocument.getElementsByTagName("link");

                for (let i = 0; i < links.length; i++) {
                    if (links[i].href.indexOf(jsHeaderFooterScriptKey) !== -1 || links[i].href.indexOf(jsLibraryScriptKey) !== -1) {
                        cssLinks.push(links[i].href)
                    }
                }


                if (cssLinks) {
                    for (let i = 0; i < cssLinks?.length; i++) {
                        const csslinkList = cssLinks[i].split('//')[1].split('/');

                        csslinkList && csslinkList.shift();

                        const cssLinkTag = document.createElement("link");


                        if (cssLinkTag) {
                            cssLinkTag.setAttribute("rel", "stylesheet");
                            cssLinkTag.setAttribute("type", "text/css");
                            cssLinkTag.href = headerFooterDomain + csslinkList?.join('/');
                        }

                        document.head.insertBefore(cssLinkTag, document.head.firstChild)
                    }
                }




                const imgs = newDocument?.getElementsByTagName("img");

                for (let i = 0; i < imgs.length; i++) {
                    if ((imgs[i]?.src?.indexOf("http://localhost") !== -1)
                        ||
                        (imgs[i].src.indexOf(baseURL) !== -1)
                    ) {


                        let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');

                        oldHeaderLogoSRC.shift();

                        imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');

                        if (imgs[i].srcset) {
                            imgs[i].srcset = "";
                        }
                    }
                }


                const a = newDocument?.getElementsByTagName("a");

                for (let i = 0; i < a.length; i++) {
                    if ((a[i].href.indexOf("http://localhost") !== -1)
                        ||
                        (a[i].href.indexOf(baseURL) !== -1)
                    ) {
                        let oldHeaderLogohref = a[i].href.split('//')[1].split('/');


                        oldHeaderLogohref.shift();

                        a[i].href = headerFooterDomain + oldHeaderLogohref.join('/');
                    }
                }



                // Removing login option
                const loginDiv = newDocument.getElementById("login-top-nav");
                if (loginDiv) {
                    loginDiv.style.display = "none";
                }



                const converted = newDocument?.body?.innerHTML;

                setHeaderSt(converted);



                const jsLinks = [];

                // Getting the JS Links
                const scripts = newDocument.getElementsByTagName("script");

                for (let i = 0; i < scripts.length; i++) {
                    if (scripts[i].src.indexOf(jsHeaderFooterScriptKey) !== -1 || scripts[i].src.indexOf(jsLibraryScriptKey) !== -1) {
                        jsLinks.push(scripts[i].src);
                    }
                }

                if (jsLinks) {
                    for (let i = 0; i < jsLinks.length; i++) {
                        const jsFullSrcList = jsLinks[i].split('//')[1].split('/');
                        jsFullSrcList && jsFullSrcList.shift()

                        const jsScript = document.createElement("script");
                        jsScript.src = headerFooterDomain + jsFullSrcList?.join('/');


                        const a = document.body.appendChild(jsScript);
                    }
                }
            })
            .catch((err) => {
                console.log("Header API call error:", err);
            });
    }


    useEffect(() => {
        getHeader();
    }, [t, i18n]);



    // Handling Country-Lang selector ~ redirecting to CID (SPA) instead of QO-Homepage
    useEffect(() => {
        if (headerSt) {
            const countryLangSelector = document.querySelector(".dropdown-glob-lang");


            if (countryLangSelector) {
                const anchorTags = countryLangSelector.getElementsByTagName("a");

                for (let anchorTag of anchorTags) {
                    if (anchorTag) {
                        const country =
                            anchorTag.href?.split("//")[1]?.split("/")[2]?.toString().toLowerCase();

                        const language =
                            anchorTag.href?.split("//")[1]?.split("/")[1]?.toString().toLowerCase();



                        anchorTag.href = "javascript:void(0)";



                        if (country && language) {
                            const countryLanguage = country + "-" + language;

                            anchorTag.onclick = () => {
                                console.log("country lang CHANGED from header   \n Language: " + language + " -- and country: " + country);

                                i18n.changeLanguage(countryLanguage);
                            };
                        }
                        // else if(!country && language) {
                        //     const countryLanguage = language + "-" + language;

                        //     anchorTag.onclick = () => {
                        //         console.log("country lang CHANGED from header   \n Language: " + language + " -- and country: " + country);

                        //         i18n.changeLanguage(countryLanguage);
                        //     };
                        // }
                    }
                }
            }



        }
    }, [headerSt]);



    return (
        <div>
            {
                bannerState &&
                <SSOBanner setBannerState={setBannerState} />
            }
            {
                headerSt
                    ?
                    <div
                        id="header"
                        className="xf-web-container"
                        dangerouslySetInnerHTML={{ __html: headerSt }}
                    >
                    </div>
                    :
                    <div className="hdr-ftr-loading">Loading.....</div>
            }
        </div>
    )
}

export default React.memo(HeaderCmp);