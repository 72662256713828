import * as React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Link Component to go back to the Home Page from other pages.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const homeLink: React.SFC<WithTranslation> = (props) => {
  const { t } = props;
  return (
    <Link to="/" className="login-btn">
      {t('homeLink')}
    </Link>
  );
};

export default withTranslation('common')(homeLink);
