import { Reducer } from 'redux';
import IAfterAuthUserInfoUpdateState from './IAfterAuthUserInfoUpdateState';
import { IAllAfterAuthUserInfoUpdateActions, AfterAuthUserInfoActionTypes } from './AfterAuthActionType';

/**
 * Initial State for AfterAuthUserInfoUpdate Reducer
 *
 * @author Gazi Rahman
 */
const initialState: IAfterAuthUserInfoUpdateState = {
  afterAuthUserInfoUpdateStarted: false,
  afterAuthUserInfoUpdateFinished: false,
  afterAuthUserInfoUpdateFailed: false,
  afterAuthUserInfoUpdateErrorCodes: undefined,
  afterAuthUserInfoUpdateErrorMessage: undefined,
};

/**
 * Redux Reducer for AfterAuthUserInfoUpdate
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const afterAuthUserInfoUpdateReducer: Reducer<IAfterAuthUserInfoUpdateState, IAllAfterAuthUserInfoUpdateActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AfterAuthUserInfoActionTypes.START_AFTER_AUTH_USER_INFO_UPDATE:
      return {
        ...state,
        afterAuthUserInfoUpdateStarted: true,
        afterAuthUserInfoUpdateFinished: false,
        afterAuthUserInfoUpdateFailed: false,
        afterAuthUserInfoUpdateErrorCodes: undefined,
        afterAuthUserInfoUpdateErrorMessage: undefined,
      };
    case AfterAuthUserInfoActionTypes.FINISH_AFTER_AUTH_USER_INFO_UPDATE:
      return {
        ...state,
        afterAuthUserInfoUpdateStarted: false,
        afterAuthUserInfoUpdateFinished: true,
        afterAuthUserInfoUpdateFailed: false,
        afterAuthUserInfoUpdateErrorCodes: undefined,
        afterAuthUserInfoUpdateErrorMessage: undefined,
      };
    case AfterAuthUserInfoActionTypes.FAILED_AFTER_AUTH_USER_INFO_UPDATE:
      return {
        ...state,
        afterAuthUserInfoUpdateStarted: false,
        afterAuthUserInfoUpdateFinished: false,
        afterAuthUserInfoUpdateFailed: true,
        afterAuthUserInfoUpdateErrorCodes: action.errorCodes,
        afterAuthUserInfoUpdateErrorMessage: action.errorMessage,
      };
    case AfterAuthUserInfoActionTypes.RESET_AFTER_AUTH_USER_INFO_UPDATE:
      return {
        ...state,
        afterAuthUserInfoUpdateStarted: false,
        afterAuthUserInfoUpdateFinished: false,
        afterAuthUserInfoUpdateFailed: false,
      };
    default:
      return state;
  }
};

export default afterAuthUserInfoUpdateReducer;
