import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import log from '../../../util/Log';

import axios, { API_BASE_URL } from '../../../axiosFactory';

import {
  AfterAuthUserInfoActionTypes,
  IAfterAuthUserInfoUpdateStarted,
  IAfterAuthUserInfoUpdateFinished,
  IAfterAuthUserInfoUpdateFailed,
  IAllAfterAuthUserInfoUpdateActions,
  IResetAfterAutheUserInfoUpdate,
} from './AfterAuthActionType';
import IAfterAuthUserInfoUpdateState from './IAfterAuthUserInfoUpdateState';
import IRestResponse from '../../../api/response/IRestResponse';
import { ResponseStatus } from '../../../api/response/ResponseStatus';
import { EndPoint } from '../../../constants/EndPoints';

/**
 * The Redux Actions used for AfterAuth UserInfo Update process
 *
 * @author Gazi Rahman
 */

/**
 * Backend/Server URL used to Load UserInfo After Authentication
 *
 * @author Gazi Rahman
 */
const afterAuthUserInfoUpdateUrl =
  process.env.REACT_APP_AFTER_AUTH_USER_INFO_UPDATE_URL || API_BASE_URL + EndPoint.AFTER_AUTH_USER_INFO_UPDATER;

/**
 * Resets AfterAuthUserInfoUpdate process
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetAfterAuthUserInfoUpdate: ActionCreator<IResetAfterAutheUserInfoUpdate> = () => ({
  type: AfterAuthUserInfoActionTypes.RESET_AFTER_AUTH_USER_INFO_UPDATE,
});

/**
 * Redux Action to Load/Update UserInfo after Authentication
 *
 * @param accessToken
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadUserInfoAfterAuthentication: ActionCreator<ThunkAction<
  Promise<any>,
  IAfterAuthUserInfoUpdateState,
  null,
  IAllAfterAuthUserInfoUpdateActions
>> = (accessToken: string) => {
  return async (dispatch: ThunkDispatch<IAfterAuthUserInfoUpdateState, null, IAllAfterAuthUserInfoUpdateActions>) => {
    dispatch(startAfterAuthUserInfoUpdate());
    try {
      const response = await axios.post<IRestResponse>(afterAuthUserInfoUpdateUrl, null, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const resData = response.data;

      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response for After Auth User Info Update!', resData);
        return dispatch(afterAuthUserInfoUpdateFailed(resData.errorCodes, resData.message));
      } else {
        log('After Auth User Info Updated Successfully.', resData);
        return dispatch(finishAfterAuthUserInfoUpdate());
      }
    } catch (error) {
      log('After Authentication User Info Update Failed!', error);
      return dispatch(
        afterAuthUserInfoUpdateFailed(
          'error.serverError',
          'Failed to update user information after user is authenticated.',
        ),
      );
    }
  };
};

const startAfterAuthUserInfoUpdate: ActionCreator<IAfterAuthUserInfoUpdateStarted> = () => ({
  type: AfterAuthUserInfoActionTypes.START_AFTER_AUTH_USER_INFO_UPDATE,
});

const finishAfterAuthUserInfoUpdate: ActionCreator<IAfterAuthUserInfoUpdateFinished> = () => ({
  type: AfterAuthUserInfoActionTypes.FINISH_AFTER_AUTH_USER_INFO_UPDATE,
});

const afterAuthUserInfoUpdateFailed: ActionCreator<IAfterAuthUserInfoUpdateFailed> = (
  errorCodes: Array<string>,
  errorMessage: string,
) => ({
  type: AfterAuthUserInfoActionTypes.FAILED_AFTER_AUTH_USER_INFO_UPDATE,
  errorCodes,
  errorMessage,
});
