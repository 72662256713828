import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { withTranslation, WithTranslation } from 'react-i18next';

import log from '../../util/Log';

import { GigyaSite } from '../../store/auth/gigya/GigyaSite';
import { DEFAULT_GIGYA_SITE, getGigyaSite } from '../../util/GigyaUtil';

import Layout from '../../hoc/layout/Layout';

import { IApplicationState } from '../../store/Store';
import IAuthState from '../../store/auth/IAuthState';
import { loadAuthentication, markAuthenticated, resetAuthenticationProcess } from '../../store/auth/AuthActions';
import {
  authenticateCustomer,
  sendAccountVerificationEmail,
  resetVerificationEmailSent,
} from '../../store/auth/AuthCustomerAction';
import { authenticateOrthoUser } from '../../store/auth/AuthOrthoUserAction';
import {
  loadUserInfoAfterAuthentication,
  resetAfterAuthUserInfoUpdate,
} from '../../store/auth/afterauth/AfterAuthActions';
import IAfterAuthUserInfoUpdateState from '../../store/auth/afterauth/IAfterAuthUserInfoUpdateState';

import { loadGigya, checkGigyaLoaded } from '../../store/auth/gigya/GigyaActions';
import IGigyaState from '../../store/auth/gigya/IGigyaState';
import IGigyaSessionState from '../../store/auth/gigya/session/IGigyaSessionState';
import { checkGigyaSession } from '../../store/auth/gigya/session/GigyaSessionActions';
import { gigyaRefreshUI, resetGigyaRefreshUI } from '../../store/auth/gigya/refreshUi/GigyaRefreshUIActions';
import IGigyaRefreshUIState from '../../store/auth/gigya/refreshUi/IGigyaRefreshUIState';

import LoginForm from '../../forms/loginform/LoginForm';
import { Links } from '../../constants/Links';
import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';
import { QueryParams } from '../../constants/QueryParams';
import VerificationEmailSent from './verificationEmailSent/VerificationEmailSent';

import { getSuccessullyLoggedInUsers } from '../../services/LogonHistoryService';

/**
 * Global gigya variable.
 *
 * @author Gazi Rahman
 */
declare const gigya: any;

/**
 * State for Login Container
 *
 * @author Gazi Rahman
 */
interface IState {
  email?: string;
  password: string;
  gigyaSite: GigyaSite;
  isLogonForSamlSP: boolean;
  isRedirectedFromWebView: boolean;
  previousLogonEmails: Array<string>;
  componentsMounted: boolean;
}

/**
 * Path Parameters for Login Container
 *
 * @author Gazi Rahman
 */
interface IRouteParams {
  gigyaSiteId: string;
}

/**
 * Properties for Login Container
 *
 * @author Gazi Rahman
 */
interface IProps
  extends RouteComponentProps<IRouteParams>,
    WithTranslation,
    IGigyaState,
    IGigyaRefreshUIState,
    IGigyaSessionState,
    IAuthState,
    IAfterAuthUserInfoUpdateState {
  loadAuthentication: typeof loadAuthentication;
  authenticateCustomer: typeof authenticateCustomer;
  authenticateOrthoUser: typeof authenticateOrthoUser;
  loadUserInfoAfterAuthentication: typeof loadUserInfoAfterAuthentication;
  markAuthenticated: typeof markAuthenticated;
  resetAuthenticationProcess: typeof resetAuthenticationProcess;
  resetAfterAuthUserInfoUpdate: typeof resetAfterAuthUserInfoUpdate;
  loadGigya: typeof loadGigya;
  checkGigyaLoaded: typeof checkGigyaLoaded;
  checkGigyaSession: typeof checkGigyaSession;
  gigyaRefreshUI: typeof gigyaRefreshUI;
  resetGigyaRefreshUI: typeof resetGigyaRefreshUI;
  sendAccountVerificationEmail: typeof sendAccountVerificationEmail;
  resetVerificationEmailSent: typeof resetVerificationEmailSent;
}

/**
 * Container for the Login Page
 *
 * @author Gazi Rahman
 */
class Login extends React.Component<IProps, IState> {
  state = {
    email: '',
    password: '',
    gigyaSite: DEFAULT_GIGYA_SITE,
    isLogonForSamlSP: false,
    isRedirectedFromWebView: false,
    previousLogonEmails: getSuccessullyLoggedInUsers(),
    componentsMounted: false,
  };

  componentDidMount = () => {
    // log("Login Props: ", JSON.stringify(this.props));

    const queryParams = queryString.parse(this.props.location.search);
    const redirectedFromWebViewParams = queryParams.redirectedFromWebView;
    const redirectedFromWebViewParam = redirectedFromWebViewParams
      ? Array.isArray(redirectedFromWebViewParams)
        ? redirectedFromWebViewParams[0]
        : redirectedFromWebViewParams
      : '';
    const redirectedFromWebView = redirectedFromWebViewParam === 'true';
    const emailParam = queryParams.email;
    const userEmail = emailParam ? (Array.isArray(emailParam) ? emailParam[0] : emailParam) : '';
    const initState = {
      email: userEmail,
      isRedirectedFromWebView: redirectedFromWebView,
      componentsMounted: true,
    };
    const initGigyaState = this.initGigyaState();
    this.setState({
      ...initState,
      ...initGigyaState,
    });
  };

  componentDidUpdate = () => {
    if (!this.props.authenticationLoadingStarted) {
      this.props.loadAuthentication();
    } else if (!this.props.gigyaLoaded) {
      /**
       * Load Gigya and then do other checks
       */
      log('Gigya is not Loaded yet.');

      if (!this.props.gigyaLoadStarted) {
        log('Gigya loading has not been started. Going to Initialize Gigya.');
        this.props.loadGigya(this.state.gigyaSite);
      } else {
        log('Gigya loading is still going on...');
        this.props.checkGigyaLoaded();
      }
    } else if (this.props.authPendingPasswordChange) {
      log('User need to change password befor login');
      this.resetAuthenticationStates();
      this.props.history.push(Links.EXPIRED_PASSWORD_CHANGE, {
        message: this.props.authErrorMessage,
        uid: this.props.uid,
        regToken: this.props.regToken,
      });
    } else if (this.props.passwordNotSet) {
      log('Password is not set yet');
      this.resetAuthenticationStates();
      this.props.history.push({
        pathname: Links.RESET_PASSWORD,
        search: QueryParams.PASSWORD_RESET_EMAIL + encodeURIComponent(this.state.email || ''),
        state: {
          initialPasswordSet: true,
        },
      });
    } else {
      log('Handling Authentication State');
      if (this.props.authenticated) {
        /**
         * User Authentication Process Finished
         */
        log('User is authenticated. Going to redirect to After Login Page');
        this.redirectToAfterLoginPage();
      } else if (
        this.props.authFinishedSuccessfully ||
        /* Check if Gigya Session was checked and a valid session exists */
        (this.props.sessionCheckFinished && this.props.sessionExists)
      ) {
        /**
         * Authentication is finished; now do the the after auth actions.
         */
        log('Authentication Finished Successfully');
        /**
         * Check if User Info is updated and Gigya API is refreshed after login.
         */
        if (this.props.afterAuthUserInfoUpdateFinished && this.props.gigyaRefreshUIFinished) {
          /**
           * All after initial login required actions are finished. Mark user
           * fully authenticated.
           */
          log('After auth info update finished. And Gigya refresh Ui finished');
          this.props.markAuthenticated();
        } else {
          if (this.props.gigyaRefreshUIFinished) {
            log('Gigya refresh UI finished');
            /**
             * Gigya Refresh API is finished. Now check if the user info is updated
             */
            if (this.props.afterAuthUserInfoUpdateFailed) {
              /* User Update Failed: Show the error to user */
              log('After auth User Info update failed!');
            } else if (!this.props.afterAuthUserInfoUpdateStarted) {
              /* User info is not updated after authentication. Update the user info. */
              log('Going to start after Auth User info Update process');
              this.props.loadUserInfoAfterAuthentication(this.props.accessTokenInfo?.accessToken);
            } else {
              /* User Info Update  is in Progress; show loading */
              log('User info update is in progress');
            }
          } else if (!this.props.gigyaRefreshUIStarted) {
            /**
             * Refresh Gigya API, if the process has not started yet.
             */
            log('Gigya Refresh UI Not started yet. Going to start it');
            // setTimeout(
            this.props.gigyaRefreshUI();
            // , 20000);
          }
        }
      } else if (this.props.sessionCheckFinished) {
        log('Gigya Session was checked after authentication was loaded and no valid session was found.');
      } else if (this.props.sessionChecking) {
        log('Gigya Session is being Checked...');
      } else if (this.props.authenticationLoaded) {
        log('Authentication has been loaded. Going to Check if Valid Gigya Session exists');
        this.props.checkGigyaSession();
      } else if (this.state.isRedirectedFromWebView) {
        log('Process Ortho User Authentication as the Login page is redirected from WebView');
        this.props.authenticateOrthoUser(
          this.state.gigyaSite,
          this.state.email,
          this.state.isRedirectedFromWebView,
          this.props.location.pathname,
        );
      }
    }
  };

  initGigyaState = () => {
    const gigyaSiteParam = this.props.match.params.gigyaSiteId;
    const gigyaSite: GigyaSite = getGigyaSite(gigyaSiteParam);
    const initGigyaState = {
      gigyaSite: gigyaSite,
      isLogonForSamlSP: !!gigyaSiteParam,
    };

    log('Initial Gigya State: ', initGigyaState);

    return initGigyaState;
  };

  redirectToAfterLoginPage = () => {
    // window.setTimeout(() => {
    if (this.state.isLogonForSamlSP) {
      gigya.fidm.saml.continueSSO(function () {
        log('User is being redirected to SAML SP Client');
      });
    } else {
      gigya.fidm.saml.initSSO({
        spName: process.env.REACT_APP_GIGYA_SAMLSP_DEFAULT_ID,
        redirectURL: process.env.REACT_APP_GIGYA_SAMLSP_DEFAULT_REDIRECT_URL,
      });
    }
    // }, 30000);
  };

  emailChangeHandler = (email: string) => {
    this.setState({
      email: email?.trim(),
    });
  };

  passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.currentTarget.value,
    });
  };

  isAuthenticationLoading = () => {
    const isAuthenticationLoadingInProgress =
      this.props.authenticationLoadingStarted &&
      !(this.props.authenticationLoaded || this.props.isAuthenticationCleared);
    const authProcessInProgress =
      this.props.authRequestStarted && !(this.props.authFinishedSuccessfully || this.props.authFailed);
    const afterAuthUpdateTobeStarted =
      this.props.authFinishedSuccessfully && !this.props.afterAuthUserInfoUpdateStarted;
    const afterAuthUserInfoUpdateInProgress =
      this.props.afterAuthUserInfoUpdateStarted &&
      !(this.props.afterAuthUserInfoUpdateFinished || this.props.afterAuthUserInfoUpdateFailed);
    const beingRedirectedToClientSP = this.props.afterAuthUserInfoUpdateFinished;

    return (
      isAuthenticationLoadingInProgress ||
      authProcessInProgress ||
      afterAuthUpdateTobeStarted ||
      afterAuthUserInfoUpdateInProgress ||
      beingRedirectedToClientSP
    );
  };

  isGigyaLoading = () => this.props.gigyaLoadStarted && !(this.props.gigyaLoaded || this.props.gigyaLoadFailed);

  isPageLoading = () => !this.state.componentsMounted || this.isGigyaLoading();

  /**
   * Resets the authentication process status, like clears up previous error
   * messages.
   * As an example, this can be used before redirecting to password reset page,
   * so that if the user comes back to login page later s/he is not automatically
   * redirected to the password reset page again.
   */
  private resetAuthenticationStates() {
    this.props.resetAuthenticationProcess();
    this.props.resetAfterAuthUserInfoUpdate();
    this.props.resetGigyaRefreshUI();
  }

  customerFormSubmitHandler = () => {
    this.resetAuthenticationStates();

    log('Starting Customer Authentication');
    this.props.authenticateCustomer(this.state.gigyaSite, this.state.email, this.state.password);
  };

  employeeFormSubmitHandler = () => {
    this.resetAuthenticationStates();

    log('Starting Ortho User Authentication');
    this.props.authenticateOrthoUser(this.state.gigyaSite, this.state.email, false, this.props.location.pathname);
  };

  registerLinkClickHandler = (event: React.MouseEvent) => {
    log('Register Link is clicked from Login Page');
    this.resetAuthenticationStates();
  };

  resendVerificationLinkClicked = () => {
    log('Resend Verification Email Link Clicked from Login Page');
    this.props.sendAccountVerificationEmail(this.state.email);
  };

  backToLoginClicked = (event: React.MouseEvent) => {
    log('Back to Login Page link clicked from Login Page');
    this.props.resetVerificationEmailSent();
  };

  render() {
    const { t } = this.props;
    const errorMessage =
      this.props.authFailed || this.props.pendingAccountVerification
        ? this.props.authErrorMessage
        : this.props.afterAuthUserInfoUpdateFailed
        ? this.props.afterAuthUserInfoUpdateErrorMessage
        : undefined;
    return (
      <Layout title={t('pageTitle')}>
        {this.props.resentAccountVerificationEmail ? (
          <VerificationEmailSent
            onRegisterLinkClick={this.registerLinkClickHandler}
            onBackToLoginClick={this.backToLoginClicked}
          />
        ) : (
          <LoginForm
            email={this.state.email}
            onEmailChange={this.emailChangeHandler}
            password={this.state.password}
            onPasswordChange={this.passwordChangeHandler}
            isAuthenticationLoading={this.isAuthenticationLoading}
            isPageLoading={this.isPageLoading}
            errorMessage={errorMessage}
            onCustomerFormSubmit={this.customerFormSubmitHandler}
            onEmployeeFormSubmit={this.employeeFormSubmitHandler}
            onRegisterLinkClick={this.registerLinkClickHandler}
            showVerificationError={this.props.pendingAccountVerification}
            onResendVerificationLinkClick={this.resendVerificationLinkClicked}
            verificationEmailSent={this.props.resentAccountVerificationEmail}
            emailSuggestions={this.state.previousLogonEmails}
          />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => ({
  ...store.authState,
  ...store.afterAuthUserInfoUpdateState,
  ...store.gigyaState,
  ...store.gigyaSessionState,
  ...store.gigyaRefreshUIState,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadAuthentication: () => dispatch(loadAuthentication()),
  authenticateCustomer: (gigyaSite: GigyaSite, username: string, password: string) =>
    dispatch(authenticateCustomer(gigyaSite, username, password)),
  authenticateOrthoUser: (
    gigyaSite: GigyaSite,
    username: string,
    isRedirectedFromWebView: boolean,
    locationPath: string,
  ) => dispatch(authenticateOrthoUser(gigyaSite, username, isRedirectedFromWebView, locationPath)),
  loadUserInfoAfterAuthentication: (accessToken: string) => dispatch(loadUserInfoAfterAuthentication(accessToken)),
  markAuthenticated: () => dispatch(markAuthenticated()),
  resetAuthenticationProcess: () => dispatch(resetAuthenticationProcess()),
  resetAfterAuthUserInfoUpdate: () => dispatch(resetAfterAuthUserInfoUpdate()),
  loadGigya: (gigyaSite: GigyaSite) => dispatch(loadGigya(gigyaSite)),
  checkGigyaLoaded: () => dispatch(checkGigyaLoaded()),
  gigyaRefreshUI: () => dispatch(gigyaRefreshUI()),
  resetGigyaRefreshUI: () => dispatch(resetGigyaRefreshUI()),
  checkGigyaSession: () => dispatch(checkGigyaSession()),
  sendAccountVerificationEmail: (emailId: string) => dispatch(sendAccountVerificationEmail(emailId)),
  resetVerificationEmailSent: () => dispatch(resetVerificationEmailSent()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('login')(Login)));
