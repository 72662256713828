import { ActionCreator, Dispatch } from 'redux';

import log from '../../../util/Log';

import {
  IGigyaLoadStartAction,
  GigyaActionType,
  IGigyaLoadFinished,
  IGigyaLoadFailedAction,
  IAllGigyaActions,
  IGigyaLoadCheckAction,
} from './GigyaActionType';
import { GigyaSite } from './GigyaSite';
import { ThunkAction } from 'redux-thunk';
import IGigyaState from './IGigyaState';

import { getGigyaApiKey } from '../../../util/GigyaUtil';

/**
 * Redux Actions for Gigya Script Loading
 *
 * @author Gazi Rahman
 */

/**
 * Global/Browser window variable
 *
 * @author Gazi Rahman
 */
declare const window: any;

/**
 * Redux Action to Check if Gigya Script has been loaded.
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const checkGigyaLoaded: ActionCreator<IGigyaLoadCheckAction> = () => {
  if (!!window.gigya && window.gigya.isGigya && window.onGigyaServiceReady) {
    window.onGigyaServiceReady();
  }

  return {
    type: GigyaActionType.GIGYA_LOAD_CHECKED,
  };
};

const SCRIPT_ELEMENT_NAME = 'script';
const GIGYA_SCRIPT_DOMAIN = process.env.REACT_APP_GIGYA_SCRIPT_DOMAIN || 'cdns.gigya.com';
const GIGYA_SCRIPT_URL = 'https://' + GIGYA_SCRIPT_DOMAIN + '/js/gigya.js';
const GIGYA_API_KEY_PARAM_STR = '?apiKey=';
const GIGYA_DEVICE_TYPE = 'auto';

/**
 * Redux Thunk Action to Load Gigya Scripts
 *
 * @param gigyaSite
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadGigya: ActionCreator<ThunkAction<Promise<any>, IGigyaState, null, IAllGigyaActions>> = (
  gigyaSite: GigyaSite,
) => {
  return async (dispatch: Dispatch) => {
    const gigyaLoaded = await new Promise(function (resolve, reject) {
      dispatch(startGigyaLoad(gigyaSite));

      if (!!window.gigya && window.gigya.isGigya) {
        resolve(true);
      } else {
        window.onGigyaServiceReady = function () {
          log('Gigya Loading Finished');
          resolve(true);
        };

        const gigyaApiKey = getGigyaApiKey(gigyaSite);
        const gigyaSdkConf = {
          deviceType: GIGYA_DEVICE_TYPE,
        };

        const s = document.createElement(SCRIPT_ELEMENT_NAME);
        s.type = 'text/javascript';
        s.async = true;
        s.src = GIGYA_SCRIPT_URL + GIGYA_API_KEY_PARAM_STR + gigyaApiKey;
        s.innerHTML = JSON.stringify(gigyaSdkConf);
        document.getElementsByTagName('head')[0].appendChild(s);
      }
    });
    /**
     * Gigya is loaded
     */
    if (gigyaLoaded) {
      return dispatch(finishGigyaLoad());
    } else {
      return dispatch(gigyaLoadFailed());
    }
  };
};

const startGigyaLoad: ActionCreator<IGigyaLoadStartAction> = (gigyaSite: GigyaSite) => ({
  type: GigyaActionType.GIGYA_LOAD_STARTED,
  gigyaSite: gigyaSite,
});

const finishGigyaLoad: ActionCreator<IGigyaLoadFinished> = () => ({
  type: GigyaActionType.GIGYA_LOAD_FINISHED,
});

const gigyaLoadFailed: ActionCreator<IGigyaLoadFailedAction> = (errorCodes: Array<string>, errorMessage: string) => ({
  type: GigyaActionType.GIGYA_LOAD_FAILED,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
