import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CDCLoading } from '../../components/cdcloading/CDCLoading';
import CustomerConset from '../../components/customerconsent/CustomerConsent';
import RegisterLink from '../../components/registerLink/RegisterLink';

/**
 * Properties for PasswordResetRequest Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  email?: string;
  isOrthoEmployeeEmail: boolean;
  termsAgreementNeeded: boolean;
  termsConditionsAgreed: boolean;
  onTermsConditionChange: (ev: React.ChangeEvent) => void;
  formSubmitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
  emailChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRegisterLinkClick: (event: React.MouseEvent) => void;
  isLoading: () => boolean;
  serverError: boolean;
  serverErrorCodes?: Array<string>;
  serverErrorMessage?: string;
}

/**
 * Form for Password Reset Request
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordResetRequest: React.SFC<IProps> = (props: IProps) => {
  const { t } = props;
  return (
    <React.Fragment>
      <div id="login-form" className="login-form forgot-form">
        {props.termsAgreementNeeded ? (
          <p className="pwd-reset-title">{t('message.initPasswordSet')}</p>
        ) : (
          <p className="pwd-reset-title">{t('passwordResetRequest.message')}</p>
        )}

        <form
          method="POST"
          action="plus-forgot-password-success.html"
          className="needs-validation"
          noValidate={true}
          onSubmit={props.formSubmitHandler}
        >
          <input
            type="text"
            className="form-control"
            value={props.email}
            placeholder={t('passwordResetRequest.placeholder.emailId')}
            required={true}
            onChange={props.emailChangeHandler}
            autoFocus={true}
          />
          <div className="invalid-feedback">
            {props.isOrthoEmployeeEmail
              ? t('passwordResetRequest.error.orthoEmployee')
              : t('passwordResetRequest.error.invalid')}
          </div>
          <label className="email-id">{t('passwordResetRequest.label.emailId')}</label>

          {props.termsAgreementNeeded && (
            <CustomerConset
              termsConditionsAgreed={props.termsConditionsAgreed}
              onTermsConditionChange={props.onTermsConditionChange}
            />
          )}

          {props.serverError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {props.serverErrorMessage}
            </div>
          ) : null}

          <div className="form-btns">
            <button className="btn btn-primary" type="submit" disabled={props.isLoading()}>
              {t('passwordResetRequest.label.submit')}
            </button>
          </div>

          {props.isLoading() ? (
            <div className="loader" style={{ display: 'block' }}>
              <CDCLoading />
            </div>
          ) : null}
        </form>
      </div>
      <RegisterLink onRegisterLinkClick={props.onRegisterLinkClick} />
    </React.Fragment>
  );
};

export default withTranslation('resetPassword')(passwordResetRequest);
