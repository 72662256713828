import IAutoLoginState from './IAutoLoginState';
import { Reducer } from 'redux';
import { IAllAutoLoginActions, AutoLoginActionType } from './AutoLoginActionType';

/**
 * Initial State for AutoLoginReducer
 *
 * @author Gazi Rahman
 */
const initialState: IAutoLoginState = {
  autoLoginFailed: false,
  autoLoginFinished: false,
  autoLoginLoading: false,
  autoLoginErrorCode: undefined,
  autoLoginErrorMessage: undefined,
};

/**
 * Redux Reducer for Auto Login
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const autoLoginReducer: Reducer<IAutoLoginState, IAllAutoLoginActions> = (state = initialState, action) => {
  switch (action.type) {
    case AutoLoginActionType.START_AUTO_LOGIN:
      return {
        ...state,
        autoLoginLoading: true,
      };
    case AutoLoginActionType.FINISH_AUTO_LOGIN:
      return {
        ...state,
        autoLoginLoading: false,
        autoLoginFinished: true,
      };
    case AutoLoginActionType.FAIL_AUTO_LOGIN:
      return {
        ...state,
        autoLoginLoading: false,
        autoLoginFailed: true,
        autoLoginErrorCode: action.errorCode || '',
        autoLoginErrorMessage: action.errorMessage || '',
      };
    default:
      return state;
  }
};

export default autoLoginReducer;
