import { CssClasses } from '../constants/CssClasses';

/**
 * Utilities for Password Strength Check used in the application.
 *
 * @author Gazi Rahman
 */

/**
 * Password Strength Progress Bar Widths
 *
 * @author Gazi Rahman
 */
export enum PasswordProgressBarWidths {
  PERCENT_0 = '0px',
  PERCENT_20 = '20%',
  PERCENT_40 = '40%',
  PERCENT_60 = '60%',
  PERCENT_80 = '80%',
  PERCENT_100 = '100%',
}

/**
 * Password Strength Progress Bar Descriptive Classes
 *
 * @author Gazi Rahman
 */
export const PasswordStrengthDescClass = [
  '',
  CssClasses.PASSWORD_STRENGTH_WEAK,
  CssClasses.PASSWORD_STRENGTH_WEAK,
  CssClasses.PASSWORD_STRENGTH_OK,
  CssClasses.PASSWORD_STRENGTH_GOOD,
  CssClasses.PASSWORD_STRENGTH_GOOD,
];

/**
 * Password Strength Progress Bar Descriptions
 *
 * @author Gazi Rahman
 */
export const PASSWORD_STRENGTH_DESC = [
  {
    width: PasswordProgressBarWidths.PERCENT_0,
  },
  {
    width: PasswordProgressBarWidths.PERCENT_20,
  },
  {
    width: PasswordProgressBarWidths.PERCENT_40,
  },
  {
    width: PasswordProgressBarWidths.PERCENT_60,
  },
  {
    width: PasswordProgressBarWidths.PERCENT_80,
  },
  {
    width: PasswordProgressBarWidths.PERCENT_100,
  },
];

/**
 * Calculates Strength of a given Password
 *
 * @param password
 * @returns
 *
 * @author Gazi Rahman
 */
export const calculatePasswordStrength = (password: string) => {
  let score = 0;

  if (password.length > 6) {
    score++;
    // $(".strength-text").text("Strength: Weak");
    // $(".strength-text").css("color", "#F54D4A");
  }

  if (password.match(/[a-z]/) || password.match(/[A-Z]/)) {
    score++;
    // $(".strength-text").text("Strength: Moderate");
    // $(".strength-text").css("color", "#F3AE3D");
  }

  if (password.match(/\d+/)) {
    score++;
    // $(".strength-text").text("Strength: Moderate");
    // $(".strength-text").css("color", "#F3AE3D");
  }

  if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
    score++;
    // $(".strength-text").text("Strength: Moderate");
    // $(".strength-text").css("color", "#F3AE3D");
  }

  if (password.length > 10) {
    score++;
    // $(".strength-text").text("Strength: Strong");
    // $(".strength-text").css("color", "#76A95A");
  }
  return score;
};
