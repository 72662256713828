import * as React from 'react';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import {
  submitCustomerInfoForm,
  goPrevFromCustomerInfoForm,
} from '../../../store/register/RegistrationNavigationActions';

import LoginLink from '../../../components/loginLink/LoginLink';

import customerInfoImg from '../../../assets/images/CDC/customer-info.svg';
import organizationImg from '../../../assets/images/CDC/organisation.svg';
import IRegistrationState from '../../../store/register/IRegistrationState';
import { ICustomerInfoForm, ICustomerInfo } from '../../../store/register/ICustomerInfoForm';
import { IApplicationState } from '../../../store/Store';
import { Dispatch } from 'redux';
import { CssClasses } from '../../../constants/CssClasses';
import IAutoLinkedCustomerUser from '../../../api/response/IAutoLinkedCustomerUser';

/**
 * Properties for CustomerInfoForm
 */
interface IProps extends IRegistrationState, WithTranslation {
  submitCustomerInfoForm: typeof submitCustomerInfoForm;
  goPrevFromCustomerInfoForm: typeof goPrevFromCustomerInfoForm;
  autoLinkedCustomerUser?: IAutoLinkedCustomerUser;
}

/**
 * State for CustomerInfoForm
 *
 * @author Gazi Rahman
 */
type IState = ICustomerInfoForm;

/**
 * Initial State for CustomerInfo Form
 *
 * @author Gazi Rahman
 */
const initCustomerInfo: ICustomerInfo = {
  customerNumber: '',
  organization: '',
  city: '',
  zip: '',
  comment: '',
};

/**
 * Registration form for Customer Info
 *
 * @author Gazi Rahman
 */
class CustomerInfoForm extends React.Component<IProps, IState> {
  state = {
    ...this.props.registrationForm.customerInfoForm,
  };

  componentDidMount(): void {
    if (this.props.autoLinkedCustomerUser) {
      this.setState({
        ...this.state,
        existingCustomer: true,
        customerInfo: {
          customerNumber: this.props.autoLinkedCustomerUser.customerNumber,
          organization: this.props.autoLinkedCustomerUser.organizationID || '',
          city: this.props.autoLinkedCustomerUser.city || '',
          zip: this.props.autoLinkedCustomerUser.zip || '',
          comment: this.props.autoLinkedCustomerUser.comment,
        },
      });
    }
  }

  handleExistingCustomerChanged = (ev: React.ChangeEvent) => {
    const existingCustomer = !this.state.existingCustomer;
    const customerInfo: ICustomerInfo | undefined = existingCustomer ? initCustomerInfo : undefined;
    this.setState({
      existingCustomer: existingCustomer,
      customerInfo: customerInfo,
    });
  };

  handleFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      form.classList.add(CssClasses.WAS_VALIDATED);
    } else {
      this.props.submitCustomerInfoForm(this.state);
    }
  };

  handlePrevButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    this.props.goPrevFromCustomerInfoForm(this.state);
  };

  customerNumberChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    if (this.state.customerInfo) {
      this.setState({
        customerInfo: {
          ...this.state.customerInfo,
          customerNumber: currentVal,
        },
      });
    }
  };

  organizationChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    if (this.state.customerInfo) {
      this.setState({
        customerInfo: {
          ...this.state.customerInfo,
          organization: currentVal,
        },
      });
    }
  };

  cityChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    if (this.state.customerInfo) {
      this.setState({
        customerInfo: {
          ...this.state.customerInfo,
          city: currentVal,
        },
      });
    }
  };

  zipChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    if (this.state.customerInfo) {
      this.setState({
        customerInfo: {
          ...this.state.customerInfo,
          zip: currentVal,
        },
      });
    }
  };

  commentChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = ev.currentTarget.value;
    if (this.state.customerInfo) {
      this.setState({
        customerInfo: {
          ...this.state.customerInfo,
          comment: currentVal,
        },
      });
    }
  };

  render() {
    const customerSupportLink = process.env.REACT_APP_CUSTOMER_SUPPORT_LINK;
    const { t } = this.props;
    return (
      <section className="third-form customer-info">
        <form className="needs-validation" noValidate={true} onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <img src={customerInfoImg} alt="" />
            <label>{t('customerInfoForm.label.customerInfo')}</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="invalidCheck"
                value=""
                checked={this.state.existingCustomer}
                onChange={!this.props.autoLinkedCustomerUser ? this.handleExistingCustomerChanged : undefined}
                autoFocus={!this.props.autoLinkedCustomerUser ? true : false}
                readOnly={!!this.props.autoLinkedCustomerUser}
                tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
              />
              <label className="form-check-label" htmlFor="invalidCheck">
                {t('customerInfoForm.label.customerConfirmation')}
              </label>
              <div className="invalid-feedback">{t('customerInfoForm.error.customerConfirmation.invalid')}</div>
            </div>
            {this.state.existingCustomer ? (
              <input
                type="text"
                className="form-control"
                placeholder={t('customerInfoForm.placeHolder.customerNumber')}
                required={true}
                value={this.state.customerInfo?.customerNumber}
                onChange={!this.props.autoLinkedCustomerUser ? this.customerNumberChangeHandler : undefined}
                readOnly={!!this.props.autoLinkedCustomerUser}
                tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
              />
            ) : null}
          </div>
          {this.state.existingCustomer ? (
            <React.Fragment>
              <img src={organizationImg} alt="" />
              <label className="mt-5">{t('customerInfoForm.label.organizationInfo')}</label>

              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('customerInfoForm.placeHolder.organizationId')}
                  required={true}
                  value={this.state.customerInfo?.organization}
                  onChange={!this.props.autoLinkedCustomerUser ? this.organizationChangeHandler : undefined}
                  readOnly={!!this.props.autoLinkedCustomerUser}
                  tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
                />
                <div className="invalid-feedback">{t('customerInfoForm.error.organizationId.invalid')}</div>
              </div>

              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('customerInfoForm.placeHolder.city')}
                  required={true}
                  value={this.state.customerInfo?.city}
                  onChange={!this.props.autoLinkedCustomerUser ? this.cityChangeHandler : undefined}
                  readOnly={!!this.props.autoLinkedCustomerUser}
                  tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
                />
                <div className="invalid-feedback">{t('customerInfoForm.error.city.invalid')}</div>
              </div>

              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('customerInfoForm.placeHolder.zip')}
                  required={true}
                  value={this.state.customerInfo?.zip}
                  onChange={!this.props.autoLinkedCustomerUser ? this.zipChangeHandler : undefined}
                  readOnly={!!this.props.autoLinkedCustomerUser}
                  tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
                />
                <div className="invalid-feedback">{t('customerInfoForm.error.zip.invalid')}</div>
              </div>

              <input
                type="text"
                className="form-control"
                placeholder={t('customerInfoForm.placeHolder.comment')}
                value={this.state.customerInfo?.comment}
                onChange={!this.props.autoLinkedCustomerUser ? this.commentChangeHandler : undefined}
                readOnly={!!this.props.autoLinkedCustomerUser}
                tabIndex={this.props.autoLinkedCustomerUser ? -1 : 0}
              />

              <span className="grey-text">
                <Trans ns="register" i18nKey="customerInfoForm.message.help">
                  If you do not have the information needed for the fields above,&nbsp;
                  <a target="_blank" rel="noopener noreferrer" href={customerSupportLink}>
                    please contact an Ortho Plus® Support Specialist
                  </a>
                  .
                </Trans>
              </span>
            </React.Fragment>
          ) : null}
          <div className="form-btns">
            <button className="btn btn-primary previous-btn-1" type="button" onClick={this.handlePrevButtonClick}>
              {t('buttonLabel.previous')}
            </button>
            <button className="btn btn-primary register-form-btn next" type="submit">
              {t('buttonLabel.next')}
            </button>
          </div>
        </form>
        <LoginLink />
      </section>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.registrationState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitCustomerInfoForm: (customerInfoForm: ICustomerInfoForm) => dispatch(submitCustomerInfoForm(customerInfoForm)),
  goPrevFromCustomerInfoForm: (customerInfoForm: ICustomerInfoForm) =>
    dispatch(goPrevFromCustomerInfoForm(customerInfoForm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('register')(CustomerInfoForm));
