import { API_BASE_URL } from '../axiosFactory';

/**
 * Utilities related to URLs.
 *
 * @author Gazi Rahman
 */

const LOGIN_ORTHO_USER_ENDPOINT = '/loginOrthoUser';
// const AZURE_SSO_HANDLER_ENDPOINT = '/gigyaAzureSSOHandler';

const orthoUserSSOUrl = process.env.REACT_APP_ORTHO_USER_SSO_URL || API_BASE_URL + LOGIN_ORTHO_USER_ENDPOINT;
// const azureSooHandlerUrl = API_BASE_URL + AZURE_SSO_HANDLER_ENDPOINT;

const EMAIL_PARAM_STR = '?email=';
const SITE_PARAM_STR = '&siteApiKey=';
const IS_WEBVIEW_PARAM = '&isWebView=';
const LOCATION_PATH_PARAM = '&locationPath=';

export const generateSSOWindowURL = function (
  emailId: string,
  siteApiKey: string,
  langUrlComponent: string,
  isWebView: boolean,
  locationPath: string,
) {
  return (
    orthoUserSSOUrl +
    EMAIL_PARAM_STR +
    encodeURIComponent(emailId) +
    SITE_PARAM_STR +
    encodeURIComponent(siteApiKey) +
    langUrlComponent +
    IS_WEBVIEW_PARAM +
    encodeURIComponent(isWebView) +
    LOCATION_PATH_PARAM +
    encodeURIComponent(locationPath)
  );
};
