import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import { IApplicationState } from '../../store/Store';
import IAuthState from '../../store/auth/IAuthState';
import { loadAuthentication } from '../../store/auth/AuthActions';
import { clearAuthentication } from '../../store/auth/AuthActions';
import IGigyaState from '../../store/auth/gigya/IGigyaState';
import { loadGigya, checkGigyaLoaded } from '../../store/auth/gigya/GigyaActions';
import { GigyaSite } from '../../store/auth/gigya/GigyaSite';
import { getGigyaSite } from '../../util/GigyaUtil';
import { gigyaLogout } from '../../store/auth/gigya/logout/GigyaLogoutActions';

import Layout from '../../hoc/layout/Layout';
import IGigyaLogoutState from '../../store/auth/gigya/logout/IGigyaLogoutState';

import { CDCLoading } from '../../components/cdcloading/CDCLoading';

/**
 * Global window variable
 *
 * @author Gazi Rahman
 */
declare const window: any;

/**
 * Path Parameters for Logout Container
 *
 * @author Gazi Rahman
 */
interface IRouteParams {
  gigyaSiteId: string;
}

/**
 * Properties for Logout Container
 *
 * @author Gazi Rahman
 */
interface IProps
  extends RouteComponentProps<IRouteParams>,
    WithTranslation,
    IAuthState,
    IGigyaState,
    IGigyaLogoutState {
  loadAuthentication: typeof loadAuthentication;
  loadGigya: typeof loadGigya;
  checkGigyaLoaded: typeof checkGigyaLoaded;
  clearAuthentication: typeof clearAuthentication;
  gigyaLogout: typeof gigyaLogout;
}

/**
 * State for Logout Container
 *
 * @author Gazi Rahman
 */
interface IState {
  redirectToUrlAfterLogout?: string;
}

/**
 * Logout Container Page
 *
 * @author Gazi Rahman
 */
class Logout extends React.Component<IProps, IState> {
  state = {
    redirectToUrlAfterLogout: undefined,
  };

  componentDidMount() {
    this.props.loadAuthentication();

    const gigyaSite: GigyaSite = getGigyaSite(this.props.match.params.gigyaSiteId);
    this.props.loadGigya(gigyaSite);

    const queryParams = queryString.parse(this.props.location.search);
    const redirectUrlParam = queryParams.redirectUrl;
    if (redirectUrlParam) {
      const redirectUrl = Array.isArray(redirectUrlParam) ? redirectUrlParam[0] : redirectUrlParam;
      this.setState({
        redirectToUrlAfterLogout: redirectUrl,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.gigyaLoaded) {
      if (this.props.gigyaLogoutFinished) {
        /**
         * User will be redirected to the URL passed in the query parameter
         */
        window.location.href = this.state.redirectToUrlAfterLogout;
        // this.props.history.push('/register');
      } else if (!this.props.authenticationLoaded) {
        /**
         * User authentication is cleared.
         */
        if (this.state.redirectToUrlAfterLogout) {
          /**
           * If user should be logged out with direct API Call.
           */
          if (!this.props.gigyalogoutProcessing) {
            /**
             * Only proces logout if it has not started yet.
             */
            this.props.gigyaLogout();
          }
        } else {
          /**
           * User is logged out by the Gigya (Proxy page); so nothing to do here.
           */
        }
      } else {
        /**
         * User is still logged in
         */
        this.props.clearAuthentication();
      }
    } else {
      this.props.checkGigyaLoaded();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Layout title={t('pageTitle')} isErrorPage={true}>
        <CDCLoading />

        <div style={{ marginTop: '70px' }} className="error-section">
          <p style={{ marginTop: '40px' }}>
            <Trans ns="logout" i18nKey="message">
              Logging out from Ortho Plus℠
              <br />
              Please wait...
            </Trans>
          </p>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => ({
  ...store.gigyaState,
  ...store.gigyaLogoutState,
  ...store.authState,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadAuthentication: () => dispatch(loadAuthentication()),
  loadGigya: (gigyaSite: GigyaSite) => dispatch(loadGigya(gigyaSite)),
  checkGigyaLoaded: () => dispatch(checkGigyaLoaded()),
  clearAuthentication: () => dispatch(clearAuthentication()),
  gigyaLogout: () => dispatch(gigyaLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('logout')(Logout));
