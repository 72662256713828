import React, { Component, Fragment } from 'react';

const SUGGESTIONS_DELAY_IN_MILIS = 200;

/**
 * Properties for {@link Autocomplete} component.
 *
 * @author Gazi Rahman
 */
interface IProps {
  id: string;
  suggestions: Array<string>;
  onInputChange: (value: string) => void;
  value?: string;
  placeholder: string;
  allowedPattern: string;
  required: boolean;
  onBlur: () => void;
  style: any;
  autoFocus: boolean;
  noSuggestionText?: string;
  isLoading: () => boolean;
}

/**
 * State for {@link Autocomplete} component.
 *
 * @author Gazi Rahman
 */
interface IState {
  activeSuggestion: number;
  filteredSuggestions: Array<string>;
  showSuggestions: boolean;
  // userInput?: string;
}

/**
 * Component to autocomplete an input text from a list of suggestions.
 *
 * This Component was created using mechanism described in:
 * {@link https://www.digitalocean.com/community/tutorials/react-react-autocomplete How To Build an Autocomplete Component in React}
 *
 * @author Gazi Rahman
 * @see https://www.digitalocean.com/community/tutorials/react-react-autocomplete
 */
class Autocomplete extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    };
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.currentTarget.value;

    this.props.onInputChange(newInputValue);

    setTimeout(() => {
      const { suggestions } = this.props;
      const userInput = newInputValue;

      const filteredSuggestions = suggestions.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
      );

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: !!filteredSuggestions,
      });
    }, SUGGESTIONS_DELAY_IN_MILIS);
  };

  onClick = (e: React.MouseEvent<HTMLLIElement>) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    });

    this.props.onInputChange(e.currentTarget.innerText);
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    /* ENTER Key */
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
      });

      if (filteredSuggestions[activeSuggestion]) {
        this.props.onInputChange(filteredSuggestions[activeSuggestion]);
      }
    } else if (e.keyCode === 38) {
      /* Up Arrow */
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      /* User pressed the down arrow, increment the index */
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: { activeSuggestion, filteredSuggestions, showSuggestions },
    } = this;

    let suggestionsListComponent;
    if (showSuggestions && this.props.value) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else if (this.props.noSuggestionText) {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>{this.props.noSuggestionText}</em>
          </div>
        );
      }
    }
    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={this.props.value}
          className="form-control"
          id={this.props.id}
          placeholder={this.props.placeholder}
          pattern={this.props.allowedPattern}
          required={this.props.required}
          onBlur={this.props.onBlur}
          style={this.props.style}
          autoFocus={this.props.autoFocus}
          disabled={this.props.isLoading()}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
