import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation } from 'react-i18next';

import log from '../../util/Log';

import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';

import { IApplicationState } from '../../store/Store';
import IPasswordChangeState from '../../store/passwordchange/PasswordChangeState';
import { resetPasswordChange, changeExpiredPassword } from '../../store/passwordchange/PasswordChangeActions';

import Layout from '../../hoc/layout/Layout';

import PasswordChangeRequest from '../../forms/passwordchangerequest/PasswordChangeRequest';
import PasswordChangeSubmitted from '../passwordchange/PasswordChangeSubmitted/PasswordChangeSubmitted';
import { CssClasses } from '../../constants/CssClasses';
import { Links } from '../../constants/Links';

/**
 * State for the ExpiredPasswordChange Container
 *
 * @author Gazi Rahman
 */
interface IState {
  oldPassword: string;
  password: string;
  verifyPassword: string;
  passwordsDoNotMatch: boolean;
}

/**
 * Path Parameters for the ExpiredPasswordChange Container Page.
 *
 * @author Gazi Rahman
 */
interface IRouteMaparms {
  apiKey: string;
  passwordResetToken: string;
}

/**
 * Location History State for the ExpiredPasswordChange Container
 *
 * @author Gazi Rahman
 */
interface IHistoryState {
  uid?: string;
  regToken?: string;
  message?: string;
}

/**
 * Properties for the ExpiredPasswordChange Container
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps<IRouteMaparms, any, IHistoryState>, WithTranslation, IPasswordChangeState {
  resetPasswordChange: typeof resetPasswordChange;
  changeExpiredPassword: typeof changeExpiredPassword;
}

/**
 * Container for the ExpiredPasswordChange Page.
 *
 * @author Gazi Rahman
 */
class ExpiredPasswordChange extends React.Component<IProps, IState> {
  state = {
    oldPassword: '',
    password: '',
    verifyPassword: '',
    passwordsDoNotMatch: false,
  };

  componentDidMount() {
    this.props.resetPasswordChange();
    this.checkErrorPage();
  }

  componentDidUpdate() {
    this.checkErrorPage();
  }

  checkErrorPage = () => {
    if (this.props.passwordChangeFailed && !this.props.errorMessage) {
      this.props.history.push(Links.PASSWORD_CHANGE_FAILED);
    }
  };

  oldPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedOldPassword = event.currentTarget.value;
    this.setState({
      oldPassword: updatedOldPassword,
    });
  };

  passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPassword = event.target.value;
    const currentVerifyPassword = this.state.verifyPassword;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== currentPassword;
    this.setState({
      password: currentPassword,
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  verifyPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const verifyPasswordInput = event.currentTarget;
    const currentVerifyPassword = verifyPasswordInput.value;
    const passwordsDoNotMatch: boolean = currentVerifyPassword !== this.state.password;
    const { t } = this.props;
    if (passwordsDoNotMatch) {
      verifyPasswordInput.setCustomValidity(t('passwordChangeRequest.error.confirmPassword.noMatch'));
    } else {
      verifyPasswordInput.setCustomValidity('');
    }
    this.setState({
      verifyPassword: currentVerifyPassword,
      passwordsDoNotMatch: passwordsDoNotMatch,
    });
  };

  registerLinkClickHandler = (event: React.MouseEvent) => {
    log('Register Link is clicked from Expired Password Change Page');
  };

  isLoading = () => {
    return this.props.passwordChangeStarted && !(this.props.passwordChangeFinished || this.props.passwordChangeFailed);
  };

  formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!this.state.passwordsDoNotMatch) {
      if (form.checkValidity() !== false) {
        log('Form is validated');
        const uid = this.props.history.location.state.uid;
        const regToken = this.props.history.location.state.regToken;
        this.props.changeExpiredPassword(
          this.state.oldPassword,
          this.state.password,
          this.state.verifyPassword,
          uid,
          regToken,
        );
      } else {
        log('Form is not valid');
      }
    }
    form.classList.add(CssClasses.WAS_VALIDATED);
  };

  render() {
    log('Properties: ', this.props);
    const { t } = this.props;
    const title = this.props.passwordChangeFinished ? t('pageTitle.passwordChanged') : t('pageTitle.resetPassword');
    const descriptiveHeader = this.props.location.state.message;
    return (
      <Layout title={title} isErrorPage={this.props.passwordChangeFinished}>
        {!this.props.passwordChangeFinished ? (
          <React.Fragment>
            {descriptiveHeader && <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>{descriptiveHeader}</h3>}
            <PasswordChangeRequest
              requireOldPassword={true}
              oldPassword={this.state.oldPassword}
              onOldPasswordChange={this.oldPasswordChangeHandler}
              password={this.state.password}
              verifyPassword={this.state.verifyPassword}
              passwordChangeHandler={this.passwordChangeHandler}
              verifyPasswordChangeHandler={this.verifyPasswordChangeHandler}
              onRegisterLinkClick={this.registerLinkClickHandler}
              isLoading={this.isLoading}
              formSubmitHandler={this.formSubmitHandler}
              serverError={this.props.passwordChangeFailed}
              serverErrorCodes={this.props.errorCodes}
              serverErrorMessage={this.props.errorMessage}
              passwordsDoNotMatch={this.state.passwordsDoNotMatch}
            />
          </React.Fragment>
        ) : (
          <PasswordChangeSubmitted />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.passwordChangeState,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPasswordChange: () => dispatch(resetPasswordChange()),
  changeExpiredPassword: (
    oldPassword: string,
    password: string,
    verifyPassword: string,
    uid?: string,
    regToken?: string,
  ) => dispatch(changeExpiredPassword(oldPassword, password, verifyPassword, uid, regToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('passwordChange')(ExpiredPasswordChange)));
