import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import log from '../../util/Log';
import axios from '../../axiosFactory';

import { EndPoint } from '../../constants/EndPoints';
import {
  AuthActionType,
  IAllAuthActions,
  ISendPasswordNotSet,
  ISendPendingAccountVerification,
  ISendPendingPasswordChange,
  IResentAccountVerificationEmail,
  IAuthenticationFailed,
  IResetVerificationEmailSent,
} from './AuthActionType';
import { getGigyaApiKey } from '../../util/GigyaUtil';
import { GigyaSite } from './gigya/GigyaSite';
import IAuthState from './IAuthState';

import { startAuthentication, loadAuthResponse, loadAuthError } from './AuthActions';
import IAuthenticationResponse from '../../api/response/IAuthenticationResponse';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import { userSuccessfullyLoggedIn } from '../../services/LogonHistoryService';

const customerAuthURL = process.env.REACT_APP_API_EXTERNAL_USER_LOGIN || EndPoint.CUSTOMER_AUTH;
const verificationEmailServiceURL =
  process.env.REACT_APP_VERIFICATION_EMAIL_SERVICE_URL || EndPoint.VERIFICATION_EMAIL_SERVICE_URL;

/**
 * Redux Action to Perform Customer Authentication
 *
 * @param gigyaSite
 * @param emailId
 * @param password
 * @returns
 *
 * @author Gazi Rahman
 */
export const authenticateCustomer: ActionCreator<ThunkAction<Promise<any>, IAuthState, null, IAllAuthActions>> = (
  gigyaSite: GigyaSite,
  emailId: string,
  password: string,
) => {
  return async (dispatch: ThunkDispatch<IAuthState, null, IAllAuthActions>) => {
    dispatch(startAuthentication(false));
    const siteApiKey = getGigyaApiKey(gigyaSite);

    try {
      const response = await axios.post<IAuthenticationResponse>(customerAuthURL, {
        siteApiKey: siteApiKey,
        customerEmail: emailId,
        password,
      });
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response for Customer Authentication!', resData);
        if (resData.errorCodes?.includes('pending_password_change')) {
          return dispatch(
            sendPendingPasswordChange(resData.errorCodes, resData.message, resData.uid, resData.regToken),
          );
        } else if (resData.errorCodes?.includes('error.authentication.passwordnotset')) {
          return dispatch(sendPasswordNotSet(resData.errorCodes, resData.message));
        } else if (resData.errorCodes?.includes('error.gigya.account_pending_verification')) {
          return dispatch(sendPendingAccountVerification(resData.errorCodes, resData.message));
        } else if (resData.errorCodes?.includes('invalid.methodArgument') && resData.fieldErrors) {
          const errorCodes = resData.fieldErrors[0].codes;
          const errorMsg = resData.fieldErrors[0].defaultMessage;
          return dispatch(loadAuthError(errorCodes, errorMsg));
        } else {
          return dispatch(loadAuthError(resData.errorCodes, resData.message));
        }
      } else {
        log('Server returned Success Response for Authentication', resData);
        userSuccessfullyLoggedIn(emailId, false);
        return dispatch(loadAuthResponse(resData.authenticationInfo, false));
      }
    } catch (err) {
      log('Failed to Send Auth Request to Server: ', err);
      return dispatch(loadAuthError(['error.serverError'], 'Authentication Failed due to Server Error'));
    }
  };
};

/**
 * Redux Action to send PasswordNotSet State
 *
 * @param errorCodes
 * @param errorMessage
 * @returns
 *
 * @author Gazi Rahman
 */
const sendPasswordNotSet: ActionCreator<ISendPasswordNotSet> = (errorCodes: Array<string>, errorMessage: string) => ({
  type: AuthActionType.SEND_PASSWORD_NOT_SET,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});

/**
 * Redux Action to send PendingPasswordChange state
 *
 * @param errorCodes
 * @param errorMessage
 * @param uid
 * @param regToken
 * @returns
 *
 * @author Gazi Rahman
 */
const sendPendingPasswordChange: ActionCreator<ISendPendingPasswordChange> = (
  errorCodes: Array<string>,
  errorMessage: string,
  uid?: string,
  regToken?: string,
) => ({
  type: AuthActionType.SEND_PENDING_PASSWORD_CHANGE,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
  uid: uid,
  regToken: regToken,
});

/**
 * Redux Action to send authentication error that Account is Pending Verification
 *
 * @param errorCodes
 * @param errorMessage
 * @returns
 *
 * @author Gazi Rahman
 */
const sendPendingAccountVerification: ActionCreator<ISendPendingAccountVerification> = (
  errorCodes: Array<string>,
  errorMessage: string,
) => ({
  type: AuthActionType.SEND_PENDING_ACCOUNT_VERIFICATION,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});

/**
 * Redux Action to send Account Verification Email to a customer.
 *
 * @param emailId
 * @returns
 *
 * @author Gazi Rahman
 */
export const sendAccountVerificationEmail: ActionCreator<ThunkAction<
  Promise<any>,
  IAuthState,
  null,
  IResentAccountVerificationEmail | IAuthenticationFailed
>> = (emailId: string) => {
  return async (dispatch: ThunkDispatch<IAuthState, null, IResentAccountVerificationEmail | IAuthenticationFailed>) => {
    try {
      const response = await axios.post<IAuthenticationResponse>(verificationEmailServiceURL, {
        customerEmail: emailId,
      });
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response for Sending Verification Email', resData);
        return dispatch(loadAuthError(resData.errorCodes, resData.message));
      } else {
        log('Server returned Success Response for Sending Verification Email', resData);
        return dispatch({
          type: AuthActionType.VERIRIFICATION_EMAIL_SENT,
        });
      }
    } catch (err) {
      log('Failed to Send Verification Email Request to Server: ', err);
      return dispatch(loadAuthError(['error.serverError'], 'Failed to send Verification Email due to Server Error'));
    }
  };
};

export const resetVerificationEmailSent: ActionCreator<IResetVerificationEmailSent> = () => ({
  type: AuthActionType.RESET_VERIFICATION_EMAIL_SENT,
});
