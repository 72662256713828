import IGigyaState from './IGigyaState';
import { Reducer } from 'redux';
import { IAllGigyaActions, GigyaActionType } from './GigyaActionType';

/**
 * Initial State for GigyaReducer
 *
 * @author Gazi Rahman
 */
const intialState: IGigyaState = {
  gigyaLoaded: false,
  gigyaLoadStarted: false,
  gigyaLoadFailed: false,
};

/**
 * Redux Reducer for Gigya Script Loading
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const gigyaReducer: Reducer<IGigyaState, IAllGigyaActions> = (state = intialState, action) => {
  switch (action.type) {
    case GigyaActionType.GIGYA_LOAD_STARTED:
      return {
        ...state,
        gigyaSite: action.gigyaSite,
        gigyaLoadStarted: true,
        gigyaLoadFailed: false,
        gigyaLoaded: false,
      };
    case GigyaActionType.GIGYA_LOAD_FAILED:
      return {
        ...state,
        gigyaLoadFailed: true,
        gigyaLoadFailedErrorCodes: action.errorCodes,
        gigyaLoadFailedErrorMessage: action.errorMessage,
      };
    case GigyaActionType.GIGYA_LOAD_FINISHED:
      return {
        ...state,
        gigyaLoadFailed: false,
        gigyaLoaded: true,
        gigyaLoadFailedErrorCode: undefined,
        gigyaLoadFailedErrorMessage: undefined,
      };
    default:
      return state;
  }
};

export default gigyaReducer;
