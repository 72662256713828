import { ActionCreator, Dispatch } from 'redux';
import {
  IGigyaLogoutStart,
  GigyaLogoutActionTypes,
  IGigyaLogoutError,
  IGigyaLogoutFinish,
  IAllGigyaLogoutActions,
} from './GigyaLogoutActionTypes';
import { ThunkAction } from 'redux-thunk';
import IGigyaLogoutState from './IGigyaLogoutState';

/**
 * Redux Actions for Logout functionality from Gigya
 *
 * @author Gazi Rahman
 */

/**
 * Global Window variable
 */
declare const window: any;

/**
 * Redux Thunk Action to perform Logout functionality from Gigya
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const gigyaLogout: ActionCreator<ThunkAction<
  Promise<any>,
  IGigyaLogoutState,
  null,
  IAllGigyaLogoutActions
>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startGigyaLogout());

    await new Promise(function (resolve, reject) {
      window.gigya.accounts.logout({
        callback: function () {
          resolve(true);
        },
      });
    });

    dispatch(finishGigyaLogout());
  };
};

const startGigyaLogout: ActionCreator<IGigyaLogoutStart> = () => ({
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_START,
});

const finishGigyaLogout: ActionCreator<IGigyaLogoutFinish> = () => ({
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_FINISH,
});

const errorGigyaLogout: ActionCreator<IGigyaLogoutError> = (errorCode: string, errorMessage: string) => ({
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_ERROR,
  errorCode: errorCode,
  errorMessage: errorMessage,
});
