import * as React from 'react';

import log from '../../util/Log';

import phoneCountriesData from '../../data/PhoneCountries.json';

/**
 * Properties for PhoneCountrySelector.
 *
 * @author Gazi Rahman
 */
interface IProps {
  countryValue?: string;
  onCountryValueChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * Component to select a Country (and Code) used for the PhoneNumber.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const phoneCountrySelector: React.SFC<IProps> = (props) => {
  log('Phone Countries: ', phoneCountriesData);
  const optionObjects = phoneCountriesData.map((it) => (
    <option
      key={it.country_code + '-' + it.calling_code}
      data-countrycode={it.country_code}
      data-callingcode={it.calling_code}
      value={it.country_code + '-' + it.calling_code}
    >
      {it.country_description} (+{it.calling_code})
    </option>
  ));
  return (
    <select name="" value={props.countryValue} onChange={props.onCountryValueChange}>
      {optionObjects}
    </select>
  );
};

export default phoneCountrySelector;
