import * as React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegisterLink from '../../../components/registerLink/RegisterLink';
import { Links } from '../../../constants/Links';

/**
 * Properties for VerificationEmailSent Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  onRegisterLinkClick: (event: React.MouseEvent) => void;
  onBackToLoginClick: (event: React.MouseEvent) => void;
}

/**
 * Fragment page for VerificationEmailSent
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const verificationEmailSent: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      <div className="error-section">
        <div className="error-title">
          <Trans ns="login" i18nKey="form.message.verificationEmailSent">
            <p className="mt-5">An email with the account verification link has been sent to your email address. </p>
            <p className="mt-1">Click on the button in the email to reset your password.</p>
            <p className="mt-5">
              <b>Didn’t get the email?</b>
            </p>
            <p className="mt-2">Check your spam/junk folder.</p>
          </Trans>
        </div>
        <Link className="login-btn" to={Links.LOGIN} onClick={props.onBackToLoginClick}>
          {t('form.link.loginLink')}
        </Link>
        <RegisterLink onRegisterLinkClick={props.onRegisterLinkClick} />
      </div>
    </React.Fragment>
  );
};

export default withTranslation('login')(verificationEmailSent);
