import * as React from 'react';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Links } from '../../../constants/Links';

/**
 * Component/Container for Password Page Submitted Page
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordChangeSubmitted: React.SFC<WithTranslation> = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      <div className="error-section">
        <div className="error-title">
          <Trans ns="passwordChange" i18nKey="passwordChangeSubmitted.successMessage">
            <h2>Congratulations!</h2>
            <p className="mt-5">You&apos;ve successfully changed your password. </p>
            <p className="mt-2">Your new password is ready to use immediately.</p>
          </Trans>
        </div>
        <Link to={Links.LOGIN} className="login-btn">
          {t('passwordChangeSubmitted.loginLink')}
        </Link>
      </div>
    </React.Fragment>
  );
};

export default withTranslation('passwordChange')(passwordChangeSubmitted);
