import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  GigyaSessionActionType,
  IAllGiygaSessionActions,
  IGigyaSessionChecked,
  IGigyaSessionChecking,
} from './GigyaSessionActionTypes';
import IGigyaSessionState from './IGigyaSessionState';

import log from '../../../../util/Log';

/**
 * Redux Actions for Gigya Session Management
 *
 * @author Gazi Rahman
 */

/**
 * Global 'window' variable
 *
 * @author Gazi Rahman
 */
declare const window: any;

const gigyaSessionChecking: ActionCreator<IGigyaSessionChecking> = () => ({
  type: GigyaSessionActionType.GIGYA_SESSION_CHECKING,
});

const gigyaSessionChecked: ActionCreator<IGigyaSessionChecked> = (gigyaSessionExists: boolean) => ({
  type: GigyaSessionActionType.GIGYA_SESSION_CHECKED,
  gigyaSessionExixts: gigyaSessionExists,
});

/**
 * Redux Thunk Action for Checking Gigya Session
 *
 * @author Gazi Rahman
 */
export const checkGigyaSession: ActionCreator<ThunkAction<
  Promise<any>,
  IGigyaSessionState,
  null,
  IAllGiygaSessionActions
>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(gigyaSessionChecking());

    window.gigya.accounts.getAccountInfo({
      callback: function (response: any) {
        if (response.errorCode === 0) {
          log('Checked and found valid session: ', response.UID);
          dispatch(gigyaSessionChecked(true));
        } else {
          log('Failed to check if Gigya Session exists!: ', response.errorMessage);
          dispatch(gigyaSessionChecked(false));
        }
      },
    });
  };
};
