import { Reducer } from 'redux';

import IPasswordChangeState from './PasswordChangeState';
import PasswordChangeActionTypes, { IAllPaswordChangeActions } from './PasswordChangeActionTypes';

/**
 * Initial State for PasswordChange Reducer
 *
 * @author Gazi Rahman
 */
const initialState: IPasswordChangeState = {
  passwordChangeStarted: false,
  passwordChangeFinished: false,
  passwordChangeFailed: false,
};

/**
 * Redux Reducer for PasswordChange
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordChangeReducer: Reducer<IPasswordChangeState, IAllPaswordChangeActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PasswordChangeActionTypes.START_PASSWORD_CHANGE:
      return {
        ...state,
        passwordChangeStarted: true,
        passwordChangeFinished: false,
        passwordChangeFailed: false,
      };
    case PasswordChangeActionTypes.FINISH_PASSWORD_CHANGE:
      return {
        ...state,
        passwordChangeStarted: false,
        passwordChangeFinished: true,
        passwordChangeFailed: false,
      };
    case PasswordChangeActionTypes.FAIL_PASSWORD_CHANGE:
      return {
        ...state,
        passwordChangeStarted: false,
        passwordChangeFinished: false,
        passwordChangeFailed: true,
        errorCodes: action.errorCodes,
        errorMessage: action.errorMessage,
      };
    case PasswordChangeActionTypes.RESET_PASSWORD_CHANGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default passwordChangeReducer;
