/**
 * Status of the response returned from server for all the REST requests.
 *
 * @author Gazi Rahman
 */
export enum ResponseStatus {
  SUCCESS = 'SUCCESS',

  ERROR = 'ERROR',
}
