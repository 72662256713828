import * as React from 'react';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import permissionsImg from '../../assets/images/CDC/permissions.svg';

/**
 * Properties for the CustomerConsent Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  termsConditionsAgreed: boolean;
  onTermsConditionChange: (ev: React.ChangeEvent) => void;
}

/**
 * Component that displays Customer Consent and asks the customer to agree to it.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const customerConsent: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      <img src={permissionsImg} alt="" />
      <label>{t('permissionsForm.label.permissions')}</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="invalidCheck"
          required={true}
          value=""
          checked={props.termsConditionsAgreed}
          onChange={props.onTermsConditionChange}
          autoFocus={true}
        />
        <label className="form-check-label" htmlFor="invalidCheck">
          <p>
            <Trans ns="register" i18nKey="permissionsForm.message.privacyNotice">
              By clicking here and submitting, you acknowledge that you are registering for an OrthoPlus® account and
              that you have reviewed and agree that your information will be used in accordance with our&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.orthoclinicaldiagnostics.com/en-us/home/privacy-notice"
              >
                Privacy Notice
              </a>
              .
            </Trans>
          </p>
          <p>
            <Trans ns="register" i18nKey="permissionsForm.message.employeePrivaceNotice">
              If you are registering on behalf of any employer, you represent that you are fully authorized to register
              and use this Site on behalf of your employer and that your information will be used in accordance with
              our&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.orthoclinicaldiagnostics.com/en-us/home/privacy-notice"
              >
                Privacy Notice
              </a>
              .
            </Trans>
          </p>
        </label>
        <div className="invalid-feedback">{t('permissionsForm.error.premissions')}</div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation('register')(customerConsent);
