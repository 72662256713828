/**
 * Redux Action Types for AccountInfoRetrieve from Gigya
 *
 * @author Gazi Rahman
 */
export enum AccountInfoRetrieveActionType {
  ACCOUNT_INFO_RETRIEVE_STARTED = 'ACCOUNT_INFO_RETRIEVE_STARTED',
  ACCOUNT_INFO_RETRIEVE_FAILED = 'ACCOUNT_INFO_RETRIEVE_FAILED',
  ACCOUNT_INFO_RETRIEVE_FINISHED = 'ACCOUNT_INFO_RETRIEVE_FINISHED',
}

export interface IAccountInfoRetrieveStartAction {
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_STARTED;
}

export interface IAccountInfoRetrieveFailedAction {
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FAILED;
  errorCode: number;
  errorMessage: string;
}

export interface IAccountInfoRetrieveFinished {
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FINISHED;
  response: any;
}

export type IAllAccountInfoRetrieveActions =
  | IAccountInfoRetrieveStartAction
  | IAccountInfoRetrieveFinished
  | IAccountInfoRetrieveFailedAction;
