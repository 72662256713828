import axios from 'axios';
import i18n from './i18n';

/**
 * The base URL for the API calls made to the server.
 *
 * @author Gazi Rahman
 */
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

/**
 * Creates an instance of axios to Communicate with server to make all the REST
 * WebService Calls
 *
 * @author Gazi Rahman
 */
const instance = axios.create({
  baseURL: API_BASE_URL,
});

const URL_PARAM_SET_CULTURE = '?culture=';
const URL_PARAM_APPEND_CULTURE = '&culture=';

instance.interceptors.request.use((config) => {
  if (i18n.language) {
    config.headers['Accept-Language'] = i18n.language;
    if (config.params) {
      config.params.culture = i18n.language;
    } else {
      if (config.url?.indexOf('?') === -1) {
        config.url += URL_PARAM_SET_CULTURE + i18n.language;
      } else {
        config.url += URL_PARAM_APPEND_CULTURE + i18n.language;
      }
    }
  }
  return config;
});

export default instance;
