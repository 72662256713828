// @ts-ignore
import iswebview from 'is-webview';

/**
 * Utilities for Login Window; specially the SSO Window for Azure Authentication
 *
 * @author Gazi Rahman
 */

declare const window: any;

const NEW_WINDOW_NAME = '_blank';
const DEFAULT_SSO_WINDOW_CONFIG = 'fullscreen=no,location=no,menubar=no,toolbar=no,status=no,titlebar=no';
const WINDOW_CONFIG_WIDTH_PARAM = ',width=';
const WINDOW_CONFIG_HEIGHT_PARAM = ',height=';
const WINDOW_CONFIG_TOP_PARAM = ',top=';
const WINDOW_CONFIG_LEFT_PARAM = ',left=';
export const openCenterWindow = (url: string, width: number, height: number): any => {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 4;

  const ssoWindowConfig =
    DEFAULT_SSO_WINDOW_CONFIG +
    WINDOW_CONFIG_WIDTH_PARAM +
    width +
    WINDOW_CONFIG_HEIGHT_PARAM +
    height +
    WINDOW_CONFIG_TOP_PARAM +
    top +
    WINDOW_CONFIG_LEFT_PARAM +
    left;
  const ssoWindow = iswebview(navigator.userAgent)
    ? window.open(url)
    : window.open(url, NEW_WINDOW_NAME, ssoWindowConfig);

  return ssoWindow;
};
