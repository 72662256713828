/**
 * Creates an instance of 'Log' for Logging in the application.
 *
 * @param args
 *
 * @author Gazi Rahman
 */
const log = (...args: any[]) => {
  const isLoggingEnabled = process.env.REACT_APP_LOGGING_ENABLED === 'true';
  if (isLoggingEnabled) {
    console.log(...args);
  }
};

export default log;
