import * as React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import log from '../../util/Log';

import { IApplicationState } from '../../store/Store';
import Layout from '../../hoc/layout/Layout';
import HomeLink from '../../components/homeLink/HomeLink';

interface IProps extends WithTranslation {
  errorCodes: Array<string>;
  errorMessage: string;
}

/**
 * Registration Error Page
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const errorRegister: React.SFC<IProps> = (props) => {
  log(`Registration Failed! Error Codes: ${props.errorCodes}; Error Message: ${props.errorMessage}`);
  const { t } = props;
  return (
    <Layout title={t('errorPage.title')} isErrorPage={true}>
      <div className="error-section">
        <div className="error-title">
          <Trans ns="register" i18nKey="errorPage.message">
            <h2>The server encountered a temporary error and could not complete your request.</h2>
            <br />
            <h2>Please try again in a few minutes.</h2>
          </Trans>
        </div>
        <HomeLink />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  errorCodes: state.registrationState.errorCodes || [],
  errorMessage: state.registrationState.errorMessage || '',
});
const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('register')(errorRegister));
