/**
 * Redux Action Types for Password Change
 *
 * @author Gazi Rahman
 */
enum PasswordChangeActionTypes {
  START_PASSWORD_CHANGE = 'START_PASSWORD_CHANGE',

  FINISH_PASSWORD_CHANGE = 'FINISH_PASSWORD_CHANGE',

  FAIL_PASSWORD_CHANGE = 'FAIL_PASSWORD_CHANGE',

  RESET_PASSWORD_CHANGE = 'RESET_PASSWORD_CHANGE',
}

export interface IStartPasswordChange {
  type: PasswordChangeActionTypes.START_PASSWORD_CHANGE;
}

export interface IFinishPasswordChange {
  type: PasswordChangeActionTypes.FINISH_PASSWORD_CHANGE;
}

export interface IFailPasswordChange {
  type: PasswordChangeActionTypes.FAIL_PASSWORD_CHANGE;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IResetPasswordChange {
  type: PasswordChangeActionTypes.RESET_PASSWORD_CHANGE;
}

export type IAllPaswordChangeActions =
  | IStartPasswordChange
  | IFinishPasswordChange
  | IFailPasswordChange
  | IResetPasswordChange;

export default PasswordChangeActionTypes;
