import IRegistrationState from './IRegistrationState';
import { Reducer } from 'redux';
import { IAllRegistrationActions, RegistrationActionType } from './RegistrationActionTypes';
import RegistrationStep from './RegistrationStep';
import { RegistrationType } from './RegistrationType';

/**
 * Initial State for RegistrationReducer
 *
 * @author Gazi Rahman
 */
const initialState: IRegistrationState = {
  activeStep: RegistrationStep.PROFILE_INFO,

  registrationForm: {
    registrationType: RegistrationType.REGULAR,
    profileForm: {
      firstName: '',
      lastName: '',
      role: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      telephone: {
        countryCode: 'US',
        countryValue: 1,
        number: '',
        extension: '',
      },
      fax: {
        countryCode: 'US',
        countryValue: 1,
        number: '',
        extension: '',
      },
      mobile: {
        countryCode: 'US',
        countryValue: 1,
        number: '',
      },
      country: '',
    },

    securityQuestionsForm: {
      securityQuestionAnswers: [
        {
          question: '',
          answer: '',
        },
        {
          question: '',
          answer: '',
        },
        {
          question: '',
          answer: '',
        },
      ],
    },

    customerInfoForm: {
      existingCustomer: true,
      customerInfo: {
        customerNumber: '',
        organization: '',
        city: '',
        zip: '',
        comment: '',
      },
    },

    consentForm: {
      termsConditionsAgreed: false,
    },
  },

  registrationSubmitStarted: false,
  registrationSubmitFinished: false,
  registrationSubmitFailed: false,

  errorCodes: undefined,
  errorMessage: undefined,
};

/**
 * Redux Reducer for Registration
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const registrationReducer: Reducer<IRegistrationState, IAllRegistrationActions> = (state = initialState, action) => {
  switch (action.type) {
    case RegistrationActionType.RESET_REGISTRATION:
      return {
        ...initialState,
      };
    case RegistrationActionType.SUBMIT_PROFILE_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.SECURITY_QUESTIONS,
        registrationForm: {
          ...state.registrationForm,
          profileForm: { ...action.profileForm },
        },
      };
    case RegistrationActionType.SUBMIT_SECURITY_QUESTIONS_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.CUSTOMER_FEATURES,
        registrationForm: {
          ...state.registrationForm,
          securityQuestionsForm: { ...action.securityQuestionsForm },
        },
      };
    case RegistrationActionType.GO_PREV_FROM_SECURITY_QUESTIONS_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.PROFILE_INFO,
        registrationForm: {
          ...state.registrationForm,
          securityQuestionsForm: { ...action.securityQuestionsForm },
        },
      };
    case RegistrationActionType.SUBMIT_CUSTOMER_INFO_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.CONSENT_AGREEMENT,
        registrationForm: {
          ...state.registrationForm,
          customerInfoForm: { ...action.customerInfoForm },
        },
      };
    case RegistrationActionType.GO_PREV_FROM_CUSTOMER_INFO_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.SECURITY_QUESTIONS,
        registrationForm: {
          ...state.registrationForm,
          customerInfoForm: { ...action.customerInfoForm },
        },
      };
    case RegistrationActionType.SUBMIT_CONSENT_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.SUBMIT_REGISTRATION,
        registrationForm: {
          ...state.registrationForm,
          consentForm: { ...action.consentForm },
        },
      };
    case RegistrationActionType.GO_PREV_FROM_CONSENT_FORM:
      return {
        ...state,
        activeStep: RegistrationStep.CUSTOMER_FEATURES,
      };
    case RegistrationActionType.START_REGISTRATION_SUBMIT:
      return {
        ...state,
        registrationSubmitStarted: true,
        registrationSubmitFinished: false,
        registrationSubmitFailed: false,
      };
    case RegistrationActionType.FINISH_REGISTRATION_SUBMIT:
      return {
        ...initialState,
        registrationSubmitFinished: true,
        registrationSubmitFailed: false,
        registrationSubmitStarted: false,
      };
    case RegistrationActionType.FAIL_REGISTRATION_SUBMIT:
      return {
        ...initialState,
        registrationSubmitStarted: false,
        registrationSubmitFinished: false,
        registrationSubmitFailed: true,
        errorCodes: action.errorCodes,
        errorMessage: action.errorMessage,
      };
    case RegistrationActionType.CHECK_EMAIL_FOR_REGISTER:
      return {
        ...state,
        isEmailAvailable: undefined,
      };
    case RegistrationActionType.SET_EMAIL_AVAILABLE_FOR_REGISTER:
      return {
        ...state,
        isEmailAvailable: true,
      };
    case RegistrationActionType.SET_EMAIL_NOT_AVAILBLE_FOR_REGISTER:
      return {
        ...state,
        isEmailAvailable: false,
      };
    case RegistrationActionType.SET_REGISTRATION_TYPE:
      return {
        ...state,
        registrationForm: {
          ...state.registrationForm,
          registrationType: action.registrationType,
        },
      };
    case RegistrationActionType.SET_REGISTRATION_KEY:
      return {
        ...state,
        registrationForm: {
          ...state.registrationForm,
          registrationKey: action.registrationKey,
        },
      };
    case RegistrationActionType.SET_REQUESTED_FEATURES:
      return {
        ...state,
        registrationForm: {
          ...state.registrationForm,
          requestedFeatures: action.requestedFeatures,
        },
      };
    default:
      return state;
  }
};

export default registrationReducer;
