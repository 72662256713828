import { GigyaSite } from '../store/auth/gigya/GigyaSite';

/**
 * Different Utilities for Gigya APIs used in the Application
 *
 * @author Gazi Rahman
 */

export const DEFAULT_GIGYA_SITE = GigyaSite.INTERNAL;

export const getGigyaSite = (siteIdStr: string) => {
  const gigyaSite: GigyaSite = siteIdStr
    ? GigyaSite[siteIdStr.toUpperCase() as keyof typeof GigyaSite]
    : DEFAULT_GIGYA_SITE;
  return gigyaSite;
};

export const getGigyaApiKey = (gigyaSite: GigyaSite): string | undefined => {
  const gigyaApiKey =
    gigyaSite === GigyaSite.EXTERNAL
      ? process.env.REACT_APP_GIGYA_API_KEY_EXTERNAL
      : process.env.REACT_APP_GIGYA_API_KEY_INTERNAL;
  return gigyaApiKey;
};
