import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import CustomerConsent from '../../../components/customerconsent/CustomerConsent';

import LoginLink from '../../../components/loginLink/LoginLink';

import { goPrevFromConsentForm, submitConsentForm } from '../../../store/register/RegistrationNavigationActions';
import { IApplicationState } from '../../../store/Store';
import IConsentForm from '../../../store/register/IConsentForm';
import { Dispatch } from 'redux';
import { CssClasses } from '../../../constants/CssClasses';

/**
 * Properties for ConsentForm
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  goPrevFromConsentForm: typeof goPrevFromConsentForm;
  submitConsentForm: typeof submitConsentForm;
}

/**
 * State for ConsentForm Component
 *
 * @author Gazi Rahman
 */
type IState = IConsentForm;

/**
 * Registration form for User Consent
 *
 * @author Gazi Rahman
 */
class ConsentForm extends React.Component<IProps, IState> {
  state = {
    termsConditionsAgreed: false,
  };

  handleTermsConditionChange = (ev: React.ChangeEvent) => {
    const termsConditionsAgreed = !this.state.termsConditionsAgreed;
    this.setState({
      termsConditionsAgreed: termsConditionsAgreed,
    });
  };

  handleFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      form.classList.add(CssClasses.WAS_VALIDATED);
    } else {
      this.props.submitConsentForm(this.state);
    }
  };

  handlePrevButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    this.props.goPrevFromConsentForm();
  };

  render() {
    const { t } = this.props;
    return (
      <section className="third-form customer-invalid">
        <form className="needs-validation" noValidate={true} onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <CustomerConsent
              termsConditionsAgreed={this.state.termsConditionsAgreed}
              onTermsConditionChange={this.handleTermsConditionChange}
            />
            <div className="form-btns">
              <button className="btn btn-primary previous-btn-2" type="button" onClick={this.handlePrevButtonClick}>
                {t('buttonLabel.previous')}
              </button>
              <button className="btn btn-primary" type="submit">
                {t('buttonLabel.submit')}
              </button>
            </div>
          </div>
        </form>
        <LoginLink />
      </section>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goPrevFromConsentForm: () => dispatch(goPrevFromConsentForm()),
  submitConsentForm: (consentForm: IConsentForm) => dispatch(submitConsentForm(consentForm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('register')(ConsentForm));
