import { Reducer } from 'redux';

import IPasswordResetState from './PasswordResetState';
import PasswordResetActionTypes, { IAllPaswordResetActions } from './PasswordResetActionTypes';

/**
 * Initial State for PasswordResetReducer
 *
 * @author Gazi Rahman
 */
const initialState: IPasswordResetState = {
  passwordResetStarted: false,
  passwordResetFinished: false,
  passwordResetFailed: false,
};

/**
 * Redux Reducer for Sending Password Reset Email to Customer
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const passwordResetReducer: Reducer<IPasswordResetState, IAllPaswordResetActions> = (state = initialState, action) => {
  switch (action.type) {
    case PasswordResetActionTypes.START_PASSWORD_RESET:
      return {
        ...state,
        passwordResetStarted: true,
        passwordResetFinished: false,
        passwordResetFailed: false,
      };
    case PasswordResetActionTypes.FINISH_PASSWORD_RESET:
      return {
        ...state,
        passwordResetStarted: false,
        passwordResetFinished: true,
        passwordResetFailed: false,
      };
    case PasswordResetActionTypes.FAIL_PASSWORD_RESET:
      return {
        ...state,
        passwordResetStarted: false,
        passwordResetFinished: false,
        passwordResetFailed: true,
        errorCodes: action.errorCodes,
        errorMessage: action.errorMessage,
      };
    case PasswordResetActionTypes.RESET_PASSWORD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default passwordResetReducer;
