import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

/**
 * import i18n (needs to be bundled ;))
 */
import './i18n';

import './assets/fonts/overpass.css';
import './assets/fonts/fontawesome.css';
import './assets/css/bootstrap.min.css';
import './assets/css/aos.min.css';
import './assets/css/style.css';

import configureStore from './store/Store';

import App from './App';

/**
 * Configuring Global Redux Store
 *
 * @author Gazi Rahman
 */
const store = configureStore();

/**
 * Rendering React DOM
 *
 * @author Gazi Rahman
 */
ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
