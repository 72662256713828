import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IAllAuthActions } from './AuthActionType';
import { GigyaSite } from './gigya/GigyaSite';
import { isWebView } from '../../util/UserAgentUtil';
import { openCenterWindow } from '../../util/windowUtil';
import { generateSSOWindowURL } from '../../util/URLUtil';
import IAuthState from './IAuthState';
import { getGigyaApiKey } from '../../util/GigyaUtil';
import { API_BASE_URL } from '../../axiosFactory';
import log from '../../util/Log';
import i18n from '../../i18n';
import IAuthenticationResponse from '../../api/response/IAuthenticationResponse';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import { startAuthentication, loadAuthError, loadAuthResponse } from './AuthActions';
import { userSuccessfullyLoggedIn } from '../../services/LogonHistoryService';

/**
 * KEY used for Storing OrthoSSOAuth
 *
 * @author Gazi Rahman
 */
const ORTHO_SSO_AUTH_STORAGE_KEY = 'ortho-sso-auth';

/**
 * Redux Action to perform Ortho User Authentication
 *
 * @param gigyaSite
 * @param emailId
 * @param isRedirectedFromWebView
 * @param locationPath
 * @returns
 *
 * @author Gazi Rahman
 */
export const authenticateOrthoUser: ActionCreator<ThunkAction<Promise<any>, IAuthState, null, IAllAuthActions>> = (
  gigyaSite: GigyaSite,
  emailId: string,
  isRedirectedFromWebView: boolean,
  locationPath: string,
) => {
  const CULTURE_PARAM_STR = '&culture=';
  return async (dispatch: ThunkDispatch<IAuthState, null, IAllAuthActions>) => {
    dispatch(startAuthentication(true));

    if (isRedirectedFromWebView) {
      log('WebView Redirected -> Handling Response from Local Storage');
      const resData = JSON.parse('' + localStorage.getItem(ORTHO_SSO_AUTH_STORAGE_KEY));
      return handleAuthenticateOrthoUserResponseData(dispatch, resData);
    } else {
      const siteApiKey = getGigyaApiKey(gigyaSite) || '';

      const langUrlComponent = i18n.language ? CULTURE_PARAM_STR + i18n.language : '';

      const testingWebView = process.env.REACT_APP_TESTING_WEBVIEW === 'true';

      const isRequestedFromWebView = isWebView() || testingWebView;

      const ssoWindowUrl = generateSSOWindowURL(
        emailId,
        siteApiKey,
        langUrlComponent,
        isRequestedFromWebView,
        locationPath,
      );

      if (isRequestedFromWebView) {
        window.location.href = ssoWindowUrl;
      } else {
        const windowWidth = window.screen.width > 1024 ? window.screen.width * 0.8 : 800;
        const ssoWindow = openCenterWindow(ssoWindowUrl, windowWidth, 600);
        const resData: IAuthenticationResponse = await new Promise(function (resolve, reject) {
          /**
           * All the browsers, except IE & Edge sends the authentication data using
           * message passing
           */
          window.onmessage = (event: MessageEvent) => {
            if (event.origin === API_BASE_URL && event.source === ssoWindow) {
              log('Ortho User Login Process finished');
              resolve(event.data);
            }
          };

          /**
           * IE & Edge sends data using localStorage as they can't send message to
           * parent window (window.opener).
           */
          window.addEventListener('storage', function (ev: StorageEvent) {
            if (ev.key === ORTHO_SSO_AUTH_STORAGE_KEY) {
              log('Ortho User Login Process finished');
              const data = JSON.parse(ev.newValue || '');
              localStorage.removeItem(ORTHO_SSO_AUTH_STORAGE_KEY);
              resolve(data);
            }
          });
        });

        if (ssoWindow) {
          ssoWindow.close();
        }

        return handleAuthenticateOrthoUserResponseData(dispatch, resData);
      }
    }
  };
};

/**
 * Handles response data for Authentication Request
 *
 * @param dispatch
 * @param resData
 * @returns
 *
 * @author Gazi Rahman
 */
const handleAuthenticateOrthoUserResponseData = (
  dispatch: ThunkDispatch<IAuthState, null, IAllAuthActions>,
  resData: any,
) => {
  if (resData.responseStatus !== ResponseStatus.SUCCESS) {
    log('Server Returned Error Response for Employee Authentication!', resData);
    return dispatch(loadAuthError(resData.errorCodes, resData.message));
  } else {
    const authenticationInfo = resData.authenticationInfo;
    log('Successfully retrieved Ortho User Authentication: ', authenticationInfo);
    userSuccessfullyLoggedIn(resData.loginEmailId, true);
    return dispatch(loadAuthResponse(authenticationInfo, true));
  }
};
