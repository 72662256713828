/**
 * This service is to access User Logon Information
 *
 * @author Gazi Rahman
 */

const LOGON_HISTORY_KEY = '_ortho_login_history';

interface UserLoginHistory {
  emailId: string;
  isOrthoUser: boolean;
  lastLogonTime: number;
}

export const userSuccessfullyLoggedIn = (emailId: string, isOrthoUser: boolean) => {
  const loginHistory: Array<UserLoginHistory> = getLoginHistory();
  const userEmail = emailId.trim();
  const userLoginHistories = loginHistory.filter(
    (history) => history.emailId.toLowerCase() === userEmail.toLowerCase(),
  );
  if (userLoginHistories.length > 0) {
    const userLoginHistory = userLoginHistories[0];
    userLoginHistory.lastLogonTime = Date.now();
  } else {
    const userLoginHistory: UserLoginHistory = {
      emailId: emailId,
      isOrthoUser: isOrthoUser,
      lastLogonTime: Date.now(),
    };
    loginHistory.push(userLoginHistory);
  }
  localStorage.setItem(LOGON_HISTORY_KEY, JSON.stringify(loginHistory));
};

export const getSuccessullyLoggedInUsers: () => Array<string> = () => {
  const loginHistory: Array<UserLoginHistory> = getLoginHistory();
  return loginHistory.map((it) => it.emailId);
};

function getLoginHistory() {
  const loginHistoryStr = localStorage.getItem(LOGON_HISTORY_KEY) || '[]';
  const loginHistory: Array<UserLoginHistory> = JSON.parse(loginHistoryStr);
  return loginHistory;
}
