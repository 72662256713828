import { Reducer } from 'redux';
import IGigyaSessionState from './IGigyaSessionState';
import { GigyaSessionActionType, IAllGiygaSessionActions } from './GigyaSessionActionTypes';

/**
 * Initial State for GigyaSessionReducer
 *
 * @author Gazi Rahman
 */
const initialState: IGigyaSessionState = {
  sessionChecking: false,
  sessionCheckFinished: false,
};

/**
 * Redux Reducer for Gigya Session Management
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const gigyaSessionReducer: Reducer<IGigyaSessionState, IAllGiygaSessionActions> = (state = initialState, action) => {
  switch (action.type) {
    case GigyaSessionActionType.GIGYA_SESSION_CHECKING:
      return {
        ...state,
        sessionChecking: true,
      };
    case GigyaSessionActionType.GIGYA_SESSION_CHECKED:
      return {
        ...state,
        sessionChecking: false,
        sessionCheckFinished: true,
        sessionExists: action.gigyaSessionExixts,
      };
    default:
      return state;
  }
};

export default gigyaSessionReducer;
