import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { withTranslation, WithTranslation } from 'react-i18next';

import log from '../../util/Log';

import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';

import { IApplicationState } from '../../store/Store';
import { isValidEmail, isValidOrthoEmployeeEmail } from '../../util/EmailUtil';

import IPasswordResetState from '../../store/passwordreset/PasswordResetState';
import { sendPasswordReset, resetPasswordReset } from '../../store/passwordreset/PasswordResetActions';
import PasswordResetRequest from '../../forms/passwordresetrequest/PasswordResetRequest';
import PasswordResetSubmitted from './passwordresetsubmitted/PasswordResetSubmitted';

import Layout from '../../hoc/layout/Layout';
import { CssClasses } from '../../constants/CssClasses';
import { ErrorCodes } from '../../constants/ErrorCodes';

/**
 * Location History for PasswordReset Component
 *
 * @author Gazi Rahman
 */
interface IHistoryState {
  initialPasswordSet?: boolean;
}

/**
 * State for PasswordReset Component
 *
 * @author Gazi Rahman
 */
interface IState {
  termsAgreementNeeded: boolean;
  email: string;
  isOrthoEmployeeEmail: boolean;
  termsConditionsAgreed: boolean;
}

/**
 * Properties for PasswordReset Component
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps<null, any, IHistoryState>, WithTranslation, IPasswordResetState {
  sendPasswordReset: typeof sendPasswordReset;
  resetPasswordReset: typeof resetPasswordReset;
}

/**
 * Container Compoment for Password Reset Page.
 *
 * @author Gazi Rahman
 */
class PasswordReset extends React.Component<IProps, IState> {
  state = {
    email: '',
    isOrthoEmployeeEmail: false,
    termsConditionsAgreed: false,
    termsAgreementNeeded: this.props.history.location.state?.initialPasswordSet || false,
  };

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const emailParam = queryParams.email;
    if (emailParam) {
      const emailFromParam = Array.isArray(emailParam) ? emailParam[0] : emailParam;
      const validEmail = isValidEmail(emailFromParam);
      if (validEmail) {
        log('Setting Email to: ', emailParam);
        const isOrthoEmployeeEmail = isValidOrthoEmployeeEmail(emailFromParam);
        this.setState({
          email: emailFromParam,
          isOrthoEmployeeEmail: isOrthoEmployeeEmail,
        });
      }
    }

    this.props.resetPasswordReset();
  }

  componentDidUpdate = () => {
    if (this.props.passwordResetFailed) {
      /**
       * Set 'termsAgreementNeeded' if the error code is: TERMS_NOT_AGREED_ERROR_CODE
       */
      if (this.props.errorCodes?.includes(ErrorCodes.TERMS_NOT_AGREED_ERROR_CODE) && !this.state.termsAgreementNeeded) {
        this.setState({
          termsAgreementNeeded: true,
        });
      }
    }
  };

  emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.currentTarget;
    const currentVal = emailInput.value?.trim();
    const isEmailValid = isValidEmail(currentVal);
    const isOrthoEmail = isValidOrthoEmployeeEmail(currentVal);
    const { t } = this.props;
    if (!isEmailValid) {
      emailInput.setCustomValidity(t('passwordResetRequest.error.invalid'));
    } else if (isOrthoEmail) {
      emailInput.setCustomValidity(t('passwordResetRequest.error.orthoEmployee'));
    } else {
      emailInput.setCustomValidity('');
    }

    this.setState({
      email: currentVal,
      isOrthoEmployeeEmail: isOrthoEmail,
    });
  };

  handleTermsConditionChange = (ev: React.ChangeEvent) => {
    const termsConditionsAgreed = !this.state.termsConditionsAgreed;
    this.setState({
      termsConditionsAgreed: termsConditionsAgreed,
    });
  };

  formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      this.props.sendPasswordReset(this.state.email, this.state.termsConditionsAgreed, this.state.termsAgreementNeeded);
    }

    form.classList.add(CssClasses.WAS_VALIDATED);
  };

  rergisterLinkClickHandler = (event: React.MouseEvent) => {
    log('Register Link was clicked from Password Reset Page');
  };

  isLoading = () => {
    return this.props.passwordResetStarted && !(this.props.passwordResetFinished || this.props.passwordResetFailed);
  };

  render() {
    const { t } = this.props;
    const title = this.props.passwordResetFinished
      ? t('pageTitle.passwordResetSubmitted')
      : t('pageTitle.passwordResetRequest');
    return (
      <Layout title={title} isErrorPage={this.props.passwordResetFinished}>
        {!this.props.passwordResetFinished ? (
          <PasswordResetRequest
            email={this.state.email}
            isOrthoEmployeeEmail={this.state.isOrthoEmployeeEmail}
            termsAgreementNeeded={this.state.termsAgreementNeeded}
            termsConditionsAgreed={this.state.termsConditionsAgreed}
            onTermsConditionChange={this.handleTermsConditionChange}
            isLoading={this.isLoading}
            emailChangeHandler={this.emailChangeHandler}
            formSubmitHandler={this.formSubmitHandler}
            onRegisterLinkClick={this.rergisterLinkClickHandler}
            serverError={this.props.passwordResetFailed}
            serverErrorCodes={this.props.errorCodes}
            serverErrorMessage={this.props.errorMessage}
          />
        ) : (
          <PasswordResetSubmitted onRegisterLinkClick={this.rergisterLinkClickHandler} />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (store: IApplicationState) => ({
  ...store.passwordResetState,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendPasswordReset: (email: string, termsConditionsAgreed: boolean, isInitialPasswordReset: boolean) =>
    dispatch(sendPasswordReset(email, termsConditionsAgreed, isInitialPasswordReset)),
  resetPasswordReset: () => dispatch(resetPasswordReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('resetPassword')(PasswordReset)));
