import { ActionCreator } from 'redux';

import log from '../../../util/Log';
import {
  IStartAutoLogin,
  AutoLoginActionType,
  IFinishAutoLogin,
  IFailAutoLogin,
  IAllAutoLoginActions,
} from './AutoLoginActionType';
import axios from '../../../axiosFactory';
import { getGigyaApiKey } from '../../../util/GigyaUtil';
import IAutoLoginResponse from '../../../api/response/IAutoLoginResponse';
import IAccessTokenInfo from '../../../api/response/IAccessTokenInfo';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import IAutoLoginState from './IAutoLoginState';
import { ResponseStatus } from '../../../api/response/ResponseStatus';
import { GigyaSite } from '../gigya/GigyaSite';
import { storeAuthenticationToStorage } from '../../../util/StorageUtil';
import { EndPoint } from '../../../constants/EndPoints';

/**
 * Redux actions for AutoLogin
 *
 * @author Gazi Rahman
 *
 */

/**
 * Redux Action to Perform AutoLogin
 *
 * @param uid
 * @param uidSignature
 * @param timestampSignature
 * @param gigyaSite
 * @returns
 *
 * @author Gazi Rahman
 */
export const autoLogin: ActionCreator<ThunkAction<Promise<any>, IAutoLoginState, null, IAllAutoLoginActions>> = (
  uid: string,
  uidSignature: string,
  timestampSignature: string,
  gigyaSite: GigyaSite,
) => async (dispatch: ThunkDispatch<IAutoLoginState, null, IAllAutoLoginActions>) => {
  dispatch(startAutoLogin());
  const siteApiKey = getGigyaApiKey(gigyaSite);
  try {
    const response = await axios.post<IAutoLoginResponse>(EndPoint.AUTO_LOGIN_API, {
      uid,
      uidSignature,
      signatureTimestamp: timestampSignature,
      siteApiKey: siteApiKey,
    });
    const autologinResponse = response.data;
    if (autologinResponse.responseStatus !== ResponseStatus.SUCCESS) {
      log('Server Returned Error Response for Auto Login!', autologinResponse);
      return dispatch(failAutoLogin(autologinResponse.errorCodes, autologinResponse.message));
    } else {
      log('Server returned Success Response for Auto Login', autologinResponse);
      const accessTokenInfo = { ...autologinResponse.accessTokenInfo };
      storeAuthenticationToStorage(accessTokenInfo);
      return dispatch(finishAutoLogin(accessTokenInfo));
    }
  } catch (error) {
    log('Error occurred for Auto Login: ', error);
    return dispatch(failAutoLogin('error.connection', error.toString()));
  }
};

const startAutoLogin: ActionCreator<IStartAutoLogin> = () => ({
  type: AutoLoginActionType.START_AUTO_LOGIN,
});

const finishAutoLogin: ActionCreator<IFinishAutoLogin> = (accessTokenInfo: IAccessTokenInfo) => ({
  type: AutoLoginActionType.FINISH_AUTO_LOGIN,
  accessTokenInfo: accessTokenInfo,
});

const failAutoLogin: ActionCreator<IFailAutoLogin> = (errorCode: string, errorMessage: string) => ({
  type: AutoLoginActionType.FAIL_AUTO_LOGIN,
  errorCode: errorCode,
  errorMessage: errorMessage,
});
