import * as React from 'react';
import { Switch, Route } from 'react-router';

import Login from '../containers/login/Login';
import PasswordReset from '../containers/passwordreset/PasswordReset';
import PasswordChange from '../containers/passwordchange/PasswordChange';
import Error404 from '../errors/error404/Error404';
import Logout from '../containers/logout/Logout';
import Register from '../containers/register/Register';
import ErrorRegister from '../errors/errorregister/ErrorRegister';
import PasswordChangeError from '../errors/passwordchangeerror/PasswordChangeError';
import AutoLogin from '../containers/autologin/AutoLogin';
import ExpiredPasswordChange from '../containers/expiredpasswordchange/ExpiredPasswordChange';

/**
 * Routes to different pages of the Application.
 *
 * @returns
 *
 * @author Gazi Rahman
 */
const routes: React.SFC = () => (
  <Switch>
    <Route path="/" exact={true} component={Login} />
    <Route path="/login/:gigyaSiteId?" component={Login} />
    <Route path="/autologin/:gigyaSiteId?" component={AutoLogin} />
    <Route path="/logout/:gigyaSiteId?" component={Logout} />
    <Route path="/register" component={Register} />
    <Route path="/registerfailed" component={ErrorRegister} />
    <Route path="/passwordreset" component={PasswordReset} />
    <Route path="/passwordchange/:apiKey/:passwordResetToken" component={PasswordChange} />
    <Route path="/expiredpasswordchange" component={ExpiredPasswordChange} />
    <Route path="/passwordchangefailed" component={PasswordChangeError} />
    <Route component={Error404} />
  </Switch>
);

export default routes;
