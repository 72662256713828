import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import log from '../../util/Log';

import IPasswordResetState from './PasswordResetState';

import axios from '../../axiosFactory';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import PasswordResetActionTypes, {
  IStartPasswordReset,
  IFinishPasswordReset,
  IFailPasswordReset,
  IAllPaswordResetActions,
  IResetPasswordReset,
} from './PasswordResetActionTypes';
import { EndPoint } from '../../constants/EndPoints';

/**
 * Redux Action to Reset PasswordReset State
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetPasswordReset: ActionCreator<IResetPasswordReset> = () => ({
  type: PasswordResetActionTypes.RESET_PASSWORD_RESET,
});

const passwordResetUrl = process.env.REACT_APP_PASSWORD_RESET_URL || EndPoint.RESET_PASSWORD;

/**
 * Redux Thunk Action to Send PasswordReset Email to Customer
 *
 * @param email
 * @param termsConditionsAgreed
 * @param isInitialPasswordReset
 * @returns
 *
 * @author Gazi Rahman
 */
export const sendPasswordReset: ActionCreator<ThunkAction<
  Promise<any>,
  IPasswordResetState,
  null,
  IAllPaswordResetActions
>> = (email: string, termsConditionsAgreed: boolean, isInitialPasswordReset: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(startPasswordReset());
    // eslint-disable-next-line
    // const config = { headers: { 'Content-Type': FORM_URL_ENCODED_CONTENT_TYPE } };

    const data = isInitialPasswordReset
      ? {
          email: email,
          terms: termsConditionsAgreed,
        }
      : {
          email: email,
        };

    try {
      const response = await axios.post(passwordResetUrl, data);
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response Password Reset Request!', resData);
        return dispatch(failPasswordReset(resData.errorCodes, resData.message));
      } else {
        log('Password Reset Request Processed Successfully: ', resData);
        return dispatch(finishPasswordReset());
      }
    } catch (error) {
      log('Failed to send request to reset password!', error);
      return dispatch(
        failPasswordReset(['error.serverError'], 'Failed to send request to reset password. Please try again later.'),
      );
    }
  };
};

const startPasswordReset: ActionCreator<IStartPasswordReset> = () => ({
  type: PasswordResetActionTypes.START_PASSWORD_RESET,
});

const finishPasswordReset: ActionCreator<IFinishPasswordReset> = () => ({
  type: PasswordResetActionTypes.FINISH_PASSWORD_RESET,
});

const failPasswordReset: ActionCreator<IFailPasswordReset> = (errorCodes: Array<string>, errorMessage: string) => ({
  type: PasswordResetActionTypes.FAIL_PASSWORD_RESET,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
