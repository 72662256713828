/**
 * Redux Action Types for Gigya RefreshUI
 *
 * @author Gazi Rahman
 */
export enum GigyaRefreshUIActionTypes {
  GIGYA_REFRESH_UI_START = 'GIGYA_REFRESH_UI_START',
  GIGYA_REFRESH_UI_FINISHED = 'GIGYA_REFRESH_UI_FINISHED',
  GIGYA_REFRESH_UI_RESET = 'GIGYA_REFRESH_UI_RESET',
}

export interface IGigyaRefreshUIStart {
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_START;
}

export interface IGigyaRefreshUIFinished {
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_FINISHED;
}

export interface IGigyaRefreshUIReset {
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_RESET;
}

export type IAllGigyaRefreshUIActions = IGigyaRefreshUIStart | IGigyaRefreshUIFinished | IGigyaRefreshUIReset;
