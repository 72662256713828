import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISecurityQuestion from '../../store/securityquestion/ISecurityQuestion';
import SecurityQuestionOption from './securityQuestionOption/SecurityQuestionOption';

/**
 * Properties for SecurityQuestionSelector.
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  questionNumber: number;
  question: string;
  onQuestionChange: (questionNumber: number, question: string) => void;
  notAllowedQuestions: Array<string>;
  securityQuestions?: Array<ISecurityQuestion>;
}

/**
 * Selector for selecting a Security Question from among all the Security Questions.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const securityQuestionSelector: React.SFC<IProps> = (props) => {
  const questionSelectHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const question = ev.currentTarget.value;
    props.onQuestionChange(props.questionNumber, question);
  };
  const { t } = props;

  const securityQuestionsOtions = props.securityQuestions
    ? props.securityQuestions.map((securityQuestions, i) => (
        <SecurityQuestionOption
          key={i}
          securityQuestion={securityQuestions.question}
          notAllowedQuestions={props.notAllowedQuestions}
        />
      ))
    : null;

  return (
    <React.Fragment>
      <select
        name=""
        required={true}
        className={`form-control security-${props.questionNumber}`}
        value={props.question}
        onChange={questionSelectHandler}
        autoFocus={props.questionNumber === 1}
      >
        <option value="" disabled>
          {`${t('securityQuestionForm.label.question')} ${props.questionNumber}`}
        </option>
        {securityQuestionsOtions}
      </select>
      <div className="invalid-feedback">{t('securityQuestionForm.error.question.invalid')}</div>
    </React.Fragment>
  );
};

export default withTranslation('register')(securityQuestionSelector);
