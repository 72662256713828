import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PhoneCountrySelector from '../phonecountryselector/PhoneCountrySelector';

/**
 * Properties for TelephoneNumber Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  countryValue: string;
  onCountryValueChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
  number: string;
  onNumberChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  extension: string;
  onExtensionChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Component for taking Telephone Number input from user.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const phoneNumber: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <div className="select-options select-options-desktop d-flex">
      <PhoneCountrySelector countryValue={props.countryValue} onCountryValueChange={props.onCountryValueChange} />
      <div className="mob-input">
        <input
          type="tel"
          className="form-control"
          placeholder={t('profileInfoForm.placeHolder.telephoneNumber')}
          maxLength={15}
          required={true}
          value={props.number}
          onChange={props.onNumberChange}
        />
        <input
          type="text"
          className="form-control"
          placeholder={t('profileInfoForm.placeHolder.extension')}
          value={props.extension}
          onChange={props.onExtensionChange}
        />
      </div>
    </div>
  );
};

export default withTranslation('register')(phoneNumber);
