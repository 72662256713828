import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import NewPasswordImg from '../../assets/images/CDC/new-password.svg';
import PasswordVisibilityImg from '../../assets/images/CDC/password-visibility.svg';
import PasswordVisibleImg from '../../assets/images/CDC/password-visible.svg';
import { CDCLoading } from '../../components/cdcloading/CDCLoading';
import RegisterLink from '../../components/registerLink/RegisterLink';
import IProgressBarStyle from '../../styles/IProgressBarStyle';
import { calculatePasswordStrength } from '../../util/PasswordStrengthUtil';
import { PASSWORD_STRENGTH_DESC, PasswordStrengthDescClass } from '../../util/PasswordStrengthUtil';

/**
 * Properties for PasswordChangeRequest Component
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  requireOldPassword: boolean;
  oldPassword?: string;
  password?: string;
  verifyPassword?: string;
  onOldPasswordChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  verifyPasswordChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRegisterLinkClick: (event: React.MouseEvent) => void;
  formSubmitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: () => boolean;
  serverError: boolean;
  serverErrorCodes?: Array<string>;
  serverErrorMessage?: string;
  passwordsDoNotMatch: boolean;
}

/**
 * State for PasswordChangeRequest Component
 *
 * @author Gazi Rahman
 */
interface IState {
  showPasswordText: boolean;
  showPasswordMessages: boolean;
  alphabetRestrictionValidated: boolean;
  numberRestrictionValidated: boolean;
  lengthRestrictionValidated: boolean;
  passwordScore: number;
  progressBarClass: string;
  progressBarStyle?: IProgressBarStyle;
}

/**
 * Value for Submit Button
 *
 * @author Gazi Rahman
 */
const SUBMIT_BUTTON_VALUE = 'submit';

/**
 * Password Change Request Page Component
 *
 * @author Gazi Rahman
 */
class PasswordChangeRequest extends React.Component<IProps, IState> {
  state = {
    showPasswordText: false,
    showPasswordMessages: false,
    alphabetRestrictionValidated: false,
    numberRestrictionValidated: false,
    lengthRestrictionValidated: false,
    passwordScore: 0,
    progressBarClass: '',
    progressBarStyle: undefined,
  };

  calculatePasswordStrength = (password: string): void => {
    const score = calculatePasswordStrength(password);

    this.setState({
      passwordScore: score,
      progressBarClass: PasswordStrengthDescClass[score],
      progressBarStyle: PASSWORD_STRENGTH_DESC[score],
    });

    // element.removeClass(descClass[score - 1]).addClass(descClass[score])
    // .css(desc[score]);
  };

  passwordVisibilityClickedHandler = (event: React.MouseEvent<HTMLImageElement>) => {
    const newVisibility = !this.state.showPasswordText;
    this.setState({
      showPasswordText: newVisibility,
    });
  };

  passwordFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      showPasswordMessages: true,
    });
  };

  passwordBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      showPasswordMessages: false,
    });
  };

  passwordKeyupHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentPassVal = event.currentTarget.value;
    const alphabetRestrictionValidated = currentPassVal.match(/[a-zA-Z]/g) !== null;
    const numberRestrictionValidated = currentPassVal.match(/[0-9]/g) !== null;
    const lengthRestrictionValidated = currentPassVal.length >= 8;
    this.setState(
      {
        alphabetRestrictionValidated: alphabetRestrictionValidated,
        numberRestrictionValidated: numberRestrictionValidated,
        lengthRestrictionValidated: lengthRestrictionValidated,
      },
      () => {
        this.calculatePasswordStrength(currentPassVal);
      },
    );
  };

  render() {
    const passVisbilityImg = this.state.showPasswordText ? PasswordVisibleImg : PasswordVisibilityImg;
    const passwordInputType = this.state.showPasswordText ? 'text' : 'password';
    const { t } = this.props;
    return (
      <React.Fragment>
        <div id="login-form" className="login-form reset-form">
          <form
            method="POST"
            action="plus-reset-password-success.html"
            className="needs-validation"
            noValidate={true}
            onSubmit={this.props.formSubmitHandler}
          >
            <div className="col">
              {this.props.requireOldPassword && (
                <div style={{ marginBottom: '20px' }}>
                  <input
                    type={passwordInputType}
                    className="form-control"
                    placeholder={t('passwordChangeRequest.placeholder.oldPassword')}
                    required={true}
                    value={this.props.oldPassword}
                    onChange={this.props.onOldPasswordChange}
                  />
                  <div className="password-container">
                    <img
                      className="password-visibility"
                      alt=""
                      src={passVisbilityImg}
                      onClick={this.passwordVisibilityClickedHandler}
                    />
                  </div>
                  <div className="invalid-feedback">{t('passwordChangeRequest.error.oldPassword.invalid')}</div>
                </div>
              )}

              <img src={NewPasswordImg} alt="" />
              <label className="">{t('passwordChangeRequest.label.newPassword')}</label>
              <input
                type={passwordInputType}
                className="form-control"
                placeholder={t('passwordChangeRequest.placeholder.newPassword')}
                pattern="^(?=.*[a-zA-Z])(?=.*[0-9])\S{8,}$"
                required={true}
                value={this.props.password}
                onChange={this.props.passwordChangeHandler}
                onFocus={this.passwordFocusHandler}
                onBlur={this.passwordBlurHandler}
                onKeyUp={this.passwordKeyupHandler}
              />
              <div className="password-container">
                <img
                  className="password-visibility"
                  alt=""
                  src={passVisbilityImg}
                  onClick={this.passwordVisibilityClickedHandler}
                />
              </div>
              <div id="progress">
                <div
                  id="progress-bar"
                  className={this.state.progressBarClass}
                  style={this.state.progressBarStyle}
                ></div>
              </div>
              <p className="strength-text"></p>
              {this.state.showPasswordMessages ? (
                <div id="message" style={{ display: 'block' }}>
                  <h3>{t('passwordChangeRequest.error.newPassword.passwordRule.messagePrefix')}</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <p id="letter" className={this.state.alphabetRestrictionValidated ? 'valid' : 'invalid'}>
                        {t('passwordChangeRequest.error.newPassword.passwordRule.alphabet')}
                      </p>
                      {/* <p id="letter" className="invalid">
                      {t('passwordChangeRequest.error.newPassword.passwordRule.lowerCase')}
                      </p> */}
                      {/* <p id="capital" className="invalid">
                      {t('passwordChangeRequest.error.newPassword.passwordRule.upperCase')}
                    </p> */}
                      <p id="length" className={this.state.lengthRestrictionValidated ? 'valid' : 'invalid'}>
                        {t('passwordChangeRequest.error.newPassword.passwordRule.minChars')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p id="number" className={this.state.numberRestrictionValidated ? 'valid' : 'invalid'}>
                        {t('passwordChangeRequest.error.newPassword.passwordRule.number')}
                      </p>
                      {/* <p id="special" className="invalid">
                      {t('passwordChangeRequest.error.newPassword.passwordRule.specialChar')}
                    </p> */}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="invalid-feedback">{t('passwordChangeRequest.error.newPassword.invalid')}</div>
              <input
                type={passwordInputType}
                className="form-control"
                placeholder={t('passwordChangeRequest.placeholder.confirmPassword')}
                required={true}
                value={this.props.verifyPassword}
                onChange={this.props.verifyPasswordChangeHandler}
              />
              <div className="password-container">
                <img
                  className="password-visibility"
                  alt=""
                  src={passVisbilityImg}
                  onClick={this.passwordVisibilityClickedHandler}
                />
              </div>

              <div className="no-match" style={{ display: 'block' }}>
                {this.props.passwordsDoNotMatch ? t('passwordChangeRequest.error.confirmPassword.noMatch') : null}
              </div>

              {this.props.serverError ? (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {this.props.serverErrorMessage}
                </div>
              ) : null}

              <div className="form-btns justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  value={SUBMIT_BUTTON_VALUE}
                  disabled={this.props.isLoading()}
                >
                  {t('passwordChangeRequest.label.submit')}
                </button>
              </div>

              {this.props.isLoading() ? <CDCLoading /> : null}
            </div>
          </form>
        </div>
        <RegisterLink onRegisterLinkClick={this.props.onRegisterLinkClick} />
      </React.Fragment>
    );
  }
}

export default withTranslation('passwordChange')(PasswordChangeRequest);
