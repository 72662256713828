import IGigyaRefreshUIState from './IGigyaRefreshUIState';
import { Reducer } from 'redux';
import { IAllGigyaRefreshUIActions, GigyaRefreshUIActionTypes } from './GigyaRefreshUIActionTypes';

const initialState: IGigyaRefreshUIState = {
  gigyaRefreshUIStarted: false,
  gigyaRefreshUIFailed: false,
  gigyaRefreshUIFinished: false,
  gigyaRefreshUIErrorCodes: undefined,
  gigyaRefreshUIErrorMessage: undefined,
};

/**
 * Redux Reducer for Gigya RefreshUI
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const gigyaRefreshUIReducer: Reducer<IGigyaRefreshUIState, IAllGigyaRefreshUIActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_START:
      return {
        ...state,
        gigyaRefreshUIStarted: true,
        gigyaRefreshUIFailed: false,
        gigyaRefreshUIFinished: false,
        gigyaRefreshUIErrorCodes: undefined,
        gigyaRefreshUIErrorMessage: undefined,
      };
    case GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_FINISHED:
      return {
        ...state,
        gigyaRefreshUIFinished: true,
      };
    case GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default gigyaRefreshUIReducer;
