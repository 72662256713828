import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryDet } from '../../util/utils';
import '../../assets/css/header.footer.css';


const FooterCmp = () => {
    const { t, i18n } = useTranslation();


    const HEADER_FOOTER_DOMAIN_CONSTANT = "https://dev.quidelortho.com/";



    let languageCountry;
    let i18Language = i18n?.language?.toString().toLowerCase() || "en-global";


    // Checking if only language is selected?
    const isCountryAbsent = i18Language.split("-")?.length < 2;

    if (isCountryAbsent) {
        const country = getCountryDet(i18Language);

        languageCountry =
            country ? (i18Language + "-" + country) : (i18Language + "-" + i18Language);
    }
    else {
        languageCountry = i18Language;
    }



    const baseURL = process?.env?.REACT_APP_API_BASE_URL + "/";


    const headerFooterDomain =
        process?.env?.REACT_APP_PUBLIC_SITE_BASE_URL + "/"
        ||
        HEADER_FOOTER_DOMAIN_CONSTANT;


    const footerApi =
        process?.env?.REACT_APP_HEADER_FOOTER_WEB_SERVICE_URL
        +
        "?type=footer&culture="
        +
        languageCountry;




    const [footerSt, setFooterSt] = useState("");

    const getFooter = () => {
        fetch(footerApi)
            .then(response => {
                return response.text();
            })
            .then((data) => {
                const enc2 = data;
                const newDocument2 = (new DOMParser()).parseFromString(enc2, 'text/html');


                const aTags = newDocument2.body.querySelectorAll('.footer-hyperlink-section a');

                if (aTags) {
                    for (let i = 0; i < aTags.length; i++) {
                        const bool = aTags[i].href
                            &&
                            (aTags[i].href.indexOf("http://localhost") !== -1)
                            ||
                            (aTags[i].href.indexOf(baseURL) !== -1);

                        if (bool) {
                            let oldFooterLogoHref = aTags[i].href?.split('//')[1].split('/');

                            oldFooterLogoHref && oldFooterLogoHref.shift();

                            aTags[i].href = headerFooterDomain + oldFooterLogoHref?.join('/');
                        }
                    }
                }


                const imgs = newDocument2.getElementsByTagName("img");

                if (imgs) {
                    for (let i = 0; i < imgs.length; i++) {
                        if ((imgs[i].src.indexOf("http://localhost") !== -1)
                            ||
                            (imgs[i].src.indexOf(baseURL) !== -1)) {
                            let oldFooterLogoSRC = imgs[i].src.split('//')[1].split('/');

                            oldFooterLogoSRC.shift();

                            imgs[i].src = headerFooterDomain + oldFooterLogoSRC.join('/');
                        }
                    }
                }


                const sources = newDocument2.getElementsByTagName("source");

                if (sources) {
                    for (let i = 0; i < sources.length; i++) {
                        if ((sources[i].srcset.indexOf("http://localhost") !== -1)
                            ||
                            (sources[i].srcset.indexOf(baseURL) !== -1)) {
                            sources[i].srcset = headerFooterDomain + sources[i].srcset;
                        }
                    }
                }


                const converted2 = newDocument2.body.innerHTML;

                setFooterSt(converted2);
            })
            .catch((err) => {
                console.log("Footer API call error:", err);
            });

    }


    useEffect(() => {
        getFooter();
    }, [t, i18n]);

    return (
        <div>
            {
                footerSt
                    ?
                    <div
                        id="footer"
                        className="xf-web-container"
                        dangerouslySetInnerHTML={{ __html: footerSt }}
                    >
                    </div>
                    :
                    <div className="hdr-ftr-loading">Loading....</div>
            }
        </div>
    );
}

export default FooterCmp;