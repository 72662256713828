import React from 'react';
import '../../assets/css/sso.banner.css';

const SSOBanner = ({ setBannerState }) => {
    const getDateInfo = () => {
        const endDate = new Date("2024-07-13T09:30:00.000-04:00");

        const currentDate = new Date();



        return currentDate < endDate;
    }




    const bannerTitle = "OrthoPlus Single Sign On (SSO) Unavailable Saturday, July 13";
    const bannerBody = "OrthoPlus Single Sign On (SSO) will be unavailable on Saturday July 13, from 9:00 AM to 12:00 PM Eastern Time due to a scheduled maintenance. During the maintenance period*, you will not be able to login to any OrthoPlus applications. Please plan your work accordingly. Thank you for your patience.";
    const bannerDisclaimer = "*Any users logged in prior to this maintenance period, will continue to have access during the maintenance."



    const showBanner = getDateInfo();

    return (
        <>
            {
                showBanner
                &&
                <div className="cdc-sso-bnr">
                    <div className="bnr-container">
                        <div className="bnr-icon">
                            <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h48v48h-48z" fill="none" /><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" />
                            </svg>
                        </div>

                        <div className="bnr-txt">
                            <h3>
                                {bannerTitle}
                            </h3>
                            <p className="bnr-txt-body">
                                {bannerBody}
                            </p>
                            <p className="bnr-txt-disc">
                                {bannerDisclaimer}
                            </p>
                        </div>

                        <div
                            className="bnr-close"
                            onClick={() => {
                                setBannerState(false);
                            }}
                        >
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default SSOBanner;