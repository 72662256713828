import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import PhoneCountrySelector from '../phonecountryselector/PhoneCountrySelector';

/**
 * Properties for MobileNumber.
 *
 * @author Gazi Rahman
 */
interface IProps extends WithTranslation {
  countryValue?: string;
  onCountryValueChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
  number?: string;
  onNumberChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Component to take MobileNumber input from the user.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const mobileNumber: React.SFC<IProps> = (props) => {
  const { t } = props;
  return (
    <div className="select-options select-options-desktop mb-5 d-flex">
      <PhoneCountrySelector countryValue={props.countryValue} onCountryValueChange={props.onCountryValueChange} />
      <input
        type="tel"
        className="form-control mobile-number"
        maxLength={15}
        placeholder={t('profileInfoForm.placeHolder.mobileNumber')}
        value={props.number}
        onChange={props.onNumberChange}
      />
    </div>
  );
};

export default withTranslation('register')(mobileNumber);
