import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';

import { withTranslation, WithTranslation } from 'react-i18next';

import withConnectionErrorHandler from '../../hoc/withconnectionerrorhandler/WithConnectionErrorHandler';

import { IApplicationState } from '../../store/Store';
import { resetRegistration } from '../../store/register/RegistrationActions';

import RegistrationStep from '../../store/register/RegistrationStep';
import IRegistrationState from '../../store/register/IRegistrationState';
import IRegistrationForm from '../../store/register/IRegistrationForm';
import {
  submitRegistration,
  setRegistrationType,
  setRegistrationKey,
  setRequestedFeatures,
} from '../../store/register/RegistrationActions';

import {
  resetAutoLinkedCustomerUser,
  getAutoLinkedCustomerUser,
} from '../../store/auto_registration/AutoLinkedCustomerUserActions';

import { checkEmailAvailbleForRegister } from '../../store/register/CheckEmailAvailableForRegisterAction';

import Layout from '../../hoc/layout/Layout';

import RegisterStepBar from '../../components/registerstepbar/RegisterStepBar';
import ProfileInfoForm from '../../forms/register/profileinfoform/ProfileInfoForm';
import SecurityQuestionsForm from '../../forms/register/securityquestionsform/SecurityQuestionsForm';
import CustomerInfoForm from '../../forms/register/customerinfoform/CustomerInfoForm';
import PermissionsForm from '../../forms/register/permissionsform/PermissionsForm';
import RegistrationFinished from './registrationfinished/RegistrationFinished';
import { Links } from '../../constants/Links';
import { RegisterSubpath } from '../../constants/RegisterSubpaths';

import { CDCLoading } from '../../components/cdcloading/CDCLoading';

import log from '../../util/Log';
import IAutoLinkedCustomerUserState from '../../store/auto_registration/IAutoLinkedCustomerUserState';
import { RegistrationType } from '../../store/register/RegistrationType';

/**
 * Properties for Register Container
 *
 * @author Gazi Rahman
 */
interface IProps extends RouteComponentProps, IRegistrationState, IAutoLinkedCustomerUserState, WithTranslation {
  resetRegistration: typeof resetRegistration;
  submitRegistration: typeof submitRegistration;
  resetAutoLinkedCustomerUser: typeof resetAutoLinkedCustomerUser;
  getAutoLinkedCustomerUser: typeof getAutoLinkedCustomerUser;
  setRegistrationType: typeof setRegistrationType;
  setRegistrationKey: typeof setRegistrationKey;
  setRequestedFeatures: typeof setRequestedFeatures;
  checkEmailAvailbleForRegister: typeof checkEmailAvailbleForRegister;
}

interface IState {
  registrationKey?: string;
}

/**
 * Container for Register Page
 *
 * @author Gazi Rahman
 */
class Register extends React.Component<IProps, IState> {
  componentDidMount() {
    this.processRegistrationStep(RegistrationStep.PROFILE_INFO, false, false);
    this.props.resetRegistration();
    this.props.resetAutoLinkedCustomerUser();

    const queryParams = queryString.parse(this.props.location.search);
    log('Found Query Parameters: ', queryParams);
    this.setRegistrationKey(queryParams);
  }

  private setRegistrationKey(queryParams: queryString.ParsedQuery<string>) {
    const registrationKey = queryParams?.key;
    log('Found Registration Key: ', registrationKey);
    if (registrationKey) {
      if (Array.isArray(registrationKey)) {
        const firstRegistrationKey = registrationKey[0];
        this.setState({
          ...this.state,
          registrationKey: firstRegistrationKey,
        });
      } else if (typeof registrationKey === 'string') {
        this.setState({
          ...this.state,
          registrationKey,
        });
      }
    }
  }

  componentDidUpdate() {
    this.processRegistrationStep(
      this.props.activeStep,
      this.props.registrationSubmitFinished,
      this.props.registrationSubmitFailed,
    );

    if (this.state?.registrationKey && !this.props.autoLinkedCustomerUserRetrievalStarted) {
      this.props.getAutoLinkedCustomerUser(this.state.registrationKey);
    }

    if (
      this.props.autoLinkedCustomerUser &&
      this.props.registrationForm.registrationType === RegistrationType.REGULAR
    ) {
      this.props.setRegistrationType(RegistrationType.AUTO_LINKED_CUSTOMER_REGISTRATION);
      this.props.setRegistrationKey(this.state.registrationKey);
      if (this.props.autoLinkedCustomerUser.b2bApplication && this.props.autoLinkedCustomerUser.applicationFeature) {
        this.props.setRequestedFeatures(
          this.props.autoLinkedCustomerUser.b2bApplication,
          this.props.autoLinkedCustomerUser.applicationFeature,
        );
      }
      this.props.checkEmailAvailbleForRegister(this.props.autoLinkedCustomerUser.userEmail);
    }
  }

  processRegistrationStep = (
    registrationStep: RegistrationStep,
    registrationFinished: boolean,
    registrationFailed: boolean,
  ) => {
    if (registrationFailed) {
      this.props.history.push(Links.REGISTER_FAILED);
    } else if (registrationFinished) {
      this.setRegistrationSubPath(RegisterSubpath.SUCCESS);
    } else {
      switch (registrationStep) {
        case RegistrationStep.PROFILE_INFO:
          this.setRegistrationSubPath(RegisterSubpath.PROFILE_INFO);
          break;
        case RegistrationStep.SECURITY_QUESTIONS:
          this.setRegistrationSubPath(RegisterSubpath.SECURITY_QUESTIONS);
          break;
        case RegistrationStep.CUSTOMER_FEATURES:
          this.setRegistrationSubPath(RegisterSubpath.CUSTOMER_INFO);
          break;
        case RegistrationStep.CONSENT_AGREEMENT:
          this.setRegistrationSubPath(RegisterSubpath.PERMISSIONS);
          break;
        case RegistrationStep.SUBMIT_REGISTRATION:
          if (!this.isLoading()) {
            this.props.submitRegistration(this.props.registrationForm);
          }
          break;
        default:
      }
    }
  };

  setRegistrationSubPath = (subpath: string) => {
    if (this.props.location.pathname !== this.props.match.path + subpath) {
      this.props.history.push(this.props.match.path + subpath);
    }
  };

  isLoading = () => {
    return (
      (this.props.registrationSubmitStarted &&
        !(this.props.registrationSubmitFinished || this.props.registrationSubmitFailed)) ||
      (this.props.autoLinkedCustomerUserRetrievalStarted &&
        !(this.props.autoLinkedCustomerUserRetrievalFinished || this.props.autoLinkedCustomerUserRetrievalFailed))
    );
  };

  render() {
    const activeStep = this.props.registrationSubmitFinished
      ? RegistrationStep.SUBMIT_REGISTRATION
      : this.props.activeStep;
    const { t } = this.props;
    return (
      <Layout title={t('pageTitle')}>
        {activeStep === RegistrationStep.PROFILE_INFO && (
          <div className="registration-info">
            <p className="form-text grey-text">{t('orthoPlusInfo')}</p>
          </div>
        )}

        <RegisterStepBar activeStep={activeStep} />

        <div className="register-section">
          <div className="container">
            {this.props.autoLinkedCustomerUserRetrievalFailed ? (
              <div className="invalid-feedback invalid-feedback-register">
                {this.props.errorCodes && t(this.props.errorCodes[0]) !== this.props.errorCodes[0]
                  ? t(this.props.errorCodes[0])
                  : this.props.errorMessage}
              </div>
            ) : null}

            <Switch>
              <Route
                path={this.props.match.path + RegisterSubpath.PROFILE_INFO}
                render={(props) => (
                  <ProfileInfoForm autoLinkedCustomerUser={this.props.autoLinkedCustomerUser} {...props} />
                )}
              />
              <Route
                path={this.props.match.path + RegisterSubpath.SECURITY_QUESTIONS}
                component={SecurityQuestionsForm}
              />
              <Route
                path={this.props.match.path + RegisterSubpath.CUSTOMER_INFO}
                component={() => <CustomerInfoForm autoLinkedCustomerUser={this.props.autoLinkedCustomerUser} />}
              />
              <Route path={this.props.match.path + RegisterSubpath.PERMISSIONS} component={PermissionsForm} />
              <Route path={this.props.match.path + RegisterSubpath.SUCCESS} component={RegistrationFinished} />
            </Switch>

            {this.isLoading() ? <CDCLoading /> : null}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  ...state.registrationState,
  ...state.autoLinkedCustomerUserState,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetRegistration: () => dispatch(resetRegistration()),
  submitRegistration: (registrationForm: IRegistrationForm) => dispatch(submitRegistration(registrationForm)),
  resetAutoLinkedCustomerUser: () => dispatch(resetAutoLinkedCustomerUser()),
  setRegistrationType: (registrationType: RegistrationType) => dispatch(setRegistrationType(registrationType)),
  setRegistrationKey: (registrationKey: string) => dispatch(setRegistrationKey(registrationKey)),
  setRequestedFeatures: (b2bApplication: string, applicationFeatures: string) =>
    dispatch(setRequestedFeatures(b2bApplication, applicationFeatures)),
  getAutoLinkedCustomerUser: (key: string) => dispatch(getAutoLinkedCustomerUser(key)),
  checkEmailAvailbleForRegister: (email: string) => dispatch(checkEmailAvailbleForRegister(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConnectionErrorHandler(withTranslation('register')(Register)));
