import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import log from '../../util/Log';

import axios from '../../axiosFactory';

import {
  ISecurityQuestionsLoadStart,
  SecurityQuestionsActionTypes,
  ISecurityQuestionsLoadFinish,
  ISecurityQuestionsLoadFail,
  IAllSecurityQuestionActions,
} from './SecurityQuestionsActionTypes';
import ISecurityQuestion from './ISecurityQuestion';
import ISecurityQuestionsState from './ISecurityQuestionsState';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import { EndPoint } from '../../constants/EndPoints';

/**
 * Redux Action to Load Security Questions
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadSecurityQuestions: ActionCreator<ThunkAction<
  Promise<any>,
  ISecurityQuestionsState,
  null,
  IAllSecurityQuestionActions
>> = () => {
  return async (dispatch: ThunkDispatch<ISecurityQuestionsState, null, IAllSecurityQuestionActions>) => {
    dispatch(startSecurityQuestionsLoad());
    try {
      const response = await axios.get(EndPoint.SECURITY_QUESTIONS);
      const responseData = response.data;
      if (!response.data.responseStatus) {
        return dispatch(failSecurityQuestionsLoad('error.data.load', 'Securirity Questions Loading Failed'));
      } else if (responseData.responseStatus === ResponseStatus.ERROR) {
        return dispatch(failSecurityQuestionsLoad(responseData.errorCodes, responseData.message));
      } else if (responseData.responseStatus === ResponseStatus.SUCCESS) {
        return dispatch(finishSecurityQuestionsLoad(responseData.securityQuestions));
      } else {
        return dispatch(failSecurityQuestionsLoad(['error.response.unknown'], 'Server returned unknown response'));
      }
    } catch (error) {
      log('Security QUestions Load failed: ', error);
      return dispatch(failSecurityQuestionsLoad('error.connection', error.toString()));
    }
  };
};

const startSecurityQuestionsLoad: ActionCreator<ISecurityQuestionsLoadStart> = () => ({
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_START,
});

const finishSecurityQuestionsLoad: ActionCreator<ISecurityQuestionsLoadFinish> = (
  securityQuestions: Array<ISecurityQuestion>,
) => ({
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FINISH,
  securityQuestions: securityQuestions,
});

const failSecurityQuestionsLoad: ActionCreator<ISecurityQuestionsLoadFail> = (
  errorCodes: Array<string>,
  errorMessage: string,
) => ({
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FAIL,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
