import IAccessTokenInfo from '../../api/response/IAccessTokenInfo';

/**
 * Redux Action Types used for Authentication
 *
 * @author Gazi Rahman
 */
export enum AuthActionType {
  AUTHENTICATION_LOADING = 'AUTHENTICATION_LOADING',
  LOAD_AUTHENTICATION = 'LOAD_AUTHENTICATION',
  START_AUTH_REQUEST = 'START_AUTH_REQUEST',
  AUTHENTICATION_COMPLETED = 'AUTHENTICATION_COMPLETED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  SEND_PENDING_PASSWORD_CHANGE = 'SEND_PENDING_PASSWORD_CHANGE',
  SEND_PASSWORD_NOT_SET = 'SEND_PASSWORD_NOT_SET',
  MARK_AUTHENTICATED = 'MARK_AUTHENTICATED',
  RESET_AUTHENTICATION_PROCESS = 'RESET_AUTHENTICATION_PROCESS',
  CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION',
  SEND_PENDING_ACCOUNT_VERIFICATION = 'SEND_PENDING_ACCOUNT_VERIFICATION',
  VERIRIFICATION_EMAIL_SENT = 'VERIRIFICATION_EMAIL_SENT',
  RESET_VERIFICATION_EMAIL_SENT = 'RESET_VERIFICATION_EMAIL_SENT',
}

export interface IAuthenticationLoading {
  readonly type: AuthActionType.AUTHENTICATION_LOADING;
}

export interface ILoadAuthentication {
  readonly type: AuthActionType.LOAD_AUTHENTICATION;
  accessTokenInfo: IAccessTokenInfo;
}

export interface IAuthenticationStarted {
  readonly type: AuthActionType.START_AUTH_REQUEST;
  orthoUser: boolean;
}

export interface IAuthenticationCompleted {
  readonly type: AuthActionType.AUTHENTICATION_COMPLETED;
  accessTokenInfo: IAccessTokenInfo;
  orthoUser: boolean;
}

export interface IAuthenticationFailed {
  readonly type: AuthActionType.AUTHENTICATION_FAILED;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface ISendPendingPasswordChange {
  readonly type: AuthActionType.SEND_PENDING_PASSWORD_CHANGE;
  errorCodes: Array<string>;
  errorMessage: string;
  uid?: string;
  regToken?: string;
}

export interface ISendPasswordNotSet {
  readonly type: AuthActionType.SEND_PASSWORD_NOT_SET;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface ISendPendingAccountVerification {
  readonly type: AuthActionType.SEND_PENDING_ACCOUNT_VERIFICATION;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IResentAccountVerificationEmail {
  readonly type: AuthActionType.VERIRIFICATION_EMAIL_SENT;
}

export interface IResetVerificationEmailSent {
  readonly type: AuthActionType.RESET_VERIFICATION_EMAIL_SENT;
}

export interface IMarkAuthenticated {
  readonly type: AuthActionType.MARK_AUTHENTICATED;
}

export interface IResetAuthenticationProcess {
  readonly type: AuthActionType.RESET_AUTHENTICATION_PROCESS;
}

export interface IClearAuthentication {
  readonly type: AuthActionType.CLEAR_AUTHENTICATION;
}

export type IAllAuthActions =
  | IAuthenticationLoading
  | ILoadAuthentication
  | IAuthenticationStarted
  | IAuthenticationCompleted
  | IAuthenticationFailed
  | ISendPendingPasswordChange
  | ISendPasswordNotSet
  | ISendPendingAccountVerification
  | IResentAccountVerificationEmail
  | IResetVerificationEmailSent
  | IMarkAuthenticated
  | IResetAuthenticationProcess
  | IClearAuthentication;
