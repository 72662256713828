/**
 * @author Gazi Rahman
 */
import React from 'react';
import loadingImg from '../../assets/images/CDC/Qo_icon.svg';

interface CDCLoadingProps {
  styles?: any;
}

/**
 * Shows Ortho branded loading image
 *
 * @param props
 * @returns
 */
export const CDCLoading: React.FC<CDCLoadingProps> = (props: CDCLoadingProps) => {
  return (
    <div className="loader" style={props.styles}>
      <img src={loadingImg} className="rotate" alt="" />
    </div>
  );
};
