import * as React from 'react';

import Logo from '../../components/logo/Logo';
import Header from './header/Header';
import Footer from './footer/Footer';
import { CssClasses } from '../../constants/CssClasses';
import HeaderCmp from '../../components/headerfooter/HeaderCmp';
import FooterCmp from '../../components/headerfooter/FooterCmp';
import '../../assets/css/header.footer.css';

/**
 * Properties for Layout Component
 *
 * @author Gazi Rahman
 */
interface IProps {
  title: string;
  isErrorPage?: boolean;
}

/**
 * HighOrderComponent for All the Page Templates/Layouts
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const layout: React.SFC<IProps> = (props) => {
  const mainClasses = CssClasses.DEFAULT_MAIN_CLASSES + (props.isErrorPage ? ' ' + CssClasses.ERROR_PAGE_CLASS : '');
  const titleClass = CssClasses.TITLE_CLASS + (props.isErrorPage ? ' ' + CssClasses.TITLE_ERROR_PAGE : '');
  return (
    <React.Fragment>
      <HeaderCmp />
      {/* <Header /> */}
      <main className={mainClasses}>
        <div className="container">
          <Logo />

          <div className={titleClass}>
            <h1>{props.title}</h1>
          </div>

          {props.children}
        </div>
      </main>
      {/* <Footer /> */}
      <FooterCmp />
    </React.Fragment>
  );
};

export default layout;
