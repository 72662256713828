/**
 * Utility script for different UserAgent related functionalities
 *
 * @author Gazi Rahman
 */

// @ts-ignore
import iswebview from 'is-webview';

export const isWebView = (): boolean => {
  const uaStr = navigator.userAgent;
  return iswebview(uaStr) || uaStr.toLowerCase().match(/android.*applewebkit(?=.*version)/) != null;
};
