/**
 * different Utilities for Emails used in the Application
 *
 * @author Gazi Rahman
 */

const validEmailRegex = /^[A-Z0-9._%+'-]+@([A-Z0-9-]+\.)+[A-Z]{2,7}$/i;

const orthoEmailAddressSuffix = '@orthoclinicaldiagnostics.com';
const quidelorthoEmailAddressSuffix = '@quidelortho.com';

export const isValidEmail = (emailAddress: string): boolean => validEmailRegex.test(emailAddress);

export const isValidOrthoEmployeeEmail = (emailAddress: string): boolean =>
  emailAddress.trim().toLowerCase().endsWith(orthoEmailAddressSuffix) ||
  emailAddress.trim().toLowerCase().endsWith(quidelorthoEmailAddressSuffix);
