/**
 * @author Gazi Rahman
 */
import { applyMiddleware, createStore, Store, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import IAuthState from './auth/IAuthState';
import authReducer from './auth/AuthReducer';
import afterAuthUserInfoUpdateReducer from './auth/afterauth/AfterAuthUserInfoUpdateReducer';
import IAfterAuthUserInfoUpdateState from './auth/afterauth/IAfterAuthUserInfoUpdateState';
import IPasswordResetState from './passwordreset/PasswordResetState';
import passwordResetReducer from './passwordreset/PasswordResetReducer';
import IPasswordChangeState from './passwordchange/PasswordChangeState';
import passwordChangeReducer from './passwordchange/PasswordChangeReducer';
import IGigyaState from './auth/gigya/IGigyaState';
import gigyaReducer from './auth/gigya/GigyaReducer';
import IGigyaSessionState from './auth/gigya/session/IGigyaSessionState';
import gigyaSessionReducer from './auth/gigya/session/GigyaSessionReducer';
import IGigyaRefreshUIState from './auth/gigya/refreshUi/IGigyaRefreshUIState';
import gigyaRefreshUIReducer from './auth/gigya/refreshUi/GigyaRefreshUIReducer';
import IRegistrationState from './register/IRegistrationState';
import registrationReducer from './register/RegistrationReducer';
import ISecurityQuestionsState from './securityquestion/ISecurityQuestionsState';
import securityQuestionsReducer from './securityquestion/SecurityQuestionsReducer';
import IAutoLoginState from './auth/autologin/IAutoLoginState';
import autoLoginReducer from './auth/autologin/AutoLoginReducer';
import IGigyaLogoutState from './auth/gigya/logout/IGigyaLogoutState';
import gigyaLogoutReducer from './auth/gigya/logout/GigyaLogoutReducer';
import accountInfoRetrieveReducer from './auth/gigya/accountInfoRetrieve/AccountInfoRetrieveReducer';
import IAccountInfoRetrieveState from './auth/gigya/accountInfoRetrieve/IAccountInfoRetrieveState';
import IAutoLinkedCustomerUserState from './auto_registration/IAutoLinkedCustomerUserState';
import autoLinkedCustomerUserReducer from './auto_registration/AutoLinkedCustomerUserReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

/**
 * Global Application State
 *
 * @author Gazi Rahman
 */
export interface IApplicationState {
  authState: IAuthState;
  afterAuthUserInfoUpdateState: IAfterAuthUserInfoUpdateState;
  passwordResetState: IPasswordResetState;
  passwordChangeState: IPasswordChangeState;
  gigyaState: IGigyaState;
  gigyaSessionState: IGigyaSessionState;
  gigyaRefreshUIState: IGigyaRefreshUIState;
  gigyaLogoutState: IGigyaLogoutState;
  registrationState: IRegistrationState;
  securityQuestionsState: ISecurityQuestionsState;
  autoLoginState: IAutoLoginState;
  accountInfoRetrieveState: IAccountInfoRetrieveState;
  autoLinkedCustomerUserState: IAutoLinkedCustomerUserState;
}

const allReducers = {
  authState: authReducer,
  afterAuthUserInfoUpdateState: afterAuthUserInfoUpdateReducer,
  passwordResetState: passwordResetReducer,
  passwordChangeState: passwordChangeReducer,
  gigyaState: gigyaReducer,
  gigyaSessionState: gigyaSessionReducer,
  gigyaRefreshUIState: gigyaRefreshUIReducer,
  gigyaLogoutState: gigyaLogoutReducer,
  registrationState: registrationReducer,
  securityQuestionsState: securityQuestionsReducer,
  autoLoginState: autoLoginReducer,
  accountInfoRetrieveState: accountInfoRetrieveReducer,
  autoLinkedCustomerUserState: autoLinkedCustomerUserReducer,
};

/**
 * Root Redux Reducer for the Application
 *
 * @author Gazi Rahman
 */
const rootReducer = combineReducers<IApplicationState>(allReducers);

/**
 * Global Application Store
 *
 * @returns
 *
 * @author Gazi Rahman
 */
const configureStore = (): Store => {
  const composeEnhancers = process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

  const store: Store<IApplicationState> = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

  return store;
};

export default configureStore;
