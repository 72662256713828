import IAccessTokenInfo from '../api/response/IAccessTokenInfo';

/**
 * Utilities for accessing Browser Stoage (local/session) used in the application.
 *
 * @author Gazi Rahman
 */

export const orthoAuthStorageKey = process.env.REACT_APP_AUTH_STORAGE_KEY || 'ORTHO_AUTH_LOCAL';

export const loadAuthenticationFromStorage = () => {
  const orthoAuthFromStorage = sessionStorage.getItem(orthoAuthStorageKey);
  const authInfo: IAccessTokenInfo = orthoAuthFromStorage ? JSON.parse(orthoAuthFromStorage) : null;

  return authInfo;
};

export const removeAuthenticationFromStorage = () => {
  sessionStorage.removeItem(orthoAuthStorageKey);
};

/**
 * Set the authentication info in local storate.
 */
export const storeAuthenticationToStorage = (accessTokenInfo: IAccessTokenInfo) => {
  sessionStorage.setItem(orthoAuthStorageKey, JSON.stringify(accessTokenInfo));
};
