import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Link to go to the LoginPage.
 *
 * @returns
 *
 * @author Gazi Rahman
 */
const loginLink: React.SFC = () => {
  return (
    <p className="login-link cdc-btm-link">
      <Trans i18nKey="loginLink" ns="common">
        Already have an account? <Link to="/login">Log in</Link>
      </Trans>
    </p>
  );
};

export default loginLink;
