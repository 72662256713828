/**
 * Redux Action Types for Sending PasswordReset Email to Customer
 *
 * @author Gazi Rahman
 */
enum PasswordResetActionTypes {
  START_PASSWORD_RESET = 'START_PASSWORD_RESET',

  FINISH_PASSWORD_RESET = 'FINISH_PASSWORD_RESET',

  FAIL_PASSWORD_RESET = 'FAIL_PASSWORD_RESET',

  RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET',
}

export interface IStartPasswordReset {
  type: PasswordResetActionTypes.START_PASSWORD_RESET;
}

export interface IFinishPasswordReset {
  type: PasswordResetActionTypes.FINISH_PASSWORD_RESET;
}

export interface IFailPasswordReset {
  type: PasswordResetActionTypes.FAIL_PASSWORD_RESET;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IResetPasswordReset {
  type: PasswordResetActionTypes.RESET_PASSWORD_RESET;
}

export type IAllPaswordResetActions =
  | IStartPasswordReset
  | IFinishPasswordReset
  | IFailPasswordReset
  | IResetPasswordReset;

export default PasswordResetActionTypes;
