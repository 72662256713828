import { Reducer } from 'redux';
import IAutoLinkedCustomerUserState from './IAutoLinkedCustomerUserState';
import {
  AutoLinkedCustomerUserActionTypes,
  IAllAutoLinkedCustomerUserActions,
} from './AutoLinkedCustomerUserActionTypes';

const initialState: IAutoLinkedCustomerUserState = {
  autoLinkedCustomerUserRetrievalStarted: false,
  autoLinkedCustomerUserRetrievalFinished: false,
  autoLinkedCustomerUserRetrievalFailed: false,

  autoLinkedCustomerUser: undefined,

  errorCodes: undefined,
  errorMessage: undefined,
};

const autoLinkedCustomerUserReducer: Reducer<IAutoLinkedCustomerUserState, IAllAutoLinkedCustomerUserActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AutoLinkedCustomerUserActionTypes.START_GETTING_AUTO_LINKED_CUSTOMER_USER:
      return {
        ...initialState,
        autoLinkedCustomerUserRetrievalStarted: true,
      };
    case AutoLinkedCustomerUserActionTypes.RESET_AUTO_LINKED_CUSTOMER_USER:
      return { ...initialState };
    case AutoLinkedCustomerUserActionTypes.FINISHED_GETTING_AUTO_LINKED_CUSTOMER_USER:
      return {
        ...state,
        autoLinkedCustomerUserRetrievalFinished: true,
        autoLinkedCustomerUser: { ...action.autoLinkedCustomerUser },
      };
    case AutoLinkedCustomerUserActionTypes.FAILED_GETTING_AUTO_LINKED_CUSTOMER_USER:
      return {
        ...state,
        autoLinkedCustomerUserRetrievalFailed: true,
        errorCodes: action.errorCodes,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default autoLinkedCustomerUserReducer;
