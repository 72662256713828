import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import IRestResponse from '../../api/response/IRestResponse';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import { EndPoint } from '../../constants/EndPoints';
import IRegistrationForm from './IRegistrationForm';
import IRegistrationState from './IRegistrationState';
import {
  IAllRegistrationActions,
  IFaileRegistrationSubmit,
  IFinishRegistrationSubmit,
  IRegistrationReset,
  ISetRegistrationKey,
  ISetRegistrationType,
  ISetRequestedFeatures,
  IStartRegistrationSubmit,
  RegistrationActionType,
} from './RegistrationActionTypes';

import log from '../../util/Log';
import axios from '../../axiosFactory';
import { RegistrationType } from './RegistrationType';

export const setRegistrationType: ActionCreator<ISetRegistrationType> = (registrationType: RegistrationType) => ({
  type: RegistrationActionType.SET_REGISTRATION_TYPE,
  registrationType: registrationType,
});

export const setRegistrationKey: ActionCreator<ISetRegistrationKey> = (registrationKey: string) => ({
  type: RegistrationActionType.SET_REGISTRATION_KEY,
  registrationKey: registrationKey,
});

export const setRequestedFeatures: ActionCreator<ISetRequestedFeatures> = (
  b2bApplication: string,
  applicationFeatures: string,
) => {
  const appFeatures = applicationFeatures.split(',').map((item) => item.trim());
  const requestedFeaturesObj = {
    [b2bApplication]: appFeatures,
  };
  const requestedFeatures = JSON.stringify(requestedFeaturesObj);
  return {
    type: RegistrationActionType.SET_REQUESTED_FEATURES,
    requestedFeatures: requestedFeatures,
  };
};

/**
 * Redux Action to Reset Registration State
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetRegistration: ActionCreator<IRegistrationReset> = () => ({
  type: RegistrationActionType.RESET_REGISTRATION,
});

/**
 * Redux Thunk Action to Submit Registration
 *
 * @param registrationForm
 * @returns
 *
 * @author Gazi Rahman
 */
export const submitRegistration: ActionCreator<ThunkAction<
  Promise<any>,
  IRegistrationState,
  null,
  IAllRegistrationActions
>> = (registrationForm: IRegistrationForm) => {
  return async (dispatch: ThunkDispatch<IRegistrationState, null, IAllRegistrationActions>) => {
    dispatch(startRegistrationSubmit());

    const registrationUrl = process.env.REACT_APP_REGISTRATION_SERVICE_URL || EndPoint.REGISTRATION;
    try {
      const response = await axios.post<IRestResponse>(registrationUrl, registrationForm);
      const resData = response.data;
      if (resData.responseStatus !== ResponseStatus.SUCCESS) {
        log('Server Returned Error Response Password Change Request!', resData);
        return dispatch(failRegistrationSubmit(resData.errorCodes, resData.message));
      } else {
        log('Registration finished Successfully: ', resData);
        return dispatch(finishRegistrationSubmit());
      }
    } catch (error) {
      log('Failed to send request to Change Password: ', error);
      return dispatch(
        failRegistrationSubmit(
          'error.serverError',
          'Failed to send request to Change Password. Please try again later.',
        ),
      );
    }
  };
};

const startRegistrationSubmit: ActionCreator<IStartRegistrationSubmit> = () => ({
  type: RegistrationActionType.START_REGISTRATION_SUBMIT,
});

const finishRegistrationSubmit: ActionCreator<IFinishRegistrationSubmit> = () => ({
  type: RegistrationActionType.FINISH_REGISTRATION_SUBMIT,
});

const failRegistrationSubmit: ActionCreator<IFaileRegistrationSubmit> = (
  errorCodes: Array<string>,
  errorMessage: string,
) => ({
  type: RegistrationActionType.FAIL_REGISTRATION_SUBMIT,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
