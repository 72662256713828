import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IEmailAvailableForRegisterResponse } from '../../api/response/IEmailAvailableForRegisterResponse';
import { EndPoint } from '../../constants/EndPoints';
import IRegistrationState from './IRegistrationState';
import {
  ICheckEmailForRegister,
  ISetEmailAvailableForRegister,
  ISetEmailNotAvailableForRegister,
  RegistrationActionType,
} from './RegistrationActionTypes';
import queryString from 'query-string';
import axios from '../../axiosFactory';
import log from '../../util/Log';

/**
 * Redux Thunk Action to Check if Email being tried for Registration is available
 * or not
 *
 * @param email
 * @returns
 *
 * @author Gazi Rahman
 */
export const checkEmailAvailbleForRegister: ActionCreator<ThunkAction<
  Promise<any>,
  IRegistrationState,
  any,
  ICheckEmailForRegister | ISetEmailAvailableForRegister | ISetEmailNotAvailableForRegister
>> = (email: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: RegistrationActionType.CHECK_EMAIL_FOR_REGISTER,
    });
    axios
      .post<IEmailAvailableForRegisterResponse>(
        EndPoint.CHECK_EMAIL_AVAILABLE_FOR_REGISTER,
        queryString.stringify({
          email: email,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
        },
      )
      .then((response) => {
        if (response.data.available) {
          return dispatch(setEmailAvailableForRegister());
        } else {
          return dispatch(setEmailNotAvailableForRegister());
        }
      })
      .catch((e) => {
        log('Failed to check if Email is Available for Register: ', e);
        return dispatch(setEmailNotAvailableForRegister());
      });
  };
};

const setEmailAvailableForRegister: ActionCreator<ISetEmailAvailableForRegister> = () => ({
  type: RegistrationActionType.SET_EMAIL_AVAILABLE_FOR_REGISTER,
});

const setEmailNotAvailableForRegister: ActionCreator<ISetEmailNotAvailableForRegister> = () => ({
  type: RegistrationActionType.SET_EMAIL_NOT_AVAILBLE_FOR_REGISTER,
});
