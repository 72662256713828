/**
 * All the authentication actions are handled here.
 *
 * @author Gazi Rahman
 */
import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import log from '../../util/Log';

import {
  AuthActionType,
  IAuthenticationLoading,
  IAuthenticationStarted,
  IAuthenticationCompleted,
  IAuthenticationFailed,
  IMarkAuthenticated,
  IResetAuthenticationProcess,
  IClearAuthentication,
  ILoadAuthentication,
} from './AuthActionType';
import IAuthState from './IAuthState';
import IAuthenticationInfo from '../../api/response/IAuthenticationInfo';

import {
  loadAuthenticationFromStorage,
  removeAuthenticationFromStorage,
  storeAuthenticationToStorage,
} from '../../util/StorageUtil';
import IAccessTokenInfo from '../../api/response/IAccessTokenInfo';

const authenticationLoading: ActionCreator<IAuthenticationLoading> = () => ({
  type: AuthActionType.AUTHENTICATION_LOADING,
});

/**
 * Redux Action for Loading Authentication from Browser Storage
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadAuthentication: ActionCreator<ThunkAction<
  AnyAction,
  IAuthState,
  null,
  ILoadAuthentication | IClearAuthentication
>> = () => (
  dispatch: ThunkDispatch<IAuthState, null, IAuthenticationLoading | ILoadAuthentication | IClearAuthentication>,
) => {
  dispatch(authenticationLoading());
  const accessTokenInfo: IAccessTokenInfo = loadAuthenticationFromStorage();
  log('Found Stored Authentication Info');
  const isLoadedTokenValid =
    accessTokenInfo &&
    (accessTokenInfo.accessTokenDurationSeconds === 0 ||
      new Date().getTime() <
        accessTokenInfo.accessTokenCreationTime + accessTokenInfo.accessTokenDurationSeconds * 1000);
  log('Is Found Authentication Valid: ', isLoadedTokenValid);
  //log('Found Authentication: ', JSON.stringify(authInfo));
  if (!isLoadedTokenValid) {
    return dispatch(clearAuthentication());
  } else {
    return dispatch({
      type: AuthActionType.LOAD_AUTHENTICATION,
      accessTokenInfo: accessTokenInfo,
    });
  }
};

/**
 * Redux Action to Reset authentication State in the application
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetAuthenticationProcess: ActionCreator<IResetAuthenticationProcess> = () => ({
  type: AuthActionType.RESET_AUTHENTICATION_PROCESS,
});

/**
 * Redux Action to Mark Current User as Authenticated
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const markAuthenticated: ActionCreator<IMarkAuthenticated> = () => ({
  type: AuthActionType.MARK_AUTHENTICATED,
});

/**
 * Redux Action to clear/remove the Authentication of Current User
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const clearAuthentication: ActionCreator<IClearAuthentication> = () => {
  removeAuthenticationFromStorage();

  return {
    type: AuthActionType.CLEAR_AUTHENTICATION,
  };
};

/**
 * Redux Action to Start the Authentication process
 *
 * @param orthoUser
 * @returns
 *
 * @author Gazi Rahman
 */
export const startAuthentication: ActionCreator<IAuthenticationStarted> = (
  orthoUser: boolean,
): IAuthenticationStarted => ({
  type: AuthActionType.START_AUTH_REQUEST,
  orthoUser,
});

/**
 * Redux Action to load Auth Error
 *
 * @param errorCodes
 * @param errorMessage
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadAuthError: ActionCreator<IAuthenticationFailed> = (
  errorCodes: Array<string>,
  errorMessage: string,
): IAuthenticationFailed => ({ type: AuthActionType.AUTHENTICATION_FAILED, errorCodes, errorMessage });

/**
 * Redux Action to Load AuthResponse from AuthenticationInfo
 *
 * @param authenticationInfo
 * @param orthoUser
 * @returns
 *
 * @author Gazi Rahman
 */
export const loadAuthResponse: ActionCreator<IAuthenticationCompleted> = (
  authenticationInfo: IAuthenticationInfo,
  orthoUser: boolean,
) => {
  /**
   * Remove any additional attributes, like session infor from authentication info.
   */
  const accessTokenInfo: IAccessTokenInfo = Object.assign({}, { ...authenticationInfo }, { sessionInfo: undefined });
  storeAuthenticationToStorage(accessTokenInfo);

  const sessionInfo = authenticationInfo.sessionInfo;
  log('Going to Set Gigya Session Info: ', sessionInfo);
  document.cookie = sessionInfo.cookieName + '=' + sessionInfo.cookieValue;

  return {
    type: AuthActionType.AUTHENTICATION_COMPLETED,
    accessTokenInfo: accessTokenInfo,
    orthoUser,
  };
};
