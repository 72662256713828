/**
 * Gigya Sites for different user groups.
 *
 * @author Gazi Rahman
 */
export enum GigyaSite {
  /**
   * Used by both Orther Users and Customers
   */
  INTERNAL = 'INTERNAL',

  /**
   * Used by Customers Only
   */
  EXTERNAL = 'EXTERNAL',
}
