/**
 * Redux Action Types related to Logout from Gigya
 *
 * @author Gazi Rahman
 */
export enum GigyaLogoutActionTypes {
  GIGYA_LOGOUT_START = 'GIGYA_LOGOUT_START',
  GIGYA_LOGOUT_FINISH = 'GIGYA_LOGOUT_FINISH',
  GIGYA_LOGOUT_ERROR = 'GIGYA_LOGOUT_ERROR',
}

export interface IGigyaLogoutStart {
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_START;
}

export interface IGigyaLogoutFinish {
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_FINISH;
}

export interface IGigyaLogoutError {
  type: GigyaLogoutActionTypes.GIGYA_LOGOUT_ERROR;
  errorCode: string;
  errorMessage: string;
}

export type IAllGigyaLogoutActions = IGigyaLogoutStart | IGigyaLogoutFinish | IGigyaLogoutError;
