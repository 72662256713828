import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import Layout from '../../hoc/layout/Layout';
import HomeLink from '../../components/homeLink/HomeLink';

/**
 * 404 Error Page
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const error404: React.SFC<WithTranslation> = (props) => {
  const customerSupportEmail = process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL;
  // const homePageUrl = process.env.REACT_APP_PUBLIC_SITE_HOME_PAGE;
  const { t } = props;
  return (
    <Layout title={t('errorPages.error404.pageTitle')} isErrorPage={true}>
      <div className="error-section">
        <div className="error-title">
          <Trans ns="common" i18nKey="errorPages.error404.message">
            <h2>The page you are looking for might have been removed,</h2>
            <h2>had its name changed or is temporarily unavailable.</h2>
          </Trans>
        </div>
        <HomeLink />
        <p>
          <Trans ns="common" i18nKey="errorPages.error404.helpText">
            If you need help please&nbsp;
            <a href={`mailto: ${customerSupportEmail}`}>email</a>&nbsp; the Ortho Plus℠ Technical Support Team
          </Trans>
        </p>
      </div>
    </Layout>
  );
};

export default withTranslation('common')(error404);
