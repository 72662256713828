import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import '../../assets/css/cdc.form.css';

/**
 * Properties for RegisterLink
 *
 * @author Gazi Rahman
 */
interface IProps {
  onRegisterLinkClick: (event: React.MouseEvent) => void;
}

/**
 * Link to go to the RegistrationPage from other pages.
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const registerLink: React.SFC<IProps> = (props) => {
  return (
    <p className="register cdc-fnt-18">
      <Trans ns="common" i18nKey="registerLink">
        Don’t have an account?{' '}
        <Link className="cdc-fnt-16" onClick={props.onRegisterLinkClick} to="/register">
          Register now
        </Link>
      </Trans>
    </p>
  );
};

export default registerLink;
