import ISecurityQuestion from './ISecurityQuestion';

/**
 * Redux Action Types for Loading Security Questions
 *
 * @author Gazi Rahman
 */
export enum SecurityQuestionsActionTypes {
  SECURITY_QUESTIONS_LOAD_START = 'SECURITY_QUESTIONS_LOAD_START',
  SECURITY_QUESTIONS_LOAD_FINISH = 'SECURITY_QUESTIONS_LOAD_FINISH',
  SECURITY_QUESTIONS_LOAD_FAIL = 'SECURITY_QUESTIONS_LOAD_FAIL',
}

export interface ISecurityQuestionsLoadStart {
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_START;
}

export interface ISecurityQuestionsLoadFinish {
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FINISH;
  securityQuestions: Array<ISecurityQuestion>;
}

export interface ISecurityQuestionsLoadFail {
  type: SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FAIL;
  errorCodes: Array<string>;
  errorMessage: string;
}

export type IAllSecurityQuestionActions =
  | ISecurityQuestionsLoadStart
  | ISecurityQuestionsLoadFinish
  | ISecurityQuestionsLoadFail;
