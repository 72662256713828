import IAccessTokenInfo from '../../../api/response/IAccessTokenInfo';

/**
 * Redux Action Types for AutoLogin
 *
 * @author Gazi Rahman
 */
export enum AutoLoginActionType {
  START_AUTO_LOGIN = 'START_AUTO_LOGIN',

  FINISH_AUTO_LOGIN = 'FINISH_AUTO_LOGIN',

  FAIL_AUTO_LOGIN = 'FAIL_AUTO_LOGIN',

  LOADING_AUTO_LOGIN = 'LOADING_AUTO_LOGIN',
}

export interface IStartAutoLogin {
  type: AutoLoginActionType.START_AUTO_LOGIN;
}

export interface IFinishAutoLogin {
  type: AutoLoginActionType.FINISH_AUTO_LOGIN;
  accessTokenInfo: IAccessTokenInfo;
}

export interface IFailAutoLogin {
  type: AutoLoginActionType.FAIL_AUTO_LOGIN;
  errorCode: string;
  errorMessage: string;
}

export interface ILoadingAutoLogin {
  type: AutoLoginActionType.LOADING_AUTO_LOGIN;
}

export type IAllAutoLoginActions = IStartAutoLogin | IFinishAutoLogin | IFailAutoLogin | ILoadingAutoLogin;
