import IAutoLinkedCustomerUser from '../../api/response/IAutoLinkedCustomerUser';

export enum AutoLinkedCustomerUserActionTypes {
  RESET_AUTO_LINKED_CUSTOMER_USER = 'RESET_AUTO_LINKED_CUSTOMER_USER',
  START_GETTING_AUTO_LINKED_CUSTOMER_USER = 'START_GETTING_AUTO_LINKED_CUSTOMER_USER',
  FINISHED_GETTING_AUTO_LINKED_CUSTOMER_USER = 'FINISHED_GETTING_AUTO_LINKED_CUSTOMER_USER',
  FAILED_GETTING_AUTO_LINKED_CUSTOMER_USER = 'FAILED_GETTING_AUTO_LINKED_CUSTOMER_USER',
}

export interface IResetAutoLinkedCustomerUser {
  type: AutoLinkedCustomerUserActionTypes.RESET_AUTO_LINKED_CUSTOMER_USER;
}

export interface IStartGettingAutoLinkedCustomerUser {
  type: AutoLinkedCustomerUserActionTypes.START_GETTING_AUTO_LINKED_CUSTOMER_USER;
}

export interface IFinishedGettingAutoLinkedCustomerUser {
  type: AutoLinkedCustomerUserActionTypes.FINISHED_GETTING_AUTO_LINKED_CUSTOMER_USER;
  autoLinkedCustomerUser: IAutoLinkedCustomerUser;
}

export interface IFailedGettingAutoLinkedCustomerUser {
  type: AutoLinkedCustomerUserActionTypes.FAILED_GETTING_AUTO_LINKED_CUSTOMER_USER;
  errorCodes: Array<string>;
  errorMessage: string;
}

export type IAllAutoLinkedCustomerUserActions =
  | IResetAutoLinkedCustomerUser
  | IStartGettingAutoLinkedCustomerUser
  | IFinishedGettingAutoLinkedCustomerUser
  | IFailedGettingAutoLinkedCustomerUser;
