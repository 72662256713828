import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

/**
 * Fragment for Registration Finished Page
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const registrationFinished: React.SFC<WithTranslation> = (props) => {
  const { t } = props;
  return (
    <div className="error-section">
      <div className="error-title">
        <h2 className="mb-5">{t('successPage.title')}</h2>
        <Trans ns="register" i18nKey="successPage.message">
          <h3>Your registration was successful.</h3>
          <br />
          <h3>We have sent you an email to activate your account.</h3>
        </Trans>
      </div>
    </div>
  );
};

export default withTranslation('register')(registrationFinished);
