/**
 * Redux Action Types for AfterAuthUserInfoUpdate
 *
 * @author Gazi Rahman
 */
export enum AfterAuthUserInfoActionTypes {
  START_AFTER_AUTH_USER_INFO_UPDATE = 'START_AFTER_AUTH_USER_INFO_UPDATE',

  FINISH_AFTER_AUTH_USER_INFO_UPDATE = 'FINISH_AFTER_AUTH_USER_INFO_UPDATE',

  FAILED_AFTER_AUTH_USER_INFO_UPDATE = 'FAILED_AFTER_AUTH_USER_INFO_UPDATE',

  RESET_AFTER_AUTH_USER_INFO_UPDATE = 'RESET_AFTER_AUTH_USER_INFO_UPDATE',
}

export interface IAfterAuthUserInfoUpdateStarted {
  type: AfterAuthUserInfoActionTypes.START_AFTER_AUTH_USER_INFO_UPDATE;
}

export interface IAfterAuthUserInfoUpdateFinished {
  type: AfterAuthUserInfoActionTypes.FINISH_AFTER_AUTH_USER_INFO_UPDATE;
}

export interface IAfterAuthUserInfoUpdateFailed {
  type: AfterAuthUserInfoActionTypes.FAILED_AFTER_AUTH_USER_INFO_UPDATE;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IResetAfterAutheUserInfoUpdate {
  type: AfterAuthUserInfoActionTypes.RESET_AFTER_AUTH_USER_INFO_UPDATE;
}

export type IAllAfterAuthUserInfoUpdateActions =
  | IAfterAuthUserInfoUpdateStarted
  | IAfterAuthUserInfoUpdateFinished
  | IAfterAuthUserInfoUpdateFailed
  | IResetAfterAutheUserInfoUpdate;
