import defaultCountries from '../assets/json/defaultCountryNames/default-country-names.json';

export const getCountryDet = (i18Language) => {
  for (const [language, country] of Object.entries(defaultCountries)) {
    if (i18Language === language) {
      return country;
    }
  }
  return false;
};
