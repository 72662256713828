import * as React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes/Routes';

import './assets/css/cdc.css';
import { IApplicationState } from './store/Store';

/**
 * Properties for React Application
 *
 * @author Gazi Rahman
 */
interface IProps {
  store: Store<IApplicationState>;
}

const SUSPENSE_FALLBACK = 'Loading OrthoPlus...';

/**
 * React Application
 *
 * @param props
 * @returns
 *
 * @author Gazi Rahman
 */
const app: React.SFC<IProps> = (props) => (
  <React.Suspense fallback={SUSPENSE_FALLBACK}>
    <Provider store={props.store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  </React.Suspense>
);

export default app;
