import { GigyaSite } from './GigyaSite';

/**
 * Redux Action Types for Gigya Script Loading
 *
 * @author Gazi Rahman
 */
export enum GigyaActionType {
  GIGYA_LOAD_STARTED = 'GIGYA_LOAD_STARTED',
  GIGYA_LOAD_CHECKED = 'GIGYA_LOAD_CHECKED',
  GIGYA_LOAD_FAILED = 'GIGYA_LOAD_FAILED',
  GIGYA_LOAD_FINISHED = 'GIGYA_LOAD_FINISHED',
}

export interface IGigyaLoadStartAction {
  type: GigyaActionType.GIGYA_LOAD_STARTED;
  gigyaSite: GigyaSite;
}

export interface IGigyaLoadCheckAction {
  type: GigyaActionType.GIGYA_LOAD_CHECKED;
}

export interface IGigyaLoadFailedAction {
  type: GigyaActionType.GIGYA_LOAD_FAILED;
  errorCodes: Array<string>;
  errorMessage: string;
}

export interface IGigyaLoadFinished {
  type: GigyaActionType.GIGYA_LOAD_FINISHED;
}

export type IAllGigyaActions =
  | IGigyaLoadStartAction
  | IGigyaLoadCheckAction
  | IGigyaLoadFinished
  | IGigyaLoadFailedAction;
