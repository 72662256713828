/**
 * Subpaths for Registration Steps
 *
 * @author Gazi Rahman
 */
export enum RegisterSubpath {
  PROFILE_INFO = '/profileinfo',
  SECURITY_QUESTIONS = '/securityquestions',
  CUSTOMER_INFO = '/customerinfo',
  PERMISSIONS = '/permissions',
  SUCCESS = '/success',
}
