/**
 * Redux Actions for AccountInfoRetrive from Gigya
 *
 * @author Gazi Rahman
 */

import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import log from '../../../../util/Log';
import {
  AccountInfoRetrieveActionType,
  IAccountInfoRetrieveFailedAction,
  IAccountInfoRetrieveFinished,
  IAccountInfoRetrieveStartAction,
  IAllAccountInfoRetrieveActions,
} from './AccountInfoRetrieveActionType';
import IAccountInfoRetrieveState from './IAccountInfoRetrieveState';

/**
 * Global/Browser window variable
 *
 * @author Gazi Rahman
 */
declare const window: any;

/**
 * Redux Thunk Action to Retrieve AccountInfo from Gigya
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const retrieveAccountInfo: ActionCreator<ThunkAction<
  Promise<any>,
  IAccountInfoRetrieveState,
  null,
  IAllAccountInfoRetrieveActions
>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startAccountInfoRetrieve());
    try {
      const response = await new Promise((resolve, reject) => {
        window.gigya.accounts.getAccountInfo({
          callback: function (gigyaResponse: any) {
            if (gigyaResponse.errorCode === 0) {
              log('Logged in User: {}', gigyaResponse.profile);
              resolve(gigyaResponse);
            } else {
              log('Error response found for user Information: ', gigyaResponse);
              reject({
                errorCode: gigyaResponse.errorCode,
                errorMessage: gigyaResponse.errorMessage,
              });
            }
          },
        });
      });
      return dispatch(finishedAccountInfoRetrieve(response));
    } catch (err) {
      return dispatch(failedAccountInfoRetrieve(err.errorCode, err.errorMessage));
    }
  };
};

const startAccountInfoRetrieve: ActionCreator<IAccountInfoRetrieveStartAction> = () => ({
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_STARTED,
});

const failedAccountInfoRetrieve: ActionCreator<IAccountInfoRetrieveFailedAction> = (
  errorCode: number,
  errorMessage: string,
) => ({
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FAILED,
  errorCode: errorCode,
  errorMessage: errorMessage,
});

const finishedAccountInfoRetrieve: ActionCreator<IAccountInfoRetrieveFinished> = (response: any) => ({
  type: AccountInfoRetrieveActionType.ACCOUNT_INFO_RETRIEVE_FINISHED,
  response: response,
});
