import ISecurityQuestionsState from './ISecurityQuestionsState';
import { Reducer } from 'redux';
import { IAllSecurityQuestionActions, SecurityQuestionsActionTypes } from './SecurityQuestionsActionTypes';

/**
 * Initial State for SecurityQuestionsReducer
 *
 * @author Gazi Rahman
 */
const initialState: ISecurityQuestionsState = {
  securityQuestionsLoading: false,
  securityQuestionsLoaded: false,
  securityQuestionsLoadFailed: false,
};

/**
 * Redux Reducer for Loading Security Questions
 *
 * @param state
 * @param action
 * @returns
 *
 * @author Gazi Rahman
 */
const securityQuestionsReducer: Reducer<ISecurityQuestionsState, IAllSecurityQuestionActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_START:
      return {
        securityQuestionsLoading: true,
        securityQuestionsLoaded: false,
        securityQuestionsLoadFailed: false,
        securityQuestions: undefined,
        securityQuestionsLoadErrorCode: undefined,
        securityQuestionsLoadErrorMessage: undefined,
      };
    case SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FINISH:
      return {
        securityQuestionsLoading: false,
        securityQuestionsLoaded: true,
        securityQuestionsLoadFailed: false,
        securityQuestions: action.securityQuestions,
        securityQuestionsLoadErrorCode: undefined,
        securityQuestionsLoadErrorMessage: undefined,
      };
    case SecurityQuestionsActionTypes.SECURITY_QUESTIONS_LOAD_FAIL:
      return {
        securityQuestionsLoading: false,
        securityQuestionsLoaded: false,
        securityQuestionsLoadFailed: true,
        securityQuestions: undefined,
        securityQuestionsLoadErrorCodes: action.errorCodes,
        securityQuestionsLoadErrorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default securityQuestionsReducer;
