import { ActionCreator, Dispatch } from 'redux';
import log from '../../../../util/Log';
import {
  IGigyaRefreshUIStart,
  GigyaRefreshUIActionTypes,
  IGigyaRefreshUIFinished,
  IAllGigyaRefreshUIActions,
  IGigyaRefreshUIReset,
} from './GigyaRefreshUIActionTypes';
import { ThunkAction } from 'redux-thunk';
import IGigyaRefreshUIState from './IGigyaRefreshUIState';

/**
 * Actions related to Gigya RefreshUI
 *
 * @author Gazi Rahman
 */

/**
 * Global Window variable
 */
declare const window: any;

/**
 * Redux Action to Reset Gigya RefreshUI State
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const resetGigyaRefreshUI: ActionCreator<IGigyaRefreshUIReset> = () => ({
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_RESET,
});

/**
 * Redux Thunk Action to Perform Gigya RefreshUI
 *
 * @returns
 *
 * @author Gazi Rahman
 */
export const gigyaRefreshUI: ActionCreator<ThunkAction<
  Promise<any>,
  IGigyaRefreshUIState,
  null,
  IAllGigyaRefreshUIActions
>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startGigyaRefreshUI());

    await new Promise(function (resolve, reject) {
      log('Going to invoke Gigya Refresgh UI to update Gigya Cookies');
      window.gigya.socialize.refreshUI({
        callback: function (response: any) {
          log('Gigya Refresh UI returned with Response: ', response);
          resolve(response);
        },
      });
    });

    return dispatch(finishGigyaRefreshUI());
  };
};

const startGigyaRefreshUI: ActionCreator<IGigyaRefreshUIStart> = () => ({
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_START,
});

const finishGigyaRefreshUI: ActionCreator<IGigyaRefreshUIFinished> = () => ({
  type: GigyaRefreshUIActionTypes.GIGYA_REFRESH_UI_FINISHED,
});
