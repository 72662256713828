import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ResponseStatus } from '../../api/response/ResponseStatus';
import { EndPoint } from '../../constants/EndPoints';
import IAutoLinkedCustomerUser from '../../api/response/IAutoLinkedCustomerUser';
import {
  AutoLinkedCustomerUserActionTypes,
  IResetAutoLinkedCustomerUser,
  IAllAutoLinkedCustomerUserActions,
  IFailedGettingAutoLinkedCustomerUser,
  IFinishedGettingAutoLinkedCustomerUser,
  IStartGettingAutoLinkedCustomerUser,
} from './AutoLinkedCustomerUserActionTypes';

import log from '../../util/Log';
import axios from '../../axiosFactory';
import IAutoLinkedCustomerUserState from './IAutoLinkedCustomerUserState';
import IAutoLinkedCustomerUserResponse from '../../api/response/IAutoLinkedCustomerUserResponse';

export const resetAutoLinkedCustomerUser: ActionCreator<IResetAutoLinkedCustomerUser> = () => ({
  type: AutoLinkedCustomerUserActionTypes.RESET_AUTO_LINKED_CUSTOMER_USER,
});

export const getAutoLinkedCustomerUser: ActionCreator<ThunkAction<
  Promise<any>,
  IAutoLinkedCustomerUserState,
  null,
  IAllAutoLinkedCustomerUserActions
>> = (key: string) => {
  return async (dispatch: ThunkDispatch<IAutoLinkedCustomerUserState, null, IAllAutoLinkedCustomerUserActions>) => {
    dispatch(startGettingAutoLinkedCustomerUser());

    const apiUrl = process.env.REACT_APP_AUTO_LINKED_CUSTOMER_USER_URL || EndPoint.AUTO_LINKED_CUSTOMER_USER_URL;

    try {
      const response = await axios.get<IAutoLinkedCustomerUserResponse>(apiUrl + '/' + key);
      const data = response.data;

      if (data.responseStatus !== ResponseStatus.SUCCESS) {
        log('Failed to get Auto Linked Customer User from Server for Key: ', key);
        return dispatch(failedGettingAutoLinkedCustomerUser(data.errorCodes, data.message));
      } else if (!data.userInfo) {
        log('Server did not return Auto Linked Customer user for Key: ', key);
        return dispatch(
          failedGettingAutoLinkedCustomerUser(
            ['error.serverError'],
            'Server failed to return Auto Registered Customer User. Please try again later.',
          ),
        );
      } else {
        log('Successfully Retrieved Auto Linked Customer User for Key: ', key, data.userInfo);
        dispatch(finishGettingAutoLinkedCustomerUser(data.userInfo));
      }
    } catch (error) {
      log('Failed to request for Auto Linked Customer User for key: ', key, ' as: ' + error);
      return dispatch(
        failedGettingAutoLinkedCustomerUser(
          ['error.serverError'],
          'Failed to request for Auto Linked Customer User. Please try again later.',
        ),
      );
    }
  };
};

const startGettingAutoLinkedCustomerUser: ActionCreator<IStartGettingAutoLinkedCustomerUser> = () => ({
  type: AutoLinkedCustomerUserActionTypes.START_GETTING_AUTO_LINKED_CUSTOMER_USER,
});

const finishGettingAutoLinkedCustomerUser: ActionCreator<IFinishedGettingAutoLinkedCustomerUser> = (
  customerUser: IAutoLinkedCustomerUser,
) => ({
  type: AutoLinkedCustomerUserActionTypes.FINISHED_GETTING_AUTO_LINKED_CUSTOMER_USER,
  autoLinkedCustomerUser: customerUser,
});

const failedGettingAutoLinkedCustomerUser: ActionCreator<IFailedGettingAutoLinkedCustomerUser> = (
  errorCodes: Array<string>,
  errorMessage: string,
) => ({
  type: AutoLinkedCustomerUserActionTypes.FAILED_GETTING_AUTO_LINKED_CUSTOMER_USER,
  errorCodes: errorCodes,
  errorMessage: errorMessage,
});
